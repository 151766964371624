<template>
  <div ref="container">
    <div v-show="false">{{ player }}</div>
    <telegram-web-chat></telegram-web-chat>
  </div>
</template>

<script>
import Vue from "vue";

Vue.config.ignoredElements.push('telegram-web-chat');

const tocha = new TochaScript();
export default {
  name: "Tocha",
  props: ['player', 'url'],
  mounted() {
    tocha.load(this.player, this.url);
  },
  beforeUpdate() {
    tocha.load(this.player, this.url);
  }
}

function TochaScript() {
  let script = null;
  this.load = (player, url) => {
    if (script) {
      script.remove();
    }

    if (player) {
      url += '?userLabel=' + encodeURIComponent(player)
    }

    script = document.createElement('script')

    script.async = true;
    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script)
  }
}

</script>

<style>
.tocha__message-line a {
  color: #1b1c37;
  text-decoration: underline;
}

.tocha__launch-button.is_mobile.is_mobile {
  right: 16px !important;
}

.tocha__helper.is_mobile {
  min-width: unset !important;
}
</style>
