<template>
  <div @click="click">
    <svg style="display:block" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="7" fill="#262744"/>
      <path d="M11.5 14.1667C11.5 13.5223 12.0074 13 12.6333 13H24.1C24.7259 13 25.2333 13.5223 25.2333 14.1667C25.2333 14.811 24.7259 15.3333 24.1 15.3333H12.6333C12.0074 15.3333 11.5 14.811 11.5 14.1667Z" fill="white"/>
      <path d="M11.5 20C11.5 19.3557 12.0074 18.8333 12.6333 18.8333H27.3667C27.9926 18.8333 28.5 19.3557 28.5 20C28.5 20.6443 27.9926 21.1667 27.3667 21.1667H12.6333C12.0074 21.1667 11.5 20.6443 11.5 20Z" fill="white"/>
      <path d="M11.5 25.8333C11.5 25.189 12.0074 24.6667 12.6333 24.6667H20.7C21.3259 24.6667 21.8333 25.189 21.8333 25.8333C21.8333 26.4777 21.3259 27 20.7 27H12.6333C12.0074 27 11.5 26.4777 11.5 25.8333Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "BurgerButton",
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>

</style>