export class GamesCache {
    key() {
        return 'cache.games';
    }

    storage() {
        return localStorage;
    }

    get() {
        return JSON.parse(this.storage().getItem(this.key()) || JSON.stringify({games: [], version: ''}))
    }

    set({games, version}) {
        this.storage().setItem(this.key(), JSON.stringify({games, version}));
    }
}