<template>
  <div class="first-deposit">
    <div class="text">
      <div class="main">{{ $t('banners.firstDeposit.title') }}</div>
      <p class="heading">+20%</p>
      <p class="subheading">free points</p>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.first-deposit {
  width: 100%;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/poker.svg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: contain;
  .text {
    padding: 20px;
    .main {
      max-width: 120px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140.7%;
      color: #ffffff;
    }
    .heading {
      font-weight: 900;
      font-size: 40px;
      line-height: 51px;
      color: #fee870;
    }
    .subheading {
      font-weight: 600;
      font-size: 16px;
      line-height: 140.7%;
      color: #fee870;
    }
  }
}
</style>
