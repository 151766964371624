<template>
  <SmsForm :tel="tel" :sender="sendCode" :sms-form-error="error"/>
</template>

<script>
import SmsForm from "../SmsForm";

export default {
  components: {SmsForm},
  computed: {
    tel() {
      return this.$store.state.register.tel
    },
    process_id() {
      return this.$store.state.register.process_id
    }
  },
  data() {
    return {error: false};
  },
  methods: {
    sendCode(code) {
      this.$store.dispatch('register/confirm', code).then(r => {
        if (r.success) {
          this.$router.push({name: 'registration.complete'})
        } else {
          this.error = true
        }
      })
    }
  }
};
</script>