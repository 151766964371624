<template>
  <div class="external-page-container" :class="{'external-promo': $route.name === 'promo.play'}">
    <div class="external-header">
      <a class="back-button" @click="goBack">
        <div class="back-icon"></div>
        {{ $t('externalHeader.back') }}
      </a>
    </div>
    <iframe :src="url" @load="frameLoaded" class="external-page-frame" seamless :allowfullscreen="allowFullScreen"/>
  </div>
</template>

<script>

export default {
  name: "Game",
  props: {
    url: {type: String, required: true},
    allowFullScreen: {type: Boolean, default: true}
  },
  mounted() {
    this.$store.commit('loading');
  },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    frameLoaded() {
      this.$store.commit('loading_complete');
    }
  }
}
</script>

<style scoped>
.external-page-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 20;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &.external-promo {
    max-height: calc(100vh - 50px);
  }
}

.external-page-frame {
  top: 40px;
  width: 100%;
  border: 0;
  flex-grow: 1;
}

.external-header {
  background: #1b1c37;
  position: static;
}

.back-icon {
  font-size: 14px;
  border-radius: 4px;
  display: block;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/icons/auth/arrow.svg");
}

.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}
</style>
