<template>
  <router-link  :to="{ name: 'registration' }" class="btn__orange sign-in-btn router-link-exact-active router-link-active">
    {{ $t('header.sign_in') }}
  </router-link>
</template>

<script>
export default {
name: "SignInButton"
}
</script>

<style scoped>
.sign-in-btn {
  width: initial;
  /*max-width: 120px;*/
  padding: 12px 14px;
  text-align: center;
  font-size: 14px;
}
</style>
