<template>
  <div class="cashback">
    <div class="text">
      <p class="heading">10%</p>
      <p class="subheading">CashBack</p>
      <div class="main">{{ $t('banners.cashback.friday') }}</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cashback {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: linear-gradient(103.03deg, #2C2C4D 0%, #F04025 181.52%);  //width: 100%;
  display: flex;
  .text {
    flex-grow: 1;
    margin:10px;
    padding: 10px;
    background-image: url(../../assets/images/banners/cashback.svg);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;

    .main {
      max-width: 120px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140.7%;
      color: #ffffff;
    }

    .heading {
      font-weight: 900;
      font-size: 40px;
      line-height: 51px;
      color: #fee870;
    }

    .subheading {
      font-weight: 600;
      font-size: 16px;
      line-height: 140.7%;
      color: #fee870;
    }
  }
}
</style>
