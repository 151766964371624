<template>
  <div class="site__links">
    <router-link
        :to="{ name: 'index' }"
        class="menu__link"
        @click.native="click"
    >
      <img src="../../../assets/icons/menu/home.svg"/>
      {{ $t('menu.main') }}
    </router-link>
    <router-link
        :to="{ name: 'promo' }"
        class="menu__link"
        @click.native="click"
    >
      <img src="../../../assets/icons/menu/percent.svg"/>
      {{$t('menu.promo')}}
    </router-link>
    <router-link
        :to="{ name: 'login' }"
        class="menu__link"
        @click.native="click"
    >
      <img src="../../../assets/icons/menu/profile.svg"/>
      {{$t('menu.login')}}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'GuestMenu',
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>
