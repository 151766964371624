<template>
  <router-link  :to="{ name: 'login' }" class="login-btn router-link-exact-active router-link-active">
    {{ $t('header.login') }}
  </router-link>
</template>

<script>
export default {
name: "SignInButton"
}
</script>

<style scoped>
.login-btn {
  font-size: 14px;
  border-radius: 4px;
  padding: 12px 18px;
  background-color: #262744;
}
</style>
