<template>
  <SuccessMessage :title="$t('confirmPhone.success.title')"
                  :message="$t('confirmPhone.success.text')"
                  :button-text="$t('confirmPhone.success.link')"
                  :button-callback="goHome"/>
</template>

<script>
import SuccessMessage from "../../components/Ui/Success";

export default {
  name: "Success",
  components: {SuccessMessage},
  methods: {
    goHome() {
      this.$router.push({name: 'index'})
    }
  }
}
</script>

<style scoped>

</style>