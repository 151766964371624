<template>
  <router-link to="/" class="logo-container"><img :src="logo"/></router-link>
</template>

<script>
export default {
  name: "Logo",
  computed: {
    logo() {
      return this.$store.state.logo
    }
  }
}
</script>

<style scoped>
.logo-container img {max-width: 100%}
.logo-container {
  margin-top: 7px;
  width: 84px;
}
</style>
