<template>
  <section class="sms" :style="customStyle">
    <div class="sms__container">
      <div class="sms__main">
        <p class="sms__title">{{ $t('smsForm.title') }}</p>
        <p class="sms__text">
          {{ $t('smsForm.sent') }} <br />
          <span>{{formattedTel(tel)}}</span>
        </p>
<!--        <a href="#" class="link__orange code__link">{{ $t('smsForm.notDeliveredLink') }}</a>-->
      </div>
      <div class="sms__inputs">
        <div :class="['input__default', { 'in-error': smsFormError }]">
          <input
            type="text"
            v-model.trim="userSmsCode[0]"
            placeholder="0"
            readonly
          />
        </div>
        <div :class="['input__default', { 'in-error': smsFormError }]">
          <input
            type="text"
            v-model.trim="userSmsCode[1]"
            placeholder="0"
            readonly
          />
        </div>
        <div :class="['input__default', { 'in-error': smsFormError }]">
          <input
            type="text"
            v-model.trim="userSmsCode[2]"
            placeholder="0"
            readonly
          />
        </div>
        <div :class="['input__default', { 'in-error': smsFormError }]">
          <input
            type="text"
            v-model.trim="userSmsCode[3]"
            placeholder="0"
            readonly
          />
        </div>
      </div>
      <div class="error__container">
        <p :class="['error__label', { 'in-error': smsFormError }]">
          {{ $t('smsForm.invalidCode') }}
        </p>
      </div>
      <div class="sms__numbers">
        <button class="number__btn" @click="addCodeNumber(1)"><p>1</p></button>
        <button class="number__btn" @click="addCodeNumber(2)"><p>2</p></button>
        <button class="number__btn" @click="addCodeNumber(3)"><p>3</p></button>
        <button class="number__btn" @click="addCodeNumber(4)"><p>4</p></button>
        <button class="number__btn" @click="addCodeNumber(5)"><p>5</p></button>
        <button class="number__btn" @click="addCodeNumber(6)"><p>6</p></button>
        <button class="number__btn" @click="addCodeNumber(7)"><p>7</p></button>
        <button class="number__btn" @click="addCodeNumber(8)"><p>8</p></button>
        <button class="number__btn" @click="addCodeNumber(9)"><p>9</p></button>
        <button class="number__btn image" @click="deleteCodeNumber()">
          <img src="../../assets/icons/auth/delete.svg" />
        </button>
        <button class="number__btn" @click="addCodeNumber(0)"><p>0</p></button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SmsForm',
  components: {},
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
    tel: {
      type: String,
      default: '',
      required: false,
    },
    sender: {
      type: Function,
      default: () => {},
      required: true
    },
    smsFormError: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      // sms
      userSmsCode: "",
      sendedSmsCode: "",
      smsButtonLock: false,
    };
  },
  watch: {
    userSmsCode() {
      if (this.userSmsCode.length === 4) {
        this.sender(this.userSmsCode)
      }
    },
  },
  methods: {
    formattedTel(tel) {
      const prefix = tel.substring(0, 1);
      const code = tel.substring(1, 4);
      const first = tel.substring(4, 7);
      const second = tel.substring(7, 9);
      const rest = tel.substring(9);

      return `+${prefix} (${code}) ${first} ${second} ${rest}`;
    },
    addCodeNumber(value) {
      if (this.userSmsCode.length < 4) {
        this.userSmsCode = this.userSmsCode + value;
      }
    },
    deleteCodeNumber() {
      this.userSmsCode = this.userSmsCode.slice(0, -1);
    },
  },
};
</script>

<style lang="scss" scoped>
.sms {
  background-color: #1b1c37;
  padding: 0 40px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;
  .sms__container {
    position: relative;
    .sms__main {
      position: relative;
      transition: 0.2s;
      transition-delay: 0.2s;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .sms__title {
        max-width: 193px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
        text-align: center;
      }
      .sms__text {
        max-width: 222px;
        margin-bottom: 12px;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        span {
          color: #ffffff;
        }
      }
      .code__link {
        margin-bottom: 20px;
      }
    }
    .sms__inputs {
      max-width: 240px;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 auto 8px auto;
      .input__default {
        margin: 0 16px 0 0;
        &:nth-child(4) {
          margin-right: 0;
        }
        input {
          text-align: center;
        }
      }
    }
    .error__container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 25px auto;
      position: relative;
      .error__label {
        position: relative;
        bottom: 0;
        font-size: 16px;
      }
    }
    .sms__numbers {
      width: 240px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      .number__btn {
        padding: 10px 18px;
        margin: 0 50px 10px 0;
        background: none;
        border-radius: 6px;
        transition: 0.2s;
        &:nth-child(3n) {
          margin: 0 0 10px 0;
        }
        p {
          width: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
        }
        &.image {
          padding: 10px 11px;
        }
        &:hover {
          background: #4a4a6c93;
        }
        &:active {
          background: #454561da;
        }
      }
    }
  }
  &.active-main {
    right: 100%;
    left: -100%;
    opacity: 0;
  }
  &.active-next {
    right: 100%;
    left: -100%;
    opacity: 0;
  }
}
</style>