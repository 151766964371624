import Vue from "vue";
import Vuex from "vuex";
import Api from "../api/Api";
import register from "./modules/register";
import phoneConfirm from "./modules/phone-confirm";
import phoneSet from "./modules/phone-set";
import recover from "./modules/recover";
import router from "../router/router";
import { PartnerData } from "./PartnerData";
import { GamesCache } from "./GamesCache";
import { logoImage } from "./logoImages";

export const gamesCache = new GamesCache();
Vue.use(Vuex);

const partnerData = new PartnerData();
const api = new Api();

const setBalance = (state, balance, balances) => {
    state.balances = balances;

    if (balance && "string" in balance) {
        state.currency = currencySymbol(balance.currency);
        state.currencyCode = balance.currency;
        state.balance = `${balance.string} ${state.currency}`;
        state.balanceDecimal = balance.decimal;
        state.accounts = balance.accounts;
        state.wagerLimitation = balance.has_wager;
        state.wagerDecimal = balance.wager_sum;
    } else {
        state.currency = "";
        state.currencyCode = "";
        state.balance = "";
        state.balanceDecimal = 0;
        state.accounts = [];
        state.wagerLimitation = false;
        state.wagerDecimal = 0;
    }
    return state;
};
const unauthDefaults = {
    auth: false,
    player_id: null,
    player_label: null,
    currency: "",
    currencyCode: "",
    balance: "",
    balances: [],
    accounts: [],
    balanceDecimal: 0,
    wagerLimitation: false,
    wagerDecimal: 0,
    identityConfirmed: false,
    hasDeposit: false,
    hasPhone: false,
    phone: "",
    bonus: [],
    holdPayoutUntil: 0,
    outConfirmationDisabled: false,
    outConfirmationPossible: false,
    outConfirmationRequiredBySystem: false,
    outConfirmation: false,
    activePromoCode: []
};
export default new Vuex.Store({
    plugins: [loadingPlugin],
    strict: process.env.NODE_ENV !== "production",
    state: {
        ...unauthDefaults,
        category: "all",
        logo: logoImage("loto"),
        currentlyLoading: 0,
        loading: false,
        gamesVersion: "",
        games: [],
        winners: [],
        displayWinners: true,
        paymentHistory: { items: [], nextPage: null },
        cashBack: {
            active: false,
            sum: "0",
            scheduledTo: 0,
            sumEnough: false,
            min: "0",
            maxBalance: "0",
            bonusActive: false
        },
        deposit: {
            crypto: []
        },
        config: {
            phoneMask: "",
            phonePrefix: "",
            defaultLanguage: "",
            logo: "loto",
            appUrl: "",
            tochaUrl: "",
            metrikaId: "",
            kolesoOnlyApp: false,
            payout: {
                enabled: [],
                min: [],
                phone_min: []
            },
            deposit: {
                hint: {
                    KZT: { min: 700, max: 500000, hint: [700, 1500, 5000, 10000] },
                    AZN: { min: 10, max: 350, hint: [10, 50, 100, 300] }
                },
                kaspi: {
                    enabled: false,
                    phone: "",
                    card: ""
                },
                enabled: []
            },
            google: {
                client_id: ""
            },
            mailru: {
                client_id: ""
            },
            banners: [],
            depositHint: "",
            payoutHint: "",
            k24: false,
            canPromo: false,
            onesignalConfig: null
        },
        // eslint-disable-next-line no-prototype-builtins
        isAndroidApp: window.hasOwnProperty("Android"),
        smsForm: false,
        minKolesoDeposit: ""
    },
    mutations: {
        setCategoryType(state, category) {
            state.category = category;
        },
        setMinKolesoDeposit(state, payload) {
            state.minKolesoDeposit = payload;
        },
        setDisplayWinners(state, payload) {
            state.displayWinners = payload;
        },
        login(state) {
            state.auth = true;
        },
        logout(state) {
            Object.keys(unauthDefaults).forEach(key => {
                state[key] = unauthDefaults[key];
            });
        },
        loading(state) {
            state.currentlyLoading++;
            state.loading = state.currentlyLoading > 0;
        },
        loading_complete(state) {
            state.currentlyLoading--;
            state.loading = state.currentlyLoading > 0;
        },

        set_balance(state, { balance, balances }) {
            setBalance(state, balance, balances);
        },

        load_index(
            state,
            {
                winners,
                balance,
                balances,
                identity_confirmed,
                config,
                player_id,
                player_label,
                has_deposit,
                has_phone,
                phone,
                bonus,
                hold_payout_until,
                out_confirmation_disabled,
                out_confirmation_possible,
                out_confirmation_required_by_system,
                active_promo
            }
        ) {
            state.winners = winners;
            state.identityConfirmed = identity_confirmed;
            state.config = config;
            state.player_id = player_id;
            state.player_label = player_label;
            state.hasDeposit = has_deposit;
            state.hasPhone = has_phone;
            state.phone = phone;
            state.bonus = bonus;
            state.holdPayoutUntil = hold_payout_until;
            state.outConfirmationDisabled = out_confirmation_disabled;
            state.outConfirmationPossible = out_confirmation_possible;
            state.outConfirmationRequiredBySystem = out_confirmation_required_by_system;
            state.outConfirmation = out_confirmation_required_by_system || !out_confirmation_disabled;
            setBalance(state, balance, balances);
            state.logo = logoImage(config.logo);
            state.activePromoCode = active_promo;
        },
        identityConfirmed(state) {
            state.identityConfirmed = true;
        },
        cashBack(state, data) {
            state.cashBack = data;
        },
        paymentHistory(state, history) {
            state.paymentHistory = history;
        },
        paymentHistoryAdd(state, h) {
            state.paymentHistory = {
                items: [...state.paymentHistory.items, ...h.items],
                nextPage: h.nextPage
            };
        },
        validationError(state, error) {
            state.validationError = error;
        },
        serverError(state) {
            state.serverError = true;
        },
        unknownServerError(state) {
            state.unknownServerError = true;
        },
        networkError(state) {
            state.networkError = true;
        },
        setGames(state, gamesList) {
            state.games = gamesList.games;
            state.gamesVersion = gamesList.version;
            gamesCache.set(gamesList);
        },
        smsForm(state, show) {
            state.smsForm = show;
        },
        setOutConfirmationDisabled(state, disabled) {
            state.outConfirmationDisabled = disabled;
        },
        setActivePromo(state, codes) {
            state.activePromoCode = codes;
        },
        setDepositCryptoWallets(state, wallets) {
            state.deposit.crypto = wallets;
        }
    },
    modules: {
        register: register(api.register, partnerData),
        recover: recover(api.recover),
        phoneConfirm: phoneConfirm(api.phoneConfirm),
        phoneSet: phoneSet(api.phoneSet)
    },
    actions: {
        requestDepositCryptoWallets({ commit }) {
            return api.requestDepositCryptoWallets().then(r => {
                commit("setDepositCryptoWallets", r);
                return r;
            });
        },

        redirect(_, url) {
            const link = document.createElement("a");
            link.referrerPolicy = "no-referrer";
            link.rel = "noreferrer";

            link.href = url;
            link.click();
        },

        tryPromoCode({ commit }, { code }) {
            return api.tryPromoCode({ code }).then(r => {
                commit("setActivePromo", r.active);
                return r;
            });
        },
        initConfirmation() {
            return api.initConfirmation();
        },

        outConfirmationDisable({ commit }, { process_id, code }) {
            return api.outConfirmationDisable({ process_id, code }).then(r => {
                if (r.ok) {
                    commit("setOutConfirmationDisabled", true);
                }
                return r;
            });
        },

        outConfirmationEnable({ commit }) {
            return api.outConfirmationEnable().then(r => {
                if (r.ok) {
                    commit("setOutConfirmationDisabled", false);
                }
                return r;
            });
        },

        updateGames({ commit }) {
            api.games().then(r => commit("setGames", r));
        },

        games({ state, dispatch }) {
            if (!state.gamesVersion) {
                dispatch("updateGames");
            }

            api.gamesVersion().then(r => {
                if (r.current !== state.gamesVersion) {
                    dispatch("updateGames");
                }
            });
        },

        balance({ commit }) {
            return api.balance().then(r => {
                commit("set_balance", r);
            });
        },

        logout({ commit }) {
            commit("logout");
            api.logout("logout");
        },

        login({ commit }, { tel, pass }) {
            return api.login(tel, pass).then(r => {
                if (r) {
                    commit("login");
                }
                return r;
            });
        },

        loginTelegram({ commit }, { user }) {
            return api.loginTelegram(user).then(r => {
                if (r) {
                    commit("login");
                }
                return r;
            });
        },

        loginGoogle({ commit }, { token }) {
            return api.loginGoogle(token).then(r => {
                if (r) {
                    commit("login");
                }
                return r;
            });
        },

        loginMailru({ commit }, { code, redirect }) {
            return api.loginMailru(code, redirect).then(r => {
                if (r) {
                    commit("login");
                }
                return r;
            });
        },

        index({ commit, dispatch }) {
            partnerData.populateFromUrl(window.location.href);
            dispatch("games");
            return api.index().then(r => {
                commit("load_index", r);
                if (!r.auth) {
                    commit("logout");
                } else {
                    commit("login");
                    if (!r.has_partner) {
                        partnerData.flush(data => api.sendPartnerData(data));
                    }
                }
                return r;
            });
        },

        startGame(context, { id, returnUrl, refillUrl, language }) {
            return api.startGame(id, returnUrl, refillUrl, language);
        },

        startDemo(context, { id, returnUrl, language }) {
            return api.startDemo(id, returnUrl, language);
        },

        changePassword(context, { from, to }) {
            return api.changePassword(from, to);
        },

        cashBack({ commit }) {
            return api.cashBack().then(r => commit("cashBack", r));
        },

        paymentHistory({ commit }) {
            return api.paymentHistory().then(r => {
                commit("paymentHistory", r);
            });
        },

        paymentHistoryMore({ commit }, page) {
            return api.paymentHistory(page).then(r => {
                commit("paymentHistoryAdd", r);
            });
        },

        deposit(context, { sum, success_url, failed_url, pending_url, ps }) {
            return api.deposit(sum, success_url, failed_url, ps, pending_url);
        },

        depositPhone(context, { ps, sum, success_url, failed_url, phone }) {
            return api.depositPhone(ps, phone, sum, success_url, failed_url);
        },

        depositPhoneComplete(context, { ps, id, code }) {
            return api.depositPhoneComplete({ ps, id, code });
        },

        payout(_, r) {
            return api.payout(r);
        },

        error({ commit, dispatch }, e) {
            if (e.response) {
                if (e.response.status === 401) {
                    dispatch("logout");
                    router.push({ name: "login" });
                }

                if (e.response.status === 422) {
                    return commit("validationError", e.response.json);
                }

                if (e.response.status === 500) {
                    return commit("serverError");
                }

                commit("unknownServerError");
            } else {
                commit("networkError");
            }
        },

        kolesoStatus({ commit }) {
            return api.kolesoStatus().then(r => {
                commit("setMinKolesoDeposit", r.minForFree);

                return r;
            });
        },

        kolesoSpin() {
            return api.kolesoSpin();
        },

        activateBonus({ dispatch }, id) {
            return api.activateBonus(id).then(() => dispatch("index"));
        },

        sendPushToken(_, { token }) {
            return api.setNotificationToken(token);
        },

        sendOnesignalToken(_, { token }) {
            return api.setOnesignalToken(token);
        }
    }
});

export function currencySymbol(c) {
    return (
        {
            KZT: "₸",
            AZN: "₼",
            USDT_TRC20: "₮",
            USDT: "₮"
        }[c] || c
    );
}

function loadingPlugin(store) {
    const original = store.dispatch;
    store.dispatch = (type, ...params) => {
        const action = original.apply(store, [type, ...params]);
        return action.catch(e => store.dispatch("error", e));
    };

    const silent = ["kolesoStatus", "kolesoSpin", "balance", "games"];
    store.subscribeAction({
        before: action => {
            if (silent.includes(action.type)) {
                return;
            }
            store.commit("loading");
        },
        after: action => {
            if (silent.includes(action.type)) {
                return;
            }
            store.commit("loading_complete");
        },
        error: action => {
            if (silent.includes(action.type)) {
                return;
            }
            store.commit("loading_complete");
        }
    });
}
