<template>
  <div class="deposit-form__container crypto_container" v-if="hasPromo">
    <p class="title">{{ $t('promoCode.label') }}</p>
    <div class="input__default">
      <input v-model="code" @input="attempted = false; err = false"/>
      <p :class="{code_result: true, attempted, err}">{{ codeResult }}</p>
    </div>
    <div v-if="!identityConfirmed">
      {{ $t('promoCode.confirmationRequired') }}
      <br><br>
      <button class="btn__orange" @click="$router.push({name: 'profile'})">{{
          $t('promoCode.confirmationRequiredButton')
        }}
      </button>
    </div>
    <button class="btn__orange" v-if="identityConfirmed" @click="tryPromo">{{ $t('promoCode.button') }}</button>
    <Help style="position: absolute; top: 10px; right: 10px">
      Промокоды рассылаются в пуш-уведомлениях официального приложения.<br/><br/>
      Скачать официальное приложение можно <a :href="appUrl"><u>здесь</u></a>.<br/><br/>
    </Help>
  </div>

</template>

<script>
import Help from "@/views/Wallet/Help.vue";

export default {
  name: "PromoCode",
  components: {Help},
  data() {
    return {codeResult: '', code: '', attempted: false, err: false}
  },
  methods: {
    async tryPromo() {
      const r = await this.$store.dispatch('tryPromoCode', {code: this.code});
      this.attempted = true;
      if (r.ok) {
        this.codeResult = this.$t('promoCode.success');
        this.code = '';
        this.err = false;
        this.$store.dispatch('balance')
        localStorage.removeItem('BonusBanner.closed');
      } else {
        this.err = true;
        const mesCode = 'promoCode.err.' + r.code;
        this.codeResult = this.$t(mesCode);
      }
    }
  },
  computed: {
    hasPhone() {
      return this.$store.state.hasPhone;
    },
    identityConfirmed() {
      return this.$store.state.identityConfirmed;
    },
    hasPromo() {
      return this.$store.state.config.canPromo;
    },
    appUrl() {
      return this.$store.state.config.appUrl;
    }
  }
}
</script>

<style scoped>

.code_result {
  position: absolute;
  bottom: -24px;
  left: 20px;
  opacity: 0;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  transition: 0.15s;
}

.code_result.attempted {
  left: 0;
  opacity: 1;
}

.code_result.err {
  color: #f45a0e;
}
</style>
