<template>
  <ConfirmForm
      :onSubmit="send"
      :re-init="initProcess"
      :error="error"
      :expired="expired"
      :tel="tel"
      :key="process_id"
      :cancel="cancel"
  />
</template>

<script>
import ConfirmForm from "./ConfirmForm";

export default {
  name: "AuthenticatedConfirmation",
  components: {ConfirmForm},
  props: {
    cancel: {default: () => this.$router.back()},
    onSuccess: {type: Function},
    submit: {type: Function},
    backRoute: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      error: false,
      expired: false,
      process_id: ''
    }
  },
  computed: {
    tel() {
      return this.$store.state.player_label
    }
  },
  mounted() {
    this.initProcess();
  },
  methods: {
    initProcess() {
      this.$store.dispatch('initConfirmation').then(r => {
        if (r.ok) {
          this.process_id = r.confirm_process
          this.expired = false;
          this.error = false;
          return;
        }

        if(r.error_code === 'wait') {
          this.$router.push({name: 'wait', params: {waitSeconds: r.wait_seconds, route: this.backRoute}})
        }

      })
    },
    send(code) {
      this.submit({process_id: this.process_id, code}).then(r => {

        if (r.confirmation_error === 'invalid_code') {
          this.error = true
        }

        if (r.confirmation_error === 'expired') {
          this.error = true
          this.expired = true
        }

        if (r.confirmation_error === 'attempts_limit') {
          this.error = true
          this.expired = true
        }

        if (!r.confirmation_error) {
          this.onSuccess(r)
        }

      })
    }
  }
}
</script>

<style scoped>

</style>