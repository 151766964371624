<script>

export default {
  name: "OnesignalPush",
  props: {
    onesignalConfig: {
      required: true,
      type: Object
    },
  },
  methods: {
    sendToken() {
      this.$store.dispatch('sendOnesignalToken', {token: this.onesignalConfig.externalId})
    }
  },
  async mounted() {
    const config = {
      appId: this.onesignalConfig.appId,
      safari_web_id: this.onesignalConfig.safariWebId,
      externalId: this.onesignalConfig.externalId,
      allowLocalhostAsSecureOrigin: true,
    };

    let script = document.createElement('script')
    script.setAttribute('src', 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js')
    document.head.appendChild(script)

    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async (OneSignal) => {
      await OneSignal.init(config);
      await OneSignal.login(config.externalId);

      OneSignal.Notifications.addEventListener(
          'permissionChange',
          hasPermission => hasPermission && this.sendToken()
      )

      if (!OneSignal.Notifications.permission) {
        OneSignal.Notifications.requestPermission();
      }
    });

  },
}
</script>

<template>
  <div v-if="false">
  </div>
</template>

