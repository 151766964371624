<template>
  <div class="popup active">
    <div class="popup__container">
      <div class="popup__close" @click="close">
        <img src="../../assets/icons/plus.svg"/>
      </div>
      <div class="popup__image">
        <slot name="image"></slot>
      </div>
      <p class="popup__title">
        <slot name="title"></slot>
      </p>
      <p class="popup__text">
        <slot name="body"></slot>
      </p>
      <div class="popup__button" v-if="!hideButton">
        <button class="btn__orange" @click="take">
          {{ $t('promo.take-bonus') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    close() {
      this.$router.back();
    },
    take() {
      this.$router.push(
          this.$store.state.auth ? '/deposit/form' : '/auth/registration'
      );
    }
  }
}
</script>

<style lang="scss" scoped>

.popup {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 1012;
  overflow: auto;
  background: #2c2c4d;
  transition: 0.5s;

  .popup__container {
    padding: 20px;

    .popup__close {
      max-width: max-content;
      margin: 0 0 5px auto;

      img {
        transform: rotate(45deg);
      }
    }

    .popup__image {
      max-width: max-content;
      margin: 0 auto 12px auto;
      z-index: 1;
      border-radius: 6px;

      img {
        border-radius: 6px;
        height: 100%;
      }
    }

    .popup__title {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }

    .popup__text {
      margin-bottom: 40px;
      font-weight: 300;
      font-size: 16px;
      line-height: 140.7%;
      color: #ffffff;
      white-space: pre-line;
    }

    .popup__button {
      position: relative;
    }
  }

  &.active {
    top: 0%;
    visibility: visible;
  }
}
</style>
