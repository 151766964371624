<template>
  <Error
    title="Нужно подождать"
    :message="message"
    :button-callback="goBack"
    button-text="Назад"
  />
</template>

<script>
import Error from "../Ui/Error.vue";

export default {
  name: "WaitSms",
  components: {
    Error
  },
  props: {
    route: {
      type: String,
      default: ''
    },
    waitSeconds: {
      type: Number,
      default: 0,
      required: true
    }
  },
  methods: {
    goBack() {
      if(this.route !== '') {
        this.$router.push(this.route);
      } else {
        this.$router.back();
      }
    }
  },
  computed: {
    message() {
      const left = Math.ceil(this.waitSeconds / 60);

      return 'Повторная отправка СМС доступна через ' + left + ' ' + plural(['минуту', 'минуты', 'минут'], left);
    }
  }
};

/**
 * @param {Array<String>} forms
 * @param {Number} n
 * @returns {String}
 */
function plural(forms, n) {
  let idx;
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0; // one
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1; // few
  } else {
    idx = 2; // many
  }
  return forms[idx] || '';
}
</script>
