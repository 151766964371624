<template>
  <div class="success-form">
    <div class="success-form__container">
      <div class="success__message">
        <img src="../../assets/icons/alerts/success.svg"/>
        <p class="success__title">{{ $t('payOut.success.title') }}</p>
        <p class="success__text">{{ $t('payOut.success.text') }}</p>
        <p v-if="false" class="success__text message">В связи с высокой нагрузкой, выплаты производятся в течении 6 часов</p>
      </div>
    </div>
    <div class="accept__button">
      <button class="btn__orange" @click="$router.push('/')">{{ $t('payOut.success.link') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style lang="scss" scoped>
.success-form {
  height: calc(100vh - 80px);
  min-height: 450px;
  /* position: relative; */

  .success-form__container {
    padding: 20px;

    .success__message {
      position: relative;
      top: 40px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 22px;
      }

      .success__title {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: #ffffff;
      }


    }
  }

  .accept__button {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 32px;
  }
}

.success__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.message {
  font-size: 1.6rem;
  margin-top: 2rem;
  line-height: normal;
}

</style>