<template>
  <div v-if="bonusActive">
    <p class="title">
      {{ $t('history.cashback') }}
      <Help style="position: absolute; z-index: 3; margin-top: 4px; margin-left: 4px"
            :custom-popper-style="{width: '360px', maxWidth: '100vw'}">
        КешБек равен 10% от проигрыша.<br><br>

        Неделя КешБека начинается с пятницы 18:01 после первого пополнения счета.<br>
        Закрывается неделя КешБека в следующую пятницу в 17:59 и в 18:00 автоматически выплачивается на Ваш
        счет!<br><br>

        - Тиражные и Лайф игры не получают КешБек<br>
        - Минимальная сумма начисления - {{ cashback.min }} {{ currency }}<br>
        - Для активации накоплений - нужно пополнить баланс.<br>

      </Help>
      <span class="timer"><Timer :seconds-left="secondsLeft"/></span>
    </p>
    <div :class="{timeline: true, hasMin: !cashback.sumEnough}">
      <div v-for="day in days" class="day" :key="day.n" :class="{...day.class}">
        <div v-if="!day.class.active" class="number">{{ day.n }}</div>
        <div v-if="!day.class.active" class="label">DAY</div>
        <div v-if="!day.class.active" class="bar"></div>

        <div v-if="day.class.active" :class="{coin: true, coinDisabled: !cashback.sumEnough}"></div>
        <div v-if="day.class.active" class="sum">{{ cashback.sum }} {{ currency }}</div>
        <div v-if="day.class.active && !cashback.sumEnough" class="min">min {{ cashback.min }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Help from "../../../views/Wallet/Help";
import Timer from "./Timer";

export default {
  name: "CashBack",
  components: {Help, Timer},
  data: function () {
    return {
      left: ''
    }
  },
  computed: {
    bonusActive() {
      return this.cashback.bonusActive
    },
    days() {

      const days = [];
      const end = this.cashback.scheduledTo;
      const dayLen = 24 * 60 * 60;
      const now = Date.now() / 1000;

      let n = 0;
      for (let i = 6; i >= 0; i--) {
        n++;
        const dayEnd = end - (dayLen * i);
        const dayBegin = dayEnd - dayLen;
        const active = now > dayBegin && now < dayEnd;
        const before = dayEnd < now;
        const after = dayBegin > now;

        const date = new Date(dayBegin * 1000).toLocaleString();
        days.push({n, class: {active, before, after}, date});
      }

      return days;
    },
    currency() {
      return this.$store.state.currency;
    },
    cashback() {
      // return {"sum":2,"scheduledTo":1668168000};
      return this.$store.state.cashBack
    },
    secondsLeft() {
      const now = Date.now() / 1000;
      const payTime = this.cashback.scheduledTo
      return payTime - now;
    }
  },
  beforeMount() {
    this.$store.dispatch('cashBack')
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.timer {
  color: #fee870;
  float: right;
}

.timeline {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.timeline.hasMin {
  margin-bottom: 1vw;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 25%;
  width: 10vw;
  height: 10vw;

  font-weight: bold;
  color: #ffffff;

  position: relative;
}

.bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5vw;
  height: 4vw;
  display: block;
  background-color: #60BF75;
  z-index: 1;
}

.active {
  width: 15vw;
  height: 15vw;
  background-color: #3452ff;
  box-shadow: 0 0 1vw .2vw rgba(50, 67, 255, .8);
  z-index: 2;
  margin: -1vw;
}

.after {
  margin-left: 2.5vw;
  background-color: #2f3051;
  color: #78788e;
}

.after .bar {
  background-color: #2f3051;
  left: -4.9vw;
}

.before {
  margin-right: 2.5vw;
  background-color: #424ea8;
}

.before .bar {
  background-color: #424ea8;
  right: -4.9vw;
}


.sum {
  white-space: nowrap;
  font-size: calc(5vw * 0.76);
}

.number {
  line-height: 5vw;
  font-size: 5vw;
}

.label {
  font-size: calc(5vw * 0.55);
  line-height: calc(5vw * 0.55);
}

.coin {
  background-image: url("./coin.png");
  background-size: contain;
  width: 50%;
  height: 50%;
  background-repeat: no-repeat;
}

.coinDisabled {
  background-image: url("./coin-disabled.png");
}

.min {
  position: absolute;
  border-radius: 10px;
  background-color: #2f3051;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
  white-space: nowrap;
  font-size: 2.5vw;
  width: 15vw;
  text-align: center;
  transform: translateY(120%);
}

</style>
