<template>
  <div class="footer">
    <!-- <router-link :to="{ name: 'help' }" class="btn__orange help" @click.native="click">
      Онлайн помощь
    </router-link> -->
    <div style="flex-grow: 0">
      <a v-show="!isApp && appUrl" :href="appUrl">
        <div class="store-container">
          <div class="store-button">
            <img src="../../../assets/icons/menu/google-play.png" alt=""/>
          </div>
          <div class="store-button">
            <img src="../../../assets/icons/menu/apple-store.png" alt=""/>
          </div>
        </div>
      </a>
      <div v-if="testUser">
        <button @click="share()" class="btn__orange">share ya.ru</button>
        <button @click="homepage()" class="btn__orange">homepage ya.ru</button>
      </div>
    </div>
    <LanguageSwitcher :onClose="click"/>
  </div>
</template>

<script>
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher";

export default {
  name: "Footer",
  components: {LanguageSwitcher},
  props: {
    click: {
      type: Function,
      required: true
    }
  },
  methods: {
    share() {
      window.Android.share('https://ya.ru/');
    },
    homepage() {
      window.Android.setHomepage('https://ya.ru/');
    }
  },
  computed: {
    testUser() {
      return [].includes(this.$store.state.player_id);
    },
    appUrl() {
      return this.$store.state.config.appUrl;
    },
    isApp() {
      return this.$store.state.isAndroidApp;
    }
  }
}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: space-between;
  margin: 36px 22px;
}
.store-container {
  display: flex;
}
.store-button {
  flex: 1;
  margin-right: 10px;
}
</style>
