<template>
  <div class="license">
<!--    <div class="license__container">-->
<!--      <div class="license__title">{{ $t('licence.header') }}</div>-->
<!--      <div class="license-text">-->
<!--        <p class="title">Licence</p>-->
<!--        <p class="text">-->
<!--          The web-site is operated by B.M BLAD acting as a payment agent, a company registered and established under the laws of Cyprus, with registered address Spyrou Kyprianou 31, 400 Limassol, Cyprus, its parent company-->
<!--          Lotta's N.V. is licensed (Curacao License #8963/JAZZU2019-0007) and regulated by Lotta's N.V. registration number is 14467323 and its Business address: Pseusweg 55A, Curaçao.-->
<!--          Mailing address: Perseusweg 55gA, Curaçao.-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->


    <div class="licence__container">
      <div class="licence__title">{{ $t('menu.licence') }}</div>
      <div
          v-for="(content, index) in contentArray"
          :class="['licence-text', { opened: opened === index }]"
          :key="index"
          @click="toggleFaq(index)"
      >
        <div class="licence-text__title">
          <p class="title">{{ content.title }}</p>
          <img src="../assets/icons/plus.svg"/>
        </div>
        <p class="text" v-html="content.text"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: -1
    }
  },
  methods: {
    toggleFaq(index) {

      if (this.opened === index) {
        this.opened = -1;
      } else {
        this.opened = index;
      }

    },
  },

  computed: {
    contentArray() {
      return [
        {
          title: 'Контакты',
          text: "Технические вопросы: help@loto37.club<br><br>"
          + "По вопросам сотрудничества: Partner@loto37.club<br><br>",
        },
        {
          title: this.$t('licence.header'),
          // text: "The web-site is operated by B.M BLAD acting as a payment agent, a company registered and established under the laws of Cyprus, with registered address Spyrou Kyprianou 31, 400 Limassol, Cyprus, its parent company\n" +
          //     "Lotta's N.V. is licensed (Curacao License #8963/JAZZU2019-0007) and regulated by Lotta's N.V. registration number is 14467323 and its Business address: Pseusweg 55A, Curaçao.\n" +
          //     "Mailing address: Perseusweg 55gA, Curaçao.\n" +
          //     "The trusted payment agent in the CIS is the company OT-Technologiya LLP.",
          text: "The web-site is operated by Softline365 B.V.  Сompany registered of Curaсao, registration number  165975, License number  ALSI-l 92407065-Fl3"
        },
        {
          title: this.$t('licence.policy'),
          text: "1. Персональные данные игрока (то есть любая информация о игроке, по которой он может быть лично идентифицирован, такая как: имя, адрес, номер телефона или адрес электронной почты) будет обрабатываться нами (в том числе нашими агентами и сотрудниками), а также (при необходимости) нашими партнерами и субподрядчиками для того, чтобы предоставить полный спектр услуг для игрока. Информация об игроке собирается во время его регистрации, чтобы открыть счет.<br/>\n" +
              "<br/>\n" +
              "2. При использовании любой из услуг, игрок признает, что он прочитал и согласен с условиями Политики конфиденциальности, и тот пункт, где игрок предоставляет нам информацию (включая, во избежание сомнений, подачу нам запроса через один из веб-сайтов, или тот, где игрок лишь частично завершает процесс регистрации учетной записи).<br/>\n"
        },
        {
          title: this.$t('licence.info'),
          text: "1. Мы собираем информацию об игроке для того, чтобы:<br/>\n" +
              "<br/>\n" +
              "позволить создать аккаунт игрока, чтобы он смог в полной мере использовать все функции услуг, в том числе делать ставки и играть в игры;<br/>\n" +
              "позволить нам получать вопросы и комментарии от игрока с помощью нашей службы поддержки, а также быть в состоянии реагировать на них;<br/>\n" +
              "соблюдать соответствующие правила, касающиеся регистрации игрока, например, завершения проверки возраста, и информировать игрока о будущих событиях, предложениях и акциях от компании;<br/>\n" +
              "В дополнение к широкому списку целей, указанных в пункте 1 выше, компания может использовать информацию об игроке, включая обмен информацией с нашими партнерами и субподрядчиками, которые обрабатывают данные (в том числе, в соответствующих случаях, информацию об игроке) от нашего имени, исключительно для:<br/>\n" +
              "создания, эксплуатации и управления счетом игрока;<br/>\n" +
              "предоставления любой из услуг, которые запрашиваются у нас, и для любой цели, связанной с таким предоставлением;<br/>\n" +
              "проверка достоверности информации об игроке, в том числе раскрытие такой информации третьим лицам (включая финансовые учреждения, проверку возраста и отчетность кредитных учреждений) в связи с такими целями (отчет о поиске будет сохранен, и третья сторона может использовать информацию, чтобы помочь другим компаниям в целях проверки);<br/>\n" +
              "подготовки статистических данных, касающихся использования услуг;<br/>\n" +
              "подготовки и предоставления индивидуальных маркетинговых материалов;<br/>\n" +
              "периодической отправки держателям счета письменных сообщений, чтобы объявить о важных изменениях в сервисе, вопросах технического обновления и изменениях в своде правил (который включает в себя Политику конфиденциальности);<br/>\n" +
              "соответствия требованиям законодательства и действий для защиты интересов спортивных органов или других компетентных органов;<br/>\n" +
              "для изучения при подозрении в незаконной, мошеннической или другой неправомерной деятельности, связанной с услугами;<br/>\n" +
              "сообщения о совершении преступления или подозреваемого преступления, включая отмывание денег или мошенничество, а также любые другие цели, которые являются необходимыми для выполнения наших договорных обязательств перед игроком.<br/>\n" +
              "<br/>\n" +
              "2. Компания не будет отправлять игроку информацию о коммерческих предложениях не связанных с нашим продуктом или рекламу третьих лиц.<br/>\n" +
              "<br/>\n" +
              "3. Компания может использовать имя игрока и/или имя и регион в рекламных целях.<br/>\n" +
              "<br/>\n" +
              "4. Телефонные звонки в/из нашего отдела обслуживания клиентов записываются для обучения и обеспечения безопасности.<br/>\n" +
              "<br/>\n" +
              "5. Мы можем передать информацию об игроке в случае, если мы будем передавать или продавать свой бизнес или любую его часть, в том числе в случае неплатежеспособности.<br/>\n" +
              "<br/>\n" +
              "6. Если игрок захочет, чтобы мы прекратили обработку информации об игроке для указанных выше целей, то игрок должен связаться с нами, и мы примем соответствующие меры, чтобы остановить это.<br/>\n"
        },
        {
          title: this.$t('licence.player'),
          text: "Игрок может обновлять или удалять информацию о себе в любое время самостоятельно или связавшись со службой поддержки"
        },
        {
          title: this.$t('licence.agreement'),
          text: "Настоящий договор об оказании игорных услуг на сайте " + window.location.hostname + " (далее – сайт) заключается между компанией Lotta's N.V. (далее – «Оператор»), зарегистрированной и действующей в соответствии с законодательством Кюрасао в качестве одной стороны, и физическое лицо, использующее игорные услуги на веб-сайте (далее именуемое «Игрок») в качестве другой стороны, (далее именуемое «Соглашение»).<br/>\n" +
              "Настоящее Соглашение является публичным договором.<br/>\n" +
              "1. Предмет договора.<br/>\n" +
              "1.1. Услуги по участию Игрока в списке ставок<br/>\n" +
              "1.1.1. Оператор оказывает Игроку услуги по участию Игрока в списке ставок в соответствии с действующим порядком Правил игр, утвержденных Lotta's (далее – «Правила»), размещенных на сайте. В случае если Правила представлены не на русском языке, русскоязычная версия указанных Правил имеет преимущественную силу.<br/>\n" +
              "1.1.2. Оператор предлагает Игроку возможность создавать коэффициенты ставок и управлять игровым счетом на сайте в сети Интернет.<br/>\n" +
              "1.2. Услуги по участию в других играх на сайте.<br/>\n" +
              "1.2.1. Оператор оказывает Игроку услуги по участию Игрока в других играх, представленных на сайте, в соответствии с действующим порядком, утвержденным Оператором, который предусматривает для каждой игры во вкладке «Как играть?» (далее – «Правила игры»).<br/>\n" +
              "1.3. Правила и Правила игры являются частью настоящего Соглашения. В случае противоречия между положениями Правил и/или Правил игры и настоящим Соглашением, положения настоящего Соглашения имеют преимущественную силу.<br/>\n" +
              "1.4. Положения настоящего Соглашения распространяются на услуги по участию Игрока в списке ставок, а также на услуги по участию в других играх на сайте.<br/>\n" +
              "1.5. При регистрации Игрока на сайте Оператора в сети Интернет считается, что Игрок принял условия настоящего Соглашения, полностью ознакомился с Правилами и Правилами игры и согласен играть в соответствии с ними, а также Игрок соглашается со всеми тарифами и комиссионными ставками Оператора за перевод денежных средств, а также соглашается с e-mail и SMS-уведомлениями о новых услугах.<br/>\n" +
              "2. Права и обязанности сторон.<br/>\n" +
              "2.1. Оператор должен:<br/>\n" +
              "2.1.1. Зарегистрировать Игрока и присвоить ему уникальный номер игрового счета.<br/>\n" +
              "2.1.2. Предоставить Игроку возможность управлять игровым аккаунтом.<br/>\n" +
              "2.1.3. Сохранять конфиденциальность информации об Игроке, полученной при регистрации, сделанных ставках, игровых результатах Игрока и расчетах с ним, а также иной информации в соответствии с Законом о защите персональных данных Кюрасао 2013.<br/>\n" +
              "2.1.4. Обеспечить Игроку техническую поддержку по электронной почте и телефону «горячей линии».<br/>\n" +
              "2.2. Оператор имеет право:<br/>\n" +
              "2.2.1. Приостановить оказание услуг в случае невыполнения Игроком положений настоящего Соглашения или Правил/Правил игры.<br/>\n" +
              "2.2.2. Изменять условия настоящего Договора в одностороннем порядке, не направляя Игроку дополнительно уведомлений о изменениях в Договоре, Правилах/Правилах Игр. Игрок обязан самостоятельно следить за соответствующими изменениями.<br/>\n" +
              "2.3. Игрок обязан:<br/>\n" +
              "2.3.1. Самостоятельно ознакомиться с Правилами/Правилами Игр и получить исчерпывающие разъяснения по неясным вопросам.<br/>\n" +
              "2.3.2. Хранить в тайне номер своего игрового счета и пароли доступа к нему. Все операции, произведенные с использованием корректно введенных имени Игрока и пароля и/или номера счета, будут рассматриваться как действительные вне зависимости от того, были ли они авторизованы Игроком, и компания не несет ответственности за претензии в том случае, если Игрок сообщит свое имя пользователя, пароль или номер счета другому лицу.<br/>\n" +
              "2.4. Игрок имеет право:<br/>\n" +
              "2.4.1. Получать от компании полный перечень ставок, в соответствии с действующими Правилами/Правилами Игр.<br/>\n" +
              "2.4.2. Клиент имеет право один раз в 90 дней в одностороннем порядке отказаться от игры с компанией, предварительно сообщив об этом по электронной почте с зарегистрированного адреса, обязательно пройдя авторизацию. Счет будет заблокирован после проведения окончательных взаиморасчетов.<br/>\n" +
              "В исключительных случаях, игрок может повторно подать запрос на отказ от игры, но не менее, чем через 30(тридцать) дней после предыдущего запроса. Компания оставляет право отказать в повторном блокировании счета.<br/>\n" +
              "2.4.3. Устанавливать для своего игрового счета ограничения по максимальному размеру ставок и потраченных денежных средств или применять самоотвод от игры на свое усмотрение в порядке, предусмотренному Правилами/Правилами Игр.<br/>\n" +
              "3. Порядок расчетов.<br/>\n" +
              "3.1. Все взаиморасчеты осуществляются в соответствии с действующими Правилами/Правил Игр. Все ставки сборов и ставки комиссионных сборов за осуществление перечисления денежных средств зависят от страны резидентства Игрока или страны, на территории которой находится Игрок в момент получения услуг. Все ставки сборов компании и ставки комиссионных сборов за осуществление перечисления денежных средств указаны в Правилах/Правилах Игр.<br/>\n" +
              "3.2. Игрок несет ответственность за корректность осуществляемых им платежей и хранение расчетных документов.<br/>\n" +
              "3.3. Фактом оплаты считается фактическое поступление средств на счета компании.<br/>\n" +
              "3.4. Если игровой счет Клиента был заблокирован по причине мошеннических или других противозаконных действий со стороны Игрока, остаток на его игровом счете направляется лицам, пострадавших от указанных действий Игрока, или для целей благотворительности.<br/>\n" +
              "3.5. В случае технических неполадок в системе расчетов компании, выплата Игроку осуществляется в порядке, предусмотренном Правилами/Правилами Игр.<br/>\n" +
              "3.6. Компания не предоставляет Игроку кредиты для совершения игры.<br/>\n" +
              "3.7. Для получения услуг по участию в перечне пари и услуг по участию в других играх на сайте используется единый игровой счет Игрока.<br/>\n" +
              "3.8. Все расчеты на территории СНГ осуществляются через доверенного платежного агента - компанию ТОО \"ИТ-Технология\".<br/>\n" +
              "4. Особые условия и ответственность сторон.<br/>\n" +
              "4.1. Компания не несет ответственности за сохранность денежных средств на игровом счете в случае разглашения Игроком номера счета и паролей доступа к нему.<br/>\n" +
              "4.2. Компания не несет ответственности за упущенные выгоды, в случае временных технических неполадок сервера и электронных каналов связи, которые могут повлечь невозможность Игроком пользоваться сервисом.<br/>\n" +
              "4.3. Компания не несет ответственность за качество работы финансовых и технических партнеров и посредников.<br/>\n" +
              "4.4. Компания не несет ответственность за предоставление по требованию государственных органов информации об Игроке, полученной при регистрации Игрока, сделанных ставках, результатах игры Игрока и денежных расчетах с ним. Игрок разрешает оператору собирать, хранить и использовать его персональные данные для целей оценки его платежеспособности или подбора для него маркетинговых продуктов и услуг.<br/>\n" +
              "4.5. Игрок принимает на себя в полном объеме все риски, связанные с использованием телефонных линий, Интернета и прочих средств связи.<br/>\n" +
              "4.6. Игрок в полном объеме отвечает за сохранность номера игрового счета и паролей доступа к нему.<br/>\n" +
              "4.7. Регистрация Игрока означает полную удовлетворенность им средствами безопасности, применяемых оператором.<br/>\n" +
              "4.8. Регистрация Игрока означает гарантию со стороны Игрока того, что:<br/>\n" +
              "4.8.1. Игрок достиг возраста 18 лет и согласно законодательству страны, резидентом которой является Игрок или страны, на территории которой находится Игрок в момент получения услуг, Игрок имеет право получать услуги, предоставляемые оператором.<br/>\n" +
              "4.8.2. Игрок является полноправным владельцем денежных средств, которые он размещает на своем игровом счете.<br/>\n" +
              "4.9. Игрок подтверждает свое согласие на проверку своего возраста, страны резидентства Игрока или страны, на территории которой находится Игрок в момент получения услуг, и идентификацию его личности.<br/>\n" +
              "4.10. Игрок гарантирует, что вся информация, которую он предоставил, является правдивой.<br/>\n" +
              "4.11. В случае, если данный Договор представлен не на русском языке, преимущественную силу имеет русскоязычная версия данного Договора.<br/>\n" +
              "4.12. В случае, если компания обнаружит, что Игрок не достиг возраста 18 лет, а также в случае обнаружения мошеннических или других противозаконных действий со стороны Игрока, оператор может применить блокировку игрового счета Игрока и проводить расследование действий Игрока согласно с Правилами/Правилами Игр.<br/>\n" +
              "4.13. Компания не предоставляет услуги на территории стран, законодательство которых запрещает пользование онлайн услугами, предоставляемыми на веб-сайте, в частности на территории следующих стран: США, Израиль, Сингапур, Территория Мохоки Канавак (Канада), Эстония, Франция, Республика Кипр, Великобритания, Италия. Настоящим, компания информирует, что законодательство Сингапура запрещает предоставление игровых услуг онлайн потребителям, которые физически находятся в Сингапуре. Не смотря на вышеизложенные положения в данном пункте, Компания не обязана и не будет: <br/>\n" +
              "(a) уведомлять Игрока о наличии или содержании положений законодательства какой-либо страны, которые запрещают пользование онлайн услугами, которые предоставляются на веб-сайте<br/>\n" +
              "(b) предоставлять консультаций по этим вопросам.<br/>\n" +
              "4.14. Настоящим Игрок гарантирует, что в момент получения онлайн услуг:<br/>\n" +
              "(a) он/она физически не находится на территории какой-либо из стран, указанных в пункте 4.13 Договора;<br/>\n" +
              "(b) он/она соблюдает законодательство страны гражданства Игрока и/или страны, на территории которой находится Игрок в момент получения услуг, в отношении права или запрета на пользование услугами, предоставляемыми на веб-сайте.<br/>\n" +
              "4.15. Игрок обязан самостоятельно осуществлять действия, которые могут требоваться положениями законодательства страны налогового резидентства Игрока и/или страны, на территории которой находится Игрок в момент получения выигрыша, в связи с уплатой налогов или других обязательных платежей с полученного им дохода от Оператора. Компания не обязана уведомлять Игрока о наличии или содержании таких положений законодательства, а также не предоставляет консультаций по этим вопросам.<br/>\n" +
              "5. Форс-мажор.<br/>\n" +
              "5.1. Стороны согласились, что в случае возникновения форс-мажорных обстоятельств (действия непреодолимой силы, которая не зависит от воли сторон Договора), включая действия государственных органов, которые делают невозможным выполнение Сторонами своих обязательств по этому Договору, пожар, наводнения, другие стихийные беды, Стороны освобождаются от выполнения своих обязательств по этому Договору на время действия отмеченных обстоятельств. В случае, когда действие отмеченных обстоятельств длится более чем 30 (тридцать) календарных дней, каждая из Сторон имеет право разорвать этот Договор и не несет ответственности за такое расторжение при условии, что она уведомит об этом другую Сторону не позднее как за 15 (пятнадцать) дней до расторжения. Достаточным доказательством действия форс-мажорных обстоятельств является документ, выданный торгово-промышленной Палатой. <br/>\n" +
              "6. Порядок рассмотрения претензий и споров.<br/>\n" +
              "6.1. Претензии Игрока принимаются к рассмотрению только в письменном виде и в срок не позднее 10 (десяти) календарных дней с даты возникновения спорной ситуации. Срок рассмотрения претензий Игрока составляет не более 30 (тридцати) календарных дней.<br/>\n" +
              "6.2. Рассмотрение претензий осуществляется только при наличии у Игрока соответствующих финансовых документов, подтверждающих оплату заключенного пари.<br/>\n" +
              "6.3. Споры, возникающие относительно данного Договора, рассматриваются судом Кюрасао или Curaçao eGaming, которая уполномочена и регулируется Правительством Кюрасао, в соответствии с законодательством Кюрасао. Для обращения в Curaçao eGaming можно отправить сообщение электронной почтой по адресу compliance@curacao-egaming.com.<br/>\n" +
              "7. Права на объекты интеллектуальной собственности<br/>\n" +
              "7.1. Все элементы дизайна, тексты, графические изображения, музыка, звуки, фотографии, видеоматериалы и их подборки и расположение на сайте оператора, а также компиляции программного обеспечения, исходные коды, ПО и все прочие материалы являются объектами защиты авторских прав и иных прав собственности, которые либо принадлежат Компании, либо используются Оператором по лицензии, выданной третьими лицами, являющимися их владельцами. В той части, в которой любые материалы могут быть скачаны или распечатаны, такие материалы также могут быть скачаны на отдельный персональный компьютер, и их отдельные части могут быть распечатаны для использования в личных и некоммерческих целях Игрока.<br/>\n" +
              "7.2. Ни при каких обстоятельствах использование услуг Компания не дает Игроку прав на любую интеллектуальную собственность (например, авторские права, ноу-хау или торговые марки), принадлежащую Компании или любому другому третьему лицу.<br/>\n" +
              "7.3. Игрок не имеет право самостоятельно осуществлять или позволять другому лицу осуществлять копирование, хранение, опубликование, аренду, лицензирование, продажу, распространение, изменение, добавление, удаление, уничтожение или нарушение работы веб-сайта или любого его раздела любым способом, а также напрямую или опосредованно нарушать или вмешиваться в работу веб-сайта (либо планировать такие действия) или изменять его, за исключением случаев, когда это происходит в ходе просмотра или использования веб-сайта согласно Правилам/Правилами Игр.<br/>\n" +
              "8. Заключение и расторжение договора.<br/>\n" +
              "8.1. Момент регистрации Игрока на сайте считается моментом заключения Договора.<br/>\n" +
              "8.2. Расторжением Договора считается уведомление Игрока с предоставлением подтверждения произведенных с ним окончательных расчетов.<br/>\n" +
              "8.3. Уступка обязательств по Договору со стороны Игрока не допускается. Игрок не может уступать свои обязательства по настоящему Договору, равно как и любые права или обязательства по настоящему Договору, любому другому физическому или юридическому лицу.<br/>\n" +
              "8.4. Компания оставляет за собой право перевести или передать права и обязанности по данному Договору целиком или частично без уведомления Игрока при условии, что любая такая передача будет на тех же условиях или на не менее благоприятных для Игрока.<br/>\n" +
              "8.5. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим законодательством Кюрасао.<br/>\n" +
              "8.6. Lotta's N.V. осуществляет предоставление услуг по участию Игрока в перечне пари и услуг по участию Игрока в других играх, представленных на сайте, согласно лицензии на игорную деятельность, полученную по законодательству Кюрасао."
        },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.license {
  .licence__container {
    padding: 0 20px;

    .licence__title {
      margin: 17px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }

    .licence-text {
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid #595a69;
      overflow: hidden;
      transition: 1s;

      .licence-text__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          max-width: 230px;
          margin-bottom: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 140.7%;
          color: #ffffff;
        }

        img {
          transition: 0.4s;
          transform: rotate(0);
        }
      }

      .text {
        max-height: 0;
        font-weight: 300;
        position: relative;
        font-size: 16px;
        line-height: 140.7%;
        color: #ffffffea;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
        transform: translateY(40px);
      }

      &.opened {
        transition: 2s;

        .licence-text__title {
          img {
            transform: rotate(45deg);
          }
        }

        .text {
          max-height: 10000px;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
