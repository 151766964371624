<template>
  <div>
    <div class="border"></div>
    <div class="site__links">

      <router-link :to="{ name: 'license' }" class="menu__link" @click.native="click">
        {{ $t('menu.licence') }}
      </router-link>
      <router-link :to="{ name: 'faq' }" class="menu__link" @click.native="click">
        {{ $t('menu.faq') }}
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "BottomLinks",
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
