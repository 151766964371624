export default api => ({
    namespaced: true,
    state: {
        process_id: '',
        tel: '',
        confirmed: false
    },
    mutations: {
        init(state, {id, tel}){
            state.process_id = id
            state.tel = tel
        },
        reset(state) {
            state.process_id = ''
            state.tel = ''
            state.confirmed = false
        },
        confirmed(state, r) {
            state.confirmed = r
        }
    },
    actions: {
        init({commit}, {phone}) {
            return api.init(phone)
                .then(r => {
                    if(r.success) {
                        commit('init', {id: r.process_id, tel: phone})
                    }
                    return r;
                })
        },
        confirm({commit, state}, code) {
            return api.confirm(code, state.process_id, state.tel)
                .then(r => {
                    commit('confirmed', r.success)
                    return r;
                })
        },
    }

})