<template>
  <div class="page">
    <div class="page-container">
      <h2>Лотерея 777
      </h2>
      <p>В Казахстане игрокам предлагаются разные типы лото. Некоторые из них не слишком востребованы, другие — собирают
        сотни тысяч участников. Ко второй категории относят Loto 777. Эта разновидность лотереи популярна не только
        благодаря простым правилам, но и за счет регулярных тиражей. Они проходят каждый день. О том, как в них
        участвовать и сколько можно выиграть, рассказано далее.
      </p>
      <h2>Азарт и призы в лотерее «777»
      </h2>
      <p>777 — лото, в котором всегда разыгрываются денежные призы. Их размер зависит от того, какой именно тип ставки
        выбрал участник и сколько цифр удалось отгадать. Ниже рассмотрена подробная схема начисления выплат:
      </p>
      <p><br></p>
      <table class="table table-bordered">
        <tbody>
        <tr>
          <td>Категория ставки</td>
          <td>Тип ставки</td>
          <td>Количество совпавших номеров</td>
          <td>Размер выигрыша в тенге</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Точный порядок</td>
          <td>3</td>
          <td>50 000</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Любой порядок</td>
          <td>3</td>
          <td>20 000<br>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Любой порядок</td>
          <td>2</td>
          <td>10 000</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Первая пара</td>
          <td>2</td>
          <td>5000</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Последняя пара</td>
          <td>2</td>
          <td>5000</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Любая пара</td>
          <td>2</td>
          <td>1000</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Любой порядок</td>
          <td>1</td>
          <td>200</td>
        </tr>
        </tbody>
      </table>
      <p><br></p>
      <h2>Играйте в «777» и побеждайте
      </h2>
      <p>Лотерея 777 представлена в нескольких вариантах. Первый из них предусматривает участие в живых тиражах. Они
        проходят ежедневно и транслируются в 16:00 (время Астаны) на ТРК «31 Канал». Выше мы представили варианты ставок
        и размеры выплат именно для такого формата loto.
      </p>
      <p>Есть также моментальная лотерея 777, она доступна в онлайн-казино. Участвовать в ней можно в любое время суток
        неограниченное количество раз. Для этого не нужно ожидать тиражей. Игра 777 предусматривает мгновенные
        розыгрыши. Достаточно пройти регистрацию на официальном сайте казино, выбрать слот с этим лото, оплатить билет и
        стереть защитный слой ячеек. Если будут совпадения по цифрам, то вы получите моментальную выплату. Ее размер
        зависит от выбранного аппарата в азартном клубе.
      </p>
      <h2>Легендарная лотерея «777»: правила и возможности
      </h2>
      <p>Несмотря на возможность играть в 777 в онлайн-казино, многие все же выбирают традиционный формат легендарной
        лотереи, предусматривающий тиражи в офлайне. Остановимся подробнее на том, как принять в них участие:
      </p>
      <ol>
        <li>Зайдите на официальный сайт организатора.
        </li>
        <li>Заполните купон. В нем нужно выбрать тип ставки, а затем в поле рядом отметить номера, которые, по вашему
          мнению, окажутся выигрышными. Но помните, что в одном столбце можно зачеркнуть только 1 цифру.
        </li>
        <li>Оплатите покупку билета с помощью банковской карты Visa или Mastercard, электронного кошелька или другого
          удобного метода.
        </li>
        <li>Убедитесь, что система приняла оплату, а ваш купон засчитан.
        </li>
      </ol>
      <p>Офлайн-розыгрыш лото 777 проводят два ведущих. Сначала они озвучивают общие правила. Далее один из них начинает
        раскручивать лототрон, состоящий из трех барабанов. Из каждого из них случайным образом выбирается по одному из
        шаров с номерами от 0 до 9. Далее ведущие объявляют выигрышные номера и анонсируют следующий тираж. Победители
        могут получить свои выигрыши на сайте организатора или в одном из его офисов.
      </p>
      <h2>Секреты успешной игры в «777»
      </h2>
      <p>Для победы в 777 недостаточно купить лотерейный билет. Нужно попытаться верной предугадать выигрышные номера.
        Иначе призовой фонд уйдет в пользу других участников.
      </p>
      <p>Некоторым может показаться, что в 777 все зависит от удачи. Да, она играет важную роль. Но у любителей loto
        есть также определенные стратегии, которые помогают им часто побеждать в тиражах.
      </p>
      <p>Популярные схемы:
      </p>
      <ul>
        <li>Выбор горячих чисел. Игроки включают в купон те номера, которые чаще всего выпадали в предыдущих тиражах.
          Посмотреть их статистику можно на сайте организатора.
        </li>
        <li>Ставки на холодные номера. Альтернативная схема, предусматривающая выбор тех чисел, которые не выпадали в
          предыдущих розыгрышах.
        </li>
        <li>Покупка нескольких билетов для участия в лотереи 777 и выбор в них разных цифр. Это повышает шансы на
          победу.
        </li>
        <li>Выбор ставок на любой порядок. Шансы получить выплаты по ним гораздо выше, чем при выборе пар или точной
          последовательности цифр.
        </li>
      </ul>
      <p>777 — лотерея с прозрачной системой проведения розыгрышей, простыми правилами и крупными призовыми выплатами.
        Поэтому она собирает большое количество участников и в течение многих лет входит в список самых востребованных
        казахстанских loto. Примите в ней участие и войдите в число победителей 777 лото.</p>

      <FAQItems :faqs="faqs" />
    </div>
  </div>
</template>

<script>
import FAQItems from "@/components/FAQItems.vue";

export default {
  components: {
    FAQItems
  },
  data() {
    return {
      faqs: [
        {
          question: "Лотерея 777 — как играть онлайн?",
          answer: "Зайдите на официальный сайт организатора, заполните билет и оплатите его с помощью удобного метода. Далее ожидайте ближайшего тиража. Его трансляция пройдет 16:00 на ТРК «31 Канал».",
        },
        {
          question: "Сколько стоит билет лото 777?",
          answer: "Цена фиксированная — 100 тенге.",
        },
        {
          question: "Разыгрываются ли в лотереи 777 накопительные призовые фонды?",
          answer: "Нет, поскольку эта разновидность loto всегда предусматривает фиксированные выплаты. Исключение составляют слоты 777 в онлайн-казино. В них могут разыгрываться прогрессивные джекпоты.",
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
