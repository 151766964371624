<template>
  <span>{{ str }}</span>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "LocalTime",
  props: {unix_time: {type: Number}},
  computed: {
    str() {
      console.log({t: this.unix_time, d: dayjs.unix(this.unix_time)});
      return dayjs.unix(this.unix_time).format('DD.MM.YY hh:mm');
    }
  }
}
</script>

<style scoped>

</style>