<template>
  <div>
      <div class="change-form" v-if="hasPhone && identityConfirmed">
        <p class="form__title">{{ $t('profile.confirmPhone.confirmed') }}</p>
        <div class="input__default" style="margin-bottom: 0">
          <label>{{ $t('profile.confirmPhone.yourConfirmedPhone') }}</label>
          <div class="input__container">
            <the-mask
                :value="confirmedPhone"
                :mask="mask"
                type="text"
                :masked="false"
                :readonly="true"
            />
            <unicon name="check" fill="#32cd32" class="addon" />
          </div>
        </div>
      </div>

      <div class="change-form" v-if="!hasPhone">
        <p class="form__title">{{ $t('profile.confirmPhone.header') }}</p>
        <TelInput
            v-model="phone"
            :title="$t('profile.setPhone.label')"
            :validate="$v.phone"
            :custom-error="phoneInUse ? $t('profile.setPhone.used') : ''"
        />
        <button class="btn__orange btn__confirm" @click="initSetPhone">{{ $t('profile.confirmPhone.button') }}</button>
      </div>
      <div class="change-form" v-if="!identityConfirmed && hasPhone">
        <p class="form__title">{{ $t('profile.confirmPhone.header') }}</p>
        <button class="btn__orange btn__confirm" @click="confirmPhone">{{ $t('profile.confirmPhone.button') }}</button>
      </div>
  </div>
</template>

<script>
import TelInput from "@/components/Ui/TelInput.vue";
import {minLength, required} from "vuelidate/lib/validators";
export default {
  name: "ConfirmPhoneForm",
  components: {
    TelInput,
  },
  computed: {
    mask() {
      return this.$store.state.config.phoneMask || '+# (###) ###-##-##';
    },
    confirmedPhone() {
      return this.$store.state.identityConfirmed && this.$store.state.hasPhone
          ? this.$store.state.phone
          : ''
    },
    identityConfirmed() {
      return this.$store.state.identityConfirmed;
    },
    hasPhone() {
      return this.$store.state.hasPhone;
    },
    phoneLen() {
      return (this.$store.state.config.phoneMask.match(/#/g) || []).length;
    }
  },
  data() {
    return {
      phone: "",
      phoneInUse: false
    };
  },
  validations() {
    return {
      phone: {
        required,
        minLength: minLength(this.phoneLen),
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0})
  },
  methods: {
    initSetPhone() {
      this.phoneInUse = false
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      this.$store.dispatch('phoneSet/init', {phone: this.phone}).then(r => {
        if (r.success) {
          this.$router.push({name: 'profile.set-phone.confirm'});
        }
        if(r.code === 'wait') {
          this.$router.push({name: 'wait', params: {waitSeconds: r.wait_seconds}})
        }
        if (r.code === 'phone_in_use') {
          this.phoneInUse = true;
        }
        if (r.code === 'player_already_has_phone') {
          this.$router.push({name: 'profile'});
        }

      })
    },
    confirmPhone() {
      this.$store.dispatch('phoneConfirm/init').then((r) => {
        if(r.success) {
          this.$router.push({name: 'profile.confirm-phone'});
        } else if(r.error_code === 'wait') {
          this.$router.push({name: 'wait', params: {waitSeconds: r.wait_seconds}})
        } else {
          this.$router.push({name: 'sms-error'})
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>

.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn__confirm {
  margin-top: 0px;
  padding: 15px;
}

.input__container {
  position: relative;
  .addon {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}
</style>