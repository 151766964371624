import ru from './messages/ru'
import ro from './messages/ro'
import en from './messages/en'
import kz from './messages/kz'
import uz from './messages/uz'
import ind from './messages/in'
import ua from './messages/ua'

const messages = {ru, ro, en, kz, uz, in: ind, ua}
export default messages;
