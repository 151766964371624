<template>
  <div class="deposit__buttons">
    <button v-for="sum in variants" :key="sum" @click="onClick(sum)">{{ format(sum) }} {{currencySign}}</button>
  </div>
</template>

<script>
export default {
  name: "Hint",
  props: ['onClick'],
  methods: {
    format(sum) {
      const formatter = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 0
      });
      return formatter.format(sum);
    }
  },
  computed: {
    currencySign() {
      return this.$store.state.currency;
    },
    variants() {
      const hint = this.$store.state.config.deposit.hint[this.$store.state.currencyCode] || {hint: []}
      return hint.hint;
    }
  }
}
</script>

<style scoped lang="scss">
.deposit__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  button {
    flex-grow: 1;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #ffffff;
    background: #4a4a6c;
    border-radius: 4px;
  }
}
</style>
