<template>
  <section class="pass-new">
    <div class="pass-new__container">
      <div class="pass-new__main">
        <p class="pass-new__title">{{ $t('recover.reset.header') }}</p>
        <PasswordInput
            v-model="userPassword"
            :title="$t('recover.reset.password.label')"
            :placeholder="$t('recover.reset.password.placeholder')"
            :validate="$v.userPassword"
            :condition="formButtonLock"
            @input="formButtonLock = false"
            :new="true"
            :customStyle="{marginBottom: '16px'}"

        />
        <PasswordInput
            v-model="userRepeatPassword"
            :title="$t('recover.reset.confirmPassword.label')"
            :placeholder="$t('recover.reset.confirmPassword.placeholder')"
            :condition="formButtonLock"
            :validate="$v.userRepeatPassword"
            @input="formButtonLock = false"
            :new="true"
            :customStyle="{marginBottom: '30px'}"

        />
        <p :class="['error__label', { 'in-error': $v.userRepeatPassword.$error || $v.userPassword.$error }]">
          {{ errorMessage }}
        </p>
      </div>
      <div class="pass-new__events">
        <button
            class="btn__orange comeIn"
            :disabled="
            $v.userRepeatPassword.$error ||
            $v.userPassword.$error ||
            formButtonLock
          "
            @click="reset()"
        >
          {{ $t('recover.reset.button') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import PasswordInput from "../../Ui/PasswordInput";

export default {
  components: {
    PasswordInput,
  },
  data() {
    return {
      userPassword: "",
      userRepeatPassword: "",
      formButtonLock: false,
    };
  },
  computed: {
    process_id() {
      return this.$store.state.register.process_id
    },
    errorMessage() {

      if (!this.$v.userRepeatPassword.required) {
        return this.$i18n.t('recover.reset.error.required');
      }

      if (!this.$v.userPassword.minLength) {
        return this.$i18n.t('recover.reset.error.min');
      }

      if (!this.$v.userRepeatPassword.sameAsPassword) {
        return this.$i18n.t('recover.reset.error.match');
      }

      return '';
    },
  },
  validations: {
    userPassword: {
      required,
      minLength: minLength(6),
    },
    userRepeatPassword: {
      required,
      sameAsPassword: sameAs('userPassword'),
    },
  },
  methods: {
    reset() {
      this.$v.$touch()
      if(this.$v.$error) {
        return
      }

      this.$store.dispatch('recover/complete', this.userPassword)
          .then(r => {
            if (r.success) {
              this.$router.push({name: 'recover.completed'});
            } else {
              this.formButtonLock = false;
            }
          });

    },
  },
};
</script>

<style lang="scss" scoped>
.error__label {
  margin-top: -20px;
  position: static;
}

.pass-new {
  background-color: #1b1c37;
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  &.active-main {
    right: -100%;
    left: 100%;
    opacity: 0;
  }

  &.active-next {
    right: -100%;
    left: 100%;
    opacity: 0;
  }
}


.pass-new__container {
  height: 100%;
  position: relative;

}


.pass-new__main {
  position: relative;
  top: 0;
  transition: 0.2s;
  transition-delay: 0.2s;
  margin-bottom: 16px;

  .pass-new__title {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
  }

  .link__orange.pass {
    max-width: max-content;
    display: flex;
    margin-left: auto;
    position: relative;
    top: -10px;
  }

  .sms__text {
    max-width: 222px;
    margin: 0 auto 40px auto;
    font-weight: normal;
    font-size: 16px;
    line-height: 130.4%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);

    span {
      color: #ffffff;
    }
  }
}

.pass-new__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0s;

  p {
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 130.4%;
    color: #ffffff;
  }

  &.in-error {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.2s;
  }
}

.pass-new__events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
