import Deposit from "../../views/Wallet/Deposit";
import DepositForm from "../../components/Deposit/DepositForm";
import DepositSuccess from "../../components/Deposit/DepositSuccess";
import DepositFailed from "../../components/Deposit/DepositFailed";
import PhoneDeposit from "../../components/Deposit/PhoneDeposit";
import PhoneDepositComplete from "@/components/Deposit/PhoneDepositComplete";
import CryptoDepositComplete from "@/components/Deposit/CryptoDepositComplete";
import DepositKaspi from "../../components/Deposit/DepositKaspi";
import DepositCrypto from "@/components/Deposit/CryptoDeposit";
import DepositPending from "@/components/Deposit/DepositPending.vue";
import ExternalPage from "@/views/ExternalPage.vue";
import PhoneDepositWithRedirect from "@/components/Deposit/PhoneDepositWithRedirect.vue";

export default [
    {
        path: '/deposit',
        component: Deposit,
        meta: {requiresAuth: true},
        children: [
            {
                name: 'deposit',
                path: 'form',
                component: DepositForm,
            },
            {
                name: 'deposit.iframe',
                path: 'pay',
                component: ExternalPage,
                props: true
            },
            {
                name: 'deposit.crypto',
                path: 'crypto',
                component: DepositCrypto,
            },
            {
                name: 'deposit.crypto.complete',
                path: 'crypto/complete',
                component: CryptoDepositComplete,
            },
            {
                name: 'deposit.success',
                path: 'success',
                component: DepositSuccess,
            },
            {
                name: 'deposit.error',
                path: 'error',
                component: DepositFailed,
            },
            {
                name: 'deposit.pending',
                path: 'pending',
                component: DepositPending,
            },
            {
                name: 'deposit.phone',
                path: 'phone',
                component: PhoneDeposit,
            },
            {
                name: 'deposit.phone-redirect',
                path: 'phone-redirect',
                component: PhoneDepositWithRedirect,
            },
            {
                name: 'deposit.phone.complete',
                path: 'phone/complete',
                component: PhoneDepositComplete,
            },
            {
                name: 'deposit.kaspi',
                path: 'kaspi',
                component: DepositKaspi,
            },
        ]
    },
]
