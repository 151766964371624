<template>
  <div
    :class="[
      'input__default',
      { 'in-error': validate.$error || condition},
      { 'in-success': !validate.$invalid && !condition},
    ]"
    :style="customStyle"
  >
    <label>{{title}}</label>
    <div class="input__container">
      <input
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        v-bind:autocomplete="this.new ? 'new-password' : null"
      />
      <div class="check-password" @click="checkPassword">
        <img v-if="type === 'password'" src="../../assets/icons/auth/open__eye.svg" />
        <img v-else src="../../assets/icons/auth/close__eye.svg" />
      </div>
    </div>
    <p :class="['error__label', { 'in-error': validate.$error || condition}]">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    new: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    condition: {
      type: Boolean,
      default: null,
    },
    validate: {
      type: Object,
      default: () => ({
        $error: false,
        $invalid: false,
      }),
    },
  },
  data() {
    return {
      type: "password",
    };
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    },
    onBlur(e) {
      this.$emit("blur", e);
    },

    checkPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input__default {
  .input__container {
    position: relative;
    .check-password {
      display: flex;
      position: absolute;
      top: 20px;
      right: 12px;
    }
  }
}
</style>
