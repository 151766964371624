<template>
  <AuthenticatedConfirmation
      :submit="submit"
      :on-success="handleResult"
      :cancel="cancel"
      back-route="/payout/form"
  />
</template>

<script>
import AuthenticatedConfirmation from "../../Ui/AuthenticatedConfirmation";

export default {
  name: "ConfirmDisableConfirmation",
  components: {AuthenticatedConfirmation},
  methods: {
    cancel() {
      this.$router.push({name: 'payout'})
    },
    submit(data) {
      return this.$store.dispatch('outConfirmationDisable', data);
    },
    handleResult(r) {
      if (r.ok) {
        this.$router.push({name: 'payout'})
      }
    }
  }
}
</script>

<style scoped>

</style>