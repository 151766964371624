<template>
  <div>
    <section class="slider">
      <div class="slider__container">
        <Swiper class="swiper" :options="swiperOption">
          <SwiperSlide >
            <NewYear/>
          </SwiperSlide>
          <SwiperSlide v-if="has('koleso')" @click.native="navigate('promo.wheel')">
            <Fortuna3x/>
          </SwiperSlide>
          <SwiperSlide>
            <Telegram/>
          </SwiperSlide>
          <SwiperSlide>
            <Application/>
          </SwiperSlide>
          <SwiperSlide v-if="has('ggg') && hasGgg" @click.native="navigate('promo.ggg')">
            <GggBanner :status="gggStatus"/>
          </SwiperSlide>
          <SwiperSlide v-if="has('firstDeposit') && !hasDeposit" @click.native="navigate('promo.first-deposit')">
            <Bonus/>
          </SwiperSlide>
          <!--          <SwiperSlide v-if="has('koleso')" @click.native="navigate('promo.wheel')">-->
          <!--            <WheelOfFortune :button="true"/>-->
          <!--          </SwiperSlide>-->
          <SwiperSlide v-if="has('cb')" @click.native="navigate('promo.cash-back')">
            <Cashback/>
          </SwiperSlide>
        </Swiper>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </section>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";

import Bonus from "../Promo/FirstDeposit";
import Cashback from "../Promo/Cashback";
import GggBanner from "@/components/Home/GggBanner";
// import WheelOfFortune from "../Promo/Wheel";
import Application from "@/components/Promo/Application.vue";
import Telegram from "@/components/Promo/Telegram.vue";
import Fortuna3x from "@/components/Promo/Fortuna3x.vue";
import NewYear from "@/components/Home/NewYear.vue";


export default {
  name: "Banners",
  components: {
    Fortuna3x,
    Telegram,
    NewYear,
    GggBanner,
    Bonus,
    Cashback,
    // WheelOfFortune,
    Application,
    Swiper,
    SwiperSlide
  },
  methods: {
    navigate(name) {
      this.$router.push({name})
    },
    has(key) {
      return this.$store.state.config.banners.includes(key)
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

      },
    };
  },
  computed: {
    hasDeposit() {
      return this.$store.state.hasDeposit
    },
    hasGgg() {
      return this.$store.state.bonus.filter(i => i.id === 'ggg').length > 0;
    },
    gggStatus() {
      const bonuses = this.$store.state.bonus.filter(i => i.id === 'ggg');
      if (bonuses.length < 1) {
        return '';
      }
      return bonuses[0].status;
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
  height: 100%;

  &__container {
    position: relative;
  }

  .swiper-container {
  }

  .swiper-pagination {
    width: 100%;
    left: 0;
    bottom: -30px;
    z-index: unset;
  }

  .swiper-slide {
    width: 280px;
    height: 158px;
    left: 0px;
    right: 0;
    top: 0px;
    background: #2c2c4d;
    border-radius: 6px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .swiper__text {
      padding: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .swiper__image {
      //   width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      img {
        height: 100%;
      }
    }
  }
}

.bonus {
  margin-top: 60px;
}

</style>
