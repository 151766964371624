<template>
  <div class="failed-form">
    <div class="failed-form__container">
      <div class="failed__message">
        <img src="../../assets/icons/alerts/warning.svg"/>
        <p class="failed__title">{{ $t('payIn.error.title') }}</p>
        <div class="error_desc">
          {{ $t('payIn.error.reasons') }}
          <ul style="padding-left: 15px">
            <li>{{ $t('payIn.error.funds') }}</li>
            <li>{{ $t('payIn.error.online_blocked') }}</li>
            <li>{{ $t('payIn.error.online_limit') }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="accept__button">
      <button class="btn__orange" @click="$router.push('/deposit/form')">
        {{ $t('payIn.error.link') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.failed-form {
  height: calc(100vh - 80px);
  min-height: 450px;
  color: #ffffff;
}

/* position: relative; */
.failed-form__container {
  padding: 20px;
}

.failed__message {
  position: relative;
  top: 100px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 8px;
  }
}

.failed__title {
  width: 191px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.failed__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.accept__button {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 32px;
}
</style>
