<template>
  <div class="finance">
    <div class="finance__container">
      <div
        v-for="(finance, index) in rows"
        class="finance__operation"
        :key="index"
      >
        <div class="finance__logo">
          <img
            v-if="finance.type === 'in'"
            src="../../assets/icons/history/in.svg"
          />
          <img
            v-if="finance.type === 'out_return'"
            src="../../assets/icons/history/in.svg"
          />
          <img
            v-if="finance.type === 'out'"
            src="../../assets/icons/history/out.svg"
          />
        </div>
        <div class="finance__title">
          <p v-if="finance.type === 'in'" class="operation">{{ $t('history.payIn') }}</p>
          <p v-if="finance.type === 'out_return'" class="operation">{{ $t('history.payOutReturn') }}</p>
          <p v-if="finance.type === 'out'" class="operation">{{ $t('history.payOut') }}</p>
          <p class="date">{{ formatDate(finance.stamp) }}</p>
        </div>
        <div class="finance__result">
          <div
            :class="['operation__value', { witdraw: finance.type === 'out' }]"
          >
            <div v-if="finance.type === 'in'" class="plus-minus">+</div>
            <div v-if="finance.type === 'out_return'" class="plus-minus">+</div>
            <div v-if="finance.type === 'out'" class="plus-minus">-</div>
            {{ finance.sum }} {{finance.isBonus ? 'B' : ''}}
            {{finance.baseCurrency ? currencySymbol(finance.baseCurrency) + ' →' : ''}}
            {{currencySymbol(finance.currency)}}
          </div>
          <div v-if="finance.status === 'complete'" class="operation__status">
            <img src="../../assets/icons/history/done-checkbox.svg" />
            {{ $t('history.status.complete') }}
          </div>
          <div v-else-if="finance.status === 'wait'" class="operation__status">
            <img src="../../assets/icons/history/waitin.svg" />
            {{ $t('history.status.wait') }}
          </div>
          <div
            v-else-if="finance.status === 'reject'"
            class="operation__status"
          >
            <img src="../../assets/icons/history/closed.svg" />
            {{ $t('history.status.cancel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {currencySymbol} from "../../store";
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDate(stamp) {
      const d = new Date(stamp * 1000);
      return d.toLocaleString(this.$i18n.locale, {
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    currencySymbol(c) {
      return currencySymbol(c);
    },
  },
  computed: {

  },
  data() {
    return {
      historyFinance: [
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.finance {
  .finance__container {
    display: flex;
    flex-direction: column;
    .finance__operation {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;
      .finance__logo {
        margin-right: 8px;
      }
      .finance__title {
        .operation {
          margin-bottom: 4px;
          font-weight: 500;
          font-size: 16px;
          line-height: 140.7%;
          color: #ffffff;
        }
        .date {
          font-weight: normal;
          font-size: 16px;
          line-height: 140.7%;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .finance__result {
        margin-left: auto;
        text-align: right;
        .operation__value {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 16px;
          line-height: 140.7%;
          color: #86e15b;
          .plus-minus {
            margin-right: 4px;
          }
          &.witdraw {
            color: #e15b5b;
          }
        }
        .operation__status {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 140.7%;
          color: #8d8d9b;
          img {
            margin: 0 4px 0 0;
          }
        }
      }
    }
  }
}
</style>
