<template>
  <Section>
    <div class="failed__message">
      <img src="../../../assets/icons/alerts/warning.svg"/>
      <p class="failed__title">{{ $t('payOut.identificationNotice.header') }}</p>
      <p class="failed__text">{{ $t('payOut.identificationNotice.notice') }}</p>

      <button style="margin-top: 16px;" class="btn__orange" @click="confirmPhone">
        {{ $t('payOut.identificationNotice.button') }}
      </button>
    </div>
  </Section>
</template>

<script>
import Section from "@/components/Payout/Section.vue";

export default {
  name: "IdentificationNotice",
  components: {Section},
  methods: {
    confirmPhone() {
      this.$store.dispatch('phoneConfirm/init').then(() => {
        this.$router.push({name: 'profile.confirm-phone'});
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.failed__message {
  position: relative;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 8px;
  }

  .failed__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  }

  .failed__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
  }

}
</style>