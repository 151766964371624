<template>
  <div>
    <form ref="form" action="https://oauth.mail.ru/login" style="display: none">
      <input type="hidden" name="client_id" :value="clientId">
      <input type="hidden" name="redirect_uri" :value="redirectUrl">
      <input type="hidden" name="response_type" value="code">
      <input type="hidden" name="scope" value="userinfo">
      <input type="hidden" name="prompt_force" value="1">
      <input type="hidden" name="state" :value="nonce">
    </form>
    <div
        class="mailru-container"
        @click="submit()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 5 20 20" class="mailru-logo">
        <path fill="#FFA930" fill-rule="evenodd"
              d="M19.108 15.42c-.102-1.96-1.58-3.138-3.363-3.138h-.068c-2.058 0-3.2 1.593-3.2 3.404 0 2.027 1.382 3.307 3.193 3.307 2.019 0 3.347-1.456 3.443-3.178l-.005-.396zm-3.42-4.89c1.375 0 2.669.598 3.618 1.535v.003c0-.45.308-.789.735-.789h.108c.668 0 .805.622.805.819l.003 6.996c-.047.458.48.694.773.4 1.141-1.154 2.506-5.934-.71-8.704-2.996-2.582-7.017-2.156-9.156-.706-2.274 1.544-3.729 4.96-2.316 8.168 1.541 3.5 5.949 4.544 8.569 3.503 1.327-.527 1.94 1.238.562 1.815-2.083.873-7.877.785-10.583-3.83-1.829-3.117-1.732-8.601 3.118-11.442 3.711-2.173 8.603-1.571 11.552 1.46 3.084 3.171 2.904 9.107-.103 11.417-1.363 1.048-3.387.027-3.374-1.501l-.014-.5a5.1 5.1 0 0 1-3.587 1.468c-2.719 0-5.11-2.354-5.11-5.03 0-2.702 2.391-5.083 5.11-5.083z"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "MailruButton",
  props: ['clientId'],
  computed: {
    redirectUrl() {
      return new URL(this.$router.resolve({name: "login.mailru"}).href, location.origin).toString()
    },
    nonce() {
      return Date.now();
    }
  },
  methods: {
    submit() {
      this.$store.commit('loading');
      this.$refs.form.submit()
    }
  }
}
</script>

<style scoped>
.mailru-container {
  width: 56px;
  height: 56px;
  background-color: #005ff9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.mailru-logo {
  align-self: center;
  height: 40px;
  width: 40px
}

</style>