<script>
import {LocalStorage} from "ttl-localstorage";

const storageKey = 'appBanner2.closed';

export default {
  name: "AppBanner",
  data() {
    return {closed: true}
  },
  methods: {
    close() {
      this.closed = true;
      const timeout = 60 * 60 * 24;
      LocalStorage.put(storageKey, true, timeout);
    },
    open() {
      this.closed = LocalStorage.get(storageKey, false);
    }
  },

  mounted() {
    const openInSeconds = 90;
    setTimeout(() => this.open(), openInSeconds * 1000);
  },

  computed: {
    isApp() {
      return this.$store.state.isAndroidApp;
    },
    isPwa() {
      return navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
    },
    whiteListedPage() {
      return ['index'].includes(this.$route.name)
    },
    enabled() {
      return true;// [4, 4193].includes(this.$store.state.player_id);
    },
    url() {
      return this.$store.state.config.appUrl;
    },
    canShow() {
      return !this.isApp
          && !this.isPwa
          && this.whiteListedPage
          && this.enabled
          && this.url
    }
  }
}
</script>

<template>
  <div :class="{'app-banner': true, closed}" v-if="canShow">
    <div class="container">
      <div class="app_logo">
        <img src="./img/loto_logo.png" width="51"/>
        <div class="app_title">
          <div class="text">{{ $t('appBanner2.text') }}</div>
          <div class="stars">
            <img src="./img/stars.png" width="83">
          </div>
        </div>
      </div>
      <div class="google_logo">
        <img src="./img/google_play_logo.png" alt=""/>
        <img src="./img/apple-store.png" alt=""/>
      </div>
      <div class="close" @click="close">
        <img src="./img/close.png" width="14"/>
      </div>
      <div class="button">
        <a :href="url" class="btn__orange action_button">{{ $t('appBanner2.link') }}</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-banner.closed {
  transform: translateY(100%);
}
.app-banner {
  transition: all ease 0.7s;
  transform: translateY(0);
  position: fixed;
  bottom: 0;
  height: 136px;
  width: 100%;
  background-color: #fff;
}

.container {
  display: flex;
  margin: 16px 16px 11px 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.app_logo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.app_title {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app_title .text {
  line-height: 30px;
  font-weight: bold;
  font-size: 18px;
}

.google_logo {
  position: absolute;
  bottom: 10px;
  left: 0;
  img {
    height: 20px;
    margin-right: 10px;
  }
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.action_button {
  display: block;
  width: 120px;
  text-align: center;
}
</style>