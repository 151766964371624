<template>
    <SuccessMessage
        :title="$t('recover.completed.title')"
        :message="$t('recover.completed.text')"
        :button-text="$t('recover.completed.link')"
        :button-callback="goHome"/>
</template>

<script>
import SuccessMessage from "../../Ui/Success";

export default {
  components: {SuccessMessage},
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
};
</script>