<template>
  <div class="deposit-page">
    <div class="deposit-page__container">
      <Tabs :active="'deposit'"/>
      <transition
          mode="out-in"
          enter-class="enter-start-position"
          enter-to-class="enter-end-position"
          enter-active-class="while-enter"
          leave-class="enter-end-position"
          leave-to-class="leave-end-position"
          leave-active-class="while-enter"
      >
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Tabs from "@/views/Wallet/Tabs";

export default {
  components: {Tabs},
  methods: {},
};
</script>

<style lang="scss" scoped>
.deposit-page {
  .deposit-page__container {
    padding: 0 20px;
  }
}
</style>
