import { render, staticRenderFns } from "./IdentificationNotice.vue?vue&type=template&id=cacb4538&scoped=true&"
import script from "./IdentificationNotice.vue?vue&type=script&lang=js&"
export * from "./IdentificationNotice.vue?vue&type=script&lang=js&"
import style0 from "./IdentificationNotice.vue?vue&type=style&index=0&id=cacb4538&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cacb4538",
  null
  
)

export default component.exports