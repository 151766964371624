<template>
  <Section>
    <div>
      <div v-if="outConfirmationDisabled" class="row">
        <div>{{ $t('payOut.confirmation.disabled') }}</div>
        <div>
          <button class="btn bnt__blue" @click="enableConfirmation">{{ $t('payOut.confirmation.enable') }}</button>
        </div>
      </div>
      <div v-if="!outConfirmationDisabled" class="row">
        <div>{{ $t('payOut.confirmation.enabled') }}</div>
        <div>
          <button class="btn bnt__blue" @click="disableConfirmation">{{ $t('payOut.confirmation.disable') }}</button>
        </div>
      </div>

    </div>
  </Section>
</template>

<script>
import Section from "@/components/Payout/Section.vue";

export default {
  components: {Section},
  data() {
    return {
      showForm: false
    }
  },
  name: "ConfirmationBlock",
  computed: {
    outConfirmationDisabled() {
      return this.$store.state.outConfirmationDisabled;
    },
  },
  methods: {
    enableConfirmation() {
      if (confirm(this.$t('payOut.confirmation.sureEnable'))) {
        this.$store.dispatch('outConfirmationEnable')
      }
    },
    disableConfirmation() {
      this.$router.push({name: 'payout.disable-confirmation'})
    },
    submit() {
      alert(123)
    },
  }
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  color: #fff;
  align-items: center;
  gap: 8px;
}

.btn {
  padding: 15px 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  border-radius: 6px;
  cursor: pointer;
}

.bnt__blue {
  background-color: #4a4a6c;
  /*border: 1px solid #1b1c37;*/
  color: #fff;
}

</style>
