<template>
  <div class="container">
    <div class="success-form">
      <div class="success-form__container">
        <div class="success__message">
          <img src="../../assets/icons/alerts/success.svg"/>
          <p class="success__title">{{ title }}</p>
          <p class="success__text">{{ message }}</p>
          <div class="progress" v-if="autoRedirect">
            <div class="bar"></div>
          </div>
        </div>
      </div>
      <div class="accept__button">
        <button class="btn__orange" @click="buttonCallback()">{{ buttonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  props: {
    autoRedirect: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonCallback: {
      type: Function,
      required: true
    },
  },
  mounted() {
    if(this.autoRedirect) {
      setTimeout(() => this.buttonCallback(), 4000);
    }
  }
};
</script>

<style lang="scss" scoped>

.progress {
  width: 100%;
  .bar {
    height: 4px;
    width: 100%;
    background-color: #f45a0e;
    border-radius: 1px;
    animation: .7s ease-in-out 0s infinite alternate both running pulse,
      4s linear 0s 1 normal forwards running shrink;
  }
}

@keyframes shrink {
  from {
    width: 100%;
  }

  to {
    width: 1%;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.6;
  }
}


.container {
  background-color: #1b1c37;
  padding: 0 20px;
}

.success-form {
  height: calc(100vh - 140px);
  min-height: 450px;
  position: relative;

  .success-form__container {
    padding: 20px;

    .success__message {
      position: relative;
      top: 40px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 22px;
      }

      .success__title {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: #ffffff;
        text-align: center;
      }

      .success__text {
        padding-bottom: 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .accept__button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32px;
  }
}
</style>