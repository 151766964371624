<template>
  <div class="slide">
    <div :class="{
      button: true,
      canActivate: status === 'absent',
      activated: status === 'activated',
      complete: status === 'complete'
    }"></div>
  </div>
</template>

<script>
export default {
  name: "GggBanner",
  props: {
    status: {
      type: String
    }
  },
}
</script>

<style scoped>
.slide {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/GGG/banner.jpg);
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
}

.button {
  width: 135px;
  height: 50px;
  border-radius: 6px;
  background-size: contain;
  background-repeat: no-repeat;
}

.canActivate {
  background-image: url(../../assets/images/banners/GGG/button_can_activate.png);
}

.activated {
  background-image: url(../../assets/images/banners/GGG/activated.png);
}

.complete {
  background-image: url(../../assets/images/banners/GGG/complete.png);
}


</style>
