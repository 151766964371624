<template>
  <section class="sms">
    <div class="sms__container">
      <div class="sms__main">
        <p class="sms__title">{{ $t('smsForm.title') }}</p>
        <p class="sms__text">
          {{ $t('smsForm.sent') }} <br/>
          <span>{{ formattedTel(tel) }}</span>
        </p>
        <!--        <a href="#" class="link__orange code__link">{{ $t('smsForm.notDeliveredLink') }}</a>-->
      </div>
      <div :class="{sms__inputs: true, 'in-error': error}">
        <div class="input__default">
          <input
              type="text"
              v-model.trim="code[0]"
              placeholder="0"
              readonly
          />
        </div>
        <div class="input__default">
          <input
              type="text"
              v-model.trim="code[1]"
              placeholder="0"
              readonly
          />
        </div>
        <div class="input__default">
          <input
              type="text"
              v-model.trim="code[2]"
              placeholder="0"
              readonly
          />
        </div>
        <div class="input__default">
          <input
              type="text"
              v-model.trim="code[3]"
              placeholder="0"
              readonly
          />
        </div>
      </div>
      <div class="error__container">
        <p :class="['error__label', { 'in-error': error }]">
          {{ $t('smsForm.invalidCode') }}
        </p>
      </div>
      <div class="sms__numbers" v-if="!expired">
        <button class="number__btn" @click="addCodeNumber(1)"><p>1</p></button>
        <button class="number__btn" @click="addCodeNumber(2)"><p>2</p></button>
        <button class="number__btn" @click="addCodeNumber(3)"><p>3</p></button>
        <button class="number__btn" @click="addCodeNumber(4)"><p>4</p></button>
        <button class="number__btn" @click="addCodeNumber(5)"><p>5</p></button>
        <button class="number__btn" @click="addCodeNumber(6)"><p>6</p></button>
        <button class="number__btn" @click="addCodeNumber(7)"><p>7</p></button>
        <button class="number__btn" @click="addCodeNumber(8)"><p>8</p></button>
        <button class="number__btn" @click="addCodeNumber(9)"><p>9</p></button>
        <button class="number__btn image" @click="deleteCodeNumber()">
          <img src="../../assets/icons/auth/delete.svg"/>
        </button>
        <button class="number__btn" @click="addCodeNumber(0)"><p>0</p></button>
      </div>
      <CloseButton :click="cancel"/>
    </div>
    <div v-if="expired">
      <div class="sms__text">{{ $t('smsForm.codeExpired') }}</div>
      <button class="btn btn__orange" @click="reInit">{{ $t('smsForm.resend') }}</button>
    </div>

  </section>
</template>

<script>
import CloseButton from "./CloseButton";
import {formatAsPhoneNumber} from "../../store/formatAsPhone";

export default {
  name: 'ConfirmForm',
  components: {CloseButton},
  props: {
    onSubmit: {
      type: Function
    },
    reInit: {
      type: Function
    },
    tel: {
      type: String
    },
    error: {type: Boolean},
    expired: {type: Boolean},
    cancel: {type: Function}
  },
  data() {
    return {
      code: "",
    };
  },
  methods: {
    formattedTel(tel) {
      return formatAsPhoneNumber(tel);
    },
    addCodeNumber(value) {
      if (this.code.length < 4) {
        this.code = this.code + value;
        if (this.code.length === 4) {
          this.onSubmit(this.code)
        }
      }
    },
    deleteCodeNumber() {
      this.code = this.code.slice(0, -1);
    },
  },
};
</script>


<style lang="scss" scoped>

.expired_container {

}

.sms {
  background-color: #1b1c37;
  padding-top: 65px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;
  z-index: 11;
}

.sms__container {
  position: relative;
}

.sms__main {
  position: relative;
  transition: 0.2s;
  transition-delay: 0.2s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sms__title {
  max-width: 193px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.sms__text {
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.65;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);

  span {
    color: #ffffff;
  }
}

.code__link {
  margin-bottom: 20px;
}

.sms__inputs {
  max-width: 240px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto 8px auto;

  .input__default {
    margin: 0 16px 0 0;

    &:nth-child(4) {
      margin-right: 0;
    }

    input {
      text-align: center;
    }
  }
}

.error__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px auto;
  position: relative;

  .error__label {
    position: relative;
    bottom: 0;
    font-size: 16px;
  }
}

.sms__numbers {
  width: 240px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  .number__btn {
    padding: 10px 18px;
    margin: 0 50px 10px 0;
    background: none;
    border-radius: 6px;
    transition: 0.2s;

    &:nth-child(3n) {
      margin: 0 0 10px 0;
    }

    p {
      width: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
    }

    &.image {
      padding: 10px 11px;
    }

    &:hover {
      background: #4a4a6c93;
    }

    &:active {
      background: #454561da;
    }
  }
}
</style>