<template>
  <div class="deposit-form">
    <div v-if="crypto.length > 0">
      <div class="deposit-form__container crypto_container">

        <p class="title">{{ $t('payIn.crypto.label') }}</p>
        <Crypto class="deposit__input" v-for="address in crypto" :key="address.address" :address="address"/>
        <Help style="position: absolute; top: 10px; right: 10px">
          Для пополнения совершите перевод на указанный кошелек.<br/><br/>
          Учитывайте минимальную сумму.<br/>
        </Help>
      </div>
      <br>
      <br>
      <div class="accept__button">
        <button class="btn__orange" @click="complete">{{ $t('payIn.crypto.complete') }}</button>
      </div>
    </div>
    <div v-else>
      <br><br>
      <p class="title">{{ $t('payIn.crypto.notAvailable.label') }}</p>
      <br><br>
      <div class="accept__button">
        <button class="btn__orange" @click="back">{{ $t('payIn.crypto.notAvailable.back') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Crypto from "./Crypto";
import Help from "../../views/Wallet/Help";

export default {
  name: "DepositCrypto",
  components: {Crypto, Help},
  computed: {
    crypto() {
      return this.$store.state.deposit.crypto || [];
    },
  },
  mounted() {
    this.$store.dispatch('requestDepositCryptoWallets')
  },
  methods: {
    complete() {
      this.$router.push({name: 'deposit.crypto.complete'})
    },
    back() {
      this.$router.push({name: 'deposit'})
    }
  }
};
</script>

<style lang="scss" scoped>

.deposit-form {
  color: #ffffff;
}

.deposit-form__container {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
  position: relative;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

</style>
