<template>
  <div class="header">
    <div class="header__container">
      <BurgerButton :click="open"/>
      <Logo/>
      <div class="header-left">
        <LoginButton v-if="guest"/>
        <SignInButton v-if="guest"/>
        <Balance v-if="loaded"/>
        <DepositButton v-if="loaded"/>
      </div>
    </div>
    <div :class="['header__mobile', { active: isActiveMenu }]">
      <div class="header__mobile-container">
        <BurgerContent :close="close" :guest="guest"/>
      </div>
    </div>
  </div>
</template>

<script>
import BurgerButton from "./BurgerButton";
import Balance from "./Balance";
import Logo from "./Logo";
import SignInButton from "./SingInButton";
import LoginButton from "./LoginButton";
import DepositButton from "./DepositButton";
import BurgerContent from "@/components/Header/BurgerContent.vue";

export default {
  components: {
    BurgerContent,
    SignInButton, LoginButton, DepositButton,
    Logo, Balance, BurgerButton
  },
  data() {
    return {
      isActiveMenu: false,
    };
  },
  computed: {
    guest() {
      return !this.$store.state.auth
    },
    loaded() {
      return this.$store.state.accounts.length > 0
    }
  },
  methods: {
    close() {
      this.isActiveMenu = false
    },
    open() {
      this.isActiveMenu = true
    },
  },
};
</script>

<style lang="scss">
.header-left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 12px;
}
.header {
  background: #1b1c37;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .header__container {
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 12px;
  }

  .header__mobile {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    right: 0;
    background: #1b1c37;
    overflow: scroll;
    transition: 0.5s;

    .header__mobile-container {
      padding: 10px;
      display: flex;
      flex-direction: column;

      .close {
        display: flex;
        justify-content: flex-end;
      }

      .site__links {
        display: flex;
        flex-direction: column;
        padding: 0 22px;

        .menu__link {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          padding-top: 12px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;

          img {
            margin-right: 12px;
          }
        }

        .menu__link:first-of-type {
          padding-top: 0px;
        }

        .menu__link:last-of-type {
          margin-bottom: 12px;
        }
      }

      .border {
        margin: 8px 22px 32px 22px;
        border-bottom: 1px solid #595a69;
      }

      .apps {
        margin-left: 10px;
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;

        .help {
          max-width: 256px;
          justify-content: center;
          display: flex;
          margin: 0 auto;
        }

        img {
          max-width: 126px;
          margin: 28px auto 34px auto;
        }
      }
    }

    &.active {
      left: 0;
    }
  }
}

.user-auth {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #1b1c37;
  z-index: 1001;
  transition: 0.5s;
  overflow-y: scroll;
  overflow-x: hidden;

  .close {
    padding: 20px 20px 11px 20px;
  }

  &.active {
    right: 0;
  }
}
</style>
