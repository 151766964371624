<template>
  <div>
    <CloseButton :click="close"/>
    <GuestMenu v-if="guest" :click="close"/>
    <PlayerMenu v-else :click="close"/>
    <BottomLinks :click="close"/>
    <Footer :click="close"/>
  </div>
</template>

<script>
import GuestMenu from "./Menu/GuestMenu";
import CloseButton from "./Menu/CloseButton";
import BottomLinks from "./Menu/BottomLinks";
import Footer from "./Menu/Footer";
import PlayerMenu from "./Menu/PlayerMenu";

export default {
  components: {
    PlayerMenu, Footer, BottomLinks, CloseButton, GuestMenu
  },
  props: {
    close: {
      type: Function,
      required: true
    },
    guest: {
      type: Boolean,
      required: true
    }
  },
};
</script>