import Vue from 'vue';
import VueRouter from 'vue-router';

import Index from '../views/Index';
import License from '../views/License';
import FAQ from '../views/FAQ';

import auth from "./auth";
import promo from './promo';
import profile from "./profile";
import wallet from "./wallet/wallet";
import NotFound from "../views/NotFound";
import Game from "../views/Game";
import WaitSms from "@/components/Profile/WaitSms.vue";
import Loterei from "@/views/Loterei.vue";
import Tirazhnyye from "@/views/Loterei/Tirazhnyye.vue";
import Momentalnyye from "@/views/Loterei/Momentalnyye.vue";
import Keno from "@/views/Loterei/Keno.vue";
import Bingo37 from "@/views/Loterei/Bingo37.vue";
import c6iz49 from "@/views/Loterei/c6iz49.vue";
import c4iz20 from "@/views/Loterei/c4iz20.vue";
import c777 from "@/views/Loterei/c777.vue";

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '',
            name: 'index',
            component: Index,
        },
        {
            path: '/play/:id',
            name: 'play',
            component: Game,
            meta: {requiresAuth: true},
            props: ({params}) => ({...params, demo: false})
        },
        {
            path: '/wait',
            name: 'wait',
            component: WaitSms,
            props: ({params}) => ({...params, demo: false})
        },
        {
            path: '/demo/:id',
            name: 'demo',
            component: Game,
            meta: {requiresAuth: false},
            props: ({params}) => ({...params, demo: true})
        },
        ...auth,
        ...promo,
        ...profile,
        ...wallet,
        {
            path: '/license',
            name: 'license',
            component: License,
        },
        {
            path: '/loterei',
            name: 'loterei',
            component: Loterei,

        },
        {
            name: 'loterei.tirazhnyye',
            path: '/loterei/tirazhnyye',
            component: Tirazhnyye,
        },
        {
            name: 'loterei.momentalnyye',
            path: '/loterei/momentalnyye',
            component: Momentalnyye,
        },
        {
            name: 'loterei.keno',
            path: '/loterei/keno',
            component: Keno,
        },
        {
            name: 'loterei.bingo-37',
            path: '/loterei/bingo-37',
            component: Bingo37,
        },
        {
            name: 'loterei.6-is-49',
            path: '/loterei/6-iz-49',
            component: c6iz49,
        },
        {
            name: 'loterei.4-is-20',
            path: '/loterei/4-iz-20',
            component: c4iz20,
        },
        {
            name: 'loterei.777',
            path: '/loterei/777',
            component: c777,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQ,
        },
        {path: '*', component: NotFound}
    ]
})
