<template>
  <div class="promo-game">
    <div class="promo-game-game-block">
      <ExternalPage v-if="game_url" :url="game_url" :allow-full-screen="false"/>
    </div>
    <div class="promo-game-footer-block">
      <FooterButtons/>
    </div>
  </div>
</template>

<script>

import ExternalPage from "../ExternalPage.vue";
import FooterButtons from "@/components/FooterButtons.vue";

export default {
  name: "PromoGame",
  components: {ExternalPage, FooterButtons},
  props: {
    id: {type: String},
    demo: {type: Boolean},
  },
  data() {
    return {
      game_url: null
    }
  },
  mounted() {
    const returnUrl = new URL(this.$router.resolve({name: "index"}).href, location.origin).toString();
    const refillUrl = new URL(this.$router.resolve({name: "deposit"}).href, location.origin).toString();
    const language = this.$i18n.locale;
    const payload = {id: this.$route.params.id, returnUrl, refillUrl, language};
    const action = this.demo ? 'startDemo' : 'startGame';
    this.$store
        .dispatch(action, payload)
        .then(r => {
          if (!r.success) {
            return
          }

          this.game_url = r.url;
        })
  }
}
</script>

<style lang="scss" scoped>
.promo-game {
  position: relative;
  height: 100vh;


  &-game-block {
    height: calc(100% - 50px);
    width: 100%;
    background: #00dc82;
  }

  &-footer-block {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    z-index: 100000;
  }
}
</style>
