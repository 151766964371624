<template>
  <div>
    <Section v-show="wagerLimitation">
      <WagerNotice :sum="wagerSum" :currency="currency"/>
      <Help style="position: absolute; top: 10px; right: 10px">
        Вейджер - это сумма ставок, которую
        нужно совершить для разблокировки
        вывода средств. Засчитываются только
        ставки сделанные с основного счета.<br/><br/>

        К примеру, если у Вас вейджер 100 тенге,
        то при ставке в 20 тенге, вейджер
        уменьшиться до 80. При этом будет ли
        спин выигрышный или проигрышный -
        значения не имеет.
      </Help>
    </Section>
    <Section v-show="holdActive" style="color: white">
      С момента последней ставки в LIVE играх с диллерами должно пройти 6 часов для вывода средств.<br><br>
      <i>Это время требуется поставщику игр для подтверждения честной игры и соответствует требованию Antillephone N.V.
        о Fair Play.</i>
    </Section>
    <IdentificationNotice v-show="false"/>
    <InsufficientFunds v-show="insufficientFunds" :min="Math.min(minSum, minSumPhone)" :available="availableBalance" :currency="currency"/>
  </div>
</template>

<script>

import Section from "@/components/Payout/Section.vue";
import Help from "@/views/Wallet/Help.vue";
import IdentificationNotice from "@/components/Payout/Limitations/IdentificationNotice.vue";
import InsufficientFunds from "@/components/Payout/Limitations/InsufficientFunds.vue";
import WagerNotice from "@/components/Payout/Limitations/WagerNotice.vue";

export default {
  name: "Limitations",
  components: {WagerNotice, InsufficientFunds, IdentificationNotice, Help, Section},
  computed: {
    wagerSum() {
      return this.$store.state.wagerDecimal;
    },
    wagerLimitation() {
      return this.$store.state.wagerLimitation;
    },
    holdActive() {
      return this.$store.state.holdPayoutUntil > (Date.now() / 1000)
    },
    insufficientFunds() {
      return (this.availableBalance < this.minSum)
      && (this.availableBalance < this.minSumPhone)
          ;
    },
    minSum() {
      const level = this.$store.state.config.payout.min.filter(l => l.currency === this.$store.state.currencyCode)[0] || {sum: 1000}
      return level.sum;
    },
    minSumPhone() {
      const level = this.$store.state.config.payout.phone_min.filter(l => l.currency === this.$store.state.currencyCode)[0] || {sum: 1000}
      return level.sum;
    },
    availableBalance() {
      const real = this.$store.state.accounts.filter(a => a.title === 'real')[0];
      if (real) {
        return parseFloat(real.sum.replace(/\s/g, ''));
      }
      return 0;
    },
    currency() {
      return this.$store.state.currency;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>