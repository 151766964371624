import Payout from "../../views/Wallet/Payout";
import Form from "../../components/Payout/Form";
import Success from "../../components/Payout/Success";
import Error from "../../components/Payout/Error";
import ConfirmDisableConfirmation from "../../components/Payout/Confirmation/ConfirmDisableConfirmation";
import ConfirmOut from "../../components/Payout/Confirmation/ConfirmOut";

export default [
    {
        path: '/payout',
        component: Payout,
        meta: {requiresAuth: true},
        children: [
            {
                name: 'payout.disable-confirmation',
                path: 'disable-confirmation',
                component: ConfirmDisableConfirmation
            },
            {
                name: 'payout.confirm',
                path: 'confirm',
                component: ConfirmOut,
            },
            {
                name: 'payout',
                path: 'form',
                component: Form,
            },
            {
                name: 'payout.success',
                path: 'success',
                component: Success,
            },
            {
                name: 'payout.error',
                path: 'error',
                component: Error,
            },
        ]
    },
]