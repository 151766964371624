import Form from "../components/Profile/Form";
import Success from "../components/Profile/Success";
import ConfirmPhone from "../components/Profile/ConfirmPhone";
import ConfirmSuccess from "../components/Profile/ConfirmSuccess";
import SetPhoneConfirm from "../components/Profile/SetPhoneConfirm";

export default [
    {
        path: '/profile/',
        name: 'profile',
        component: Form,
        meta: {requiresAuth: true},
    },
    {
        path: '/profile/password-changed',
        name: 'profile.password-changed',
        component: Success,
        meta: {requiresAuth: true},
    },
    {
        path: '/profile/confirm-phone',
        name: 'profile.confirm-phone',
        component: ConfirmPhone,
    },
    {
        path: '/profile/confirm-phone/success',
        name: 'profile.confirm-phone.success',
        component: ConfirmSuccess,
    },
    {
        path: '/profile/set-phone/confirm',
        name: 'profile.set-phone.confirm',
        component: SetPhoneConfirm,
    },
]