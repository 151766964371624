export class Game {
    constructor(
        {
            id,
            title,
            image,
            provider,
            categories,
            can_play_bonus,
            has_demo,
            order
        }) {
        this.id = String(id)
        this.title = String(title)
        this.image = String(image)
        this.provider = String(provider)
        this.categories = categories.map(c => String(c))
        this.can_play_bonus = Boolean(can_play_bonus)
        this.has_demo = Boolean(has_demo)
        this.order = Number(order);
    }

    isBelongsProvider(provider) {
        return this.provider === provider;
    }

    isHasSearchTerm(term) {
        return term !== ''
            && this.title.toLowerCase().includes(term.toLowerCase());
    }

    isBelongsCategory(category) {
        return this.categories.includes(category);
    }

}

export class Filter {
    /** @param {Game[]} games */
    constructor(games) {
        const ordered = [...games];
        ordered.sort((a, b) => b.order - a.order);
        this.games = ordered
    }

    providers() {
        const list = [];
        this.games.forEach(({provider}) => !list.includes(provider) && list.push(provider));
        return list;
    }

    gamesOfCategory(categoryName, limit = 4) {
        const list = [];
        this.games.forEach(game => {
            if (game.isBelongsCategory(categoryName) && limit-- > 0) {
                list.push(game)
            }
        })
        return list;
    }

    filter({category, provider, search, limit}) {
        const list = [];
        let lim = limit;
        this.games.forEach(game => {
            if (lim < list.length) {
                return;
            }

            if (game.isBelongsCategory(category) ||
                game.isBelongsProvider(provider) ||
                game.isHasSearchTerm(search)) {
                list.push(game)
            }
        })
        return list;
    }

    total_filter({category, provider, search}) {
        let val = 0;
        this.games.forEach(game => {
            if (game.isBelongsCategory(category) ||
                game.isBelongsProvider(provider) ||
                game.isHasSearchTerm(search)) {
                val++;
            }
        });
        return val;
    }

}
