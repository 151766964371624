<template>
  <div class="withdraw-form">
    <Section v-show="showForm">
      <div class="withdraw__input">
        <p class="title">{{ $t('payOut.sum.label') }}</p>
        <div
            :class="['input__default', { 'in-error': $v.withdrawValue.$error }]"
        >
          <input v-model="withdrawValue" inputmode="numeric"/>
          <p class="currency">{{ currency }}</p>
          <div
              :class="['error__label', { 'in-error': $v.withdrawValue.$error }]"
          >
            {{ sumError }}
          </div>
        </div>
      </div>
      <Help style="position: absolute; top: 10px; right: 10px">
        Укажите номер карты, сумму и нажмите ВЫВЕСТИ.<br/><br/>
        * Выплаты приходят моментально. Вывод возможен только на карты выпущенные в РК.<br/><br/>
        ** Максимальная выплата в день с одного логина: 10 000 000 тенге.<br/><br/>
        *** При выплате суммы превышающий 10 000 000 тенге, платеж будет раздроблен на несколько дней.
      </Help>
    </Section>
    <Section style="color: #fff">Минимальная сумма вывода на карту: {{ minSum }} {{ currency }}, на телефон:
      {{ minSumPhone }} {{ currency }}.
    </Section>

    <Limitations/>

    <div class="buttons" v-if="needSetPhone">
      <button class="btn__orange" @click="$router.push({name: 'profile'})">{{ $t('payOut.needSetPhone') }}</button>
    </div>

    <div class="buttons" v-if="!needSetPhone && canWithdraw && !holdActive && pss.length > 1">
      <button v-for="ps in pss" :key="ps.id" :class="{'btn__orange': true, selected: ps.id === selected.id}"
              @click="selected = ps">
        {{ $t(ps.phone ? 'payOut.buttonPhone' : 'payOut.buttonCard') }}
        {{ ps.n ? ps.n + 1 : '' }}
      </button>
    </div>

    <Section style="color: #fff" v-if="payoutHint">
      {{ payoutHint }}
    </Section>

    <Section v-show="selected.id && selected.phone === false">
      <div class="withdraw__input">
        <p class="title">{{ $t('payOut.cardNumber.label') }}</p>
        <div
            :class="['input__default', { 'in-error': $v.withdrawalCard.$error }]"
        >
          <the-mask
              v-model="withdrawalCard"
              mask="#### #### #### ####"
              type="text"
              inputmode="numeric"
              :masked="false"
              placeholder="0000 0000 0000 0000"
          />
          <div
              :class="['error__label', { 'in-error': $v.withdrawalCard.$error }]"
          >
            {{ cardError }}
          </div>
        </div>
      </div>
    </Section>

    <Section v-show="selected.id && selected.phone === true">
      <TelInput
          v-model="phone"
          :title="$t('payIn.phone.phoneLabel')"
          :validate="$v.phone"
          :custom-style="{marginBottom: '16px'}"
      />
    </Section>

    <div class="buttons" v-if="selected.id && canWithdraw && !holdActive">
      <button class="btn__orange" @click="requestPayout">{{ $t('payOut.buttonPay') }}</button>
    </div>

    <div class="buttons" v-if="!canWithdraw || holdActive">
      <button class="btn__orange" @click="goHome">{{ $t('payOut.goHome') }}</button>
    </div>

    <br>
    <ConfirmationBlock v-if="outConfirmationPossible"/>
  </div>
</template>

<script>
import {
  required,
  minValue,
  minLength,
  maxValue,
} from "vuelidate/lib/validators";
import Limitations from "./Limitations/Limitations";
import Help from "../../views/Wallet/Help";
import ConfirmationBlock from "./Confirmation/ConfirmationBlock";
import Section from "@/components/Payout/Section.vue";
import TelInput from "@/components/Ui/TelInput.vue";
import psFromJson from "@/json/payout.json";

export default {
  components: {
    TelInput,
    Section,
    ConfirmationBlock, Limitations, Help
  },
  data() {
    return {
      withdrawValue: null,
      withdrawalCard: "",
      phone: "",
      selected: {id: null, phone: false},
    };
  },
  computed: {
    needSetPhone() {
      return this.$store.state.outConfirmation
          && !this.$store.state.hasPhone
    },
    showForm() {
      return this.canWithdraw || !this.hasDeposit
    },
    outConfirmationPossible() {
      return this.$store.state.outConfirmationPossible;
    },
    holdActive() {
      return this.$store.state.holdPayoutUntil > (Date.now() / 1000)
    },
    canWithdraw() {
      return !this.$store.state.wagerLimitation
          && (
              (this.availableBalance >= this.minSum)
              || (this.availableBalance >= this.minSumPhone)
          )
    },

    minSum() {
      const level = this.$store.state.config.payout.min.filter(l => l.currency === this.$store.state.currencyCode)[0] || {sum: 1000}
      return level.sum;
    },
    minSumPhone() {
      const level = this.$store.state.config.payout.phone_min.filter(l => l.currency === this.$store.state.currencyCode)[0] || {sum: 1000}
      return level.sum;
    },
    hasDeposit() {
      return this.$store.state.hasDeposit;
    },
    currency() {
      return this.$store.state.currency;
    },
    payoutHint() {
      return this.$store.state.config.payoutHint;
    },
    sumError() {
      if (!this.$v.withdrawValue.required) {
        return this.$i18n.t('payOut.sum.required');
      }
      if (!this.$v.withdrawValue.minValue) {
        if (this.selected.phone) {
          return this.$i18n.t('payOut.sum.min', {sum: this.minSumPhone + ' KZT'});
        }
        return this.$i18n.t('payOut.sum.min', {sum: this.minSum + ' ' + this.currency});
      }
      if (!this.$v.withdrawValue.maxValue) {
        return this.$i18n.t('payOut.sum.max');
      }
      return "";
    },
    cardError() {
      if (!this.$v.withdrawalCard.required) {
        return this.$i18n.t('payOut.cardNumber.required');
      }
      if (!this.$v.withdrawalCard.minLength) {
        return this.$i18n.t('payOut.cardNumber.full');
      }
      return "";
    },
    availableBalance() {
      const real = this.$store.state.accounts.filter(a => a.title === 'real')[0];
      if (real) {
        return parseFloat(real.sum.replace(/\s/g, ''));
      }
      return 0;
    },
    pss() {
      const enabled = this.$store.state.config.payout.enabled;
      const r = [];
      let cardN = 0;
      let phoneN = 0;
      enabled.filter(item => {
        const [id] = item.split(':');

        if (psFromJson[id]) {
          const crypto = require('crypto');
          const mode = psFromJson[id].allow ? 'allow' : 'deny';
          const domains = psFromJson[id][mode];
          const hostnameCrypto = crypto.createHash('md5').update(window.location.hostname).digest("hex");
          const isDomainInList = domains.includes(window.location.hostname) || domains.includes(hostnameCrypto);

          if ((mode === 'allow' && !isDomainInList) || (mode === 'deny' && isDomainInList)) {
            return false;
          }
        }

        return true;
      }).forEach(item => {
        const [id, tag] = item.split(':');
        const phone = tag === 'phone';
        const n = phone ? cardN++ : phoneN++;
        r.push({id, phone, n});
      })
      return r;
    }
  },
  validations() {
    return {
      withdrawValue: {
        required,
        minValue: minValue(this.selected.phone ? this.minSumPhone : this.minSum),
        maxValue: maxValue(this.availableBalance),
      },
      withdrawalCard: {
        required: this.selected.phone ? false : required,
        minLength: minLength(12)
      },
      phone: {
        required: this.selected.phone ? required : false,
        minLength: minLength(11),
      }
    };
  },
  mounted() {
    this.withdrawalCard = this.loadCard();
    if (this.pss.length === 1) {
      this.selected = this.pss[0];
    }
  },
  methods: {
    loadCard() {
      return window.localStorage.getItem('payout_card') || '';
    },
    saveCard(card) {
      window.localStorage.setItem('payout_card', card);
    },
    goHome() {
      this.$router.push('/')
    },
    requestPayout() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      this.withdrawalCard && this.saveCard(this.withdrawalCard);

      const request = (confirm) => {
        let req = {
          ps: this.selected.id,
          sum: this.withdrawValue,
          currency: this.$store.state.currencyCode,
          ...confirm
        }

        if (this.selected.phone) {
          req.phone = this.phone;
        } else {
          req.card = this.withdrawalCard
        }

        return this.$store.dispatch("payout", req);
      }

      if (this.$store.state.outConfirmation) {
        this.$router.push({name: 'payout.confirm', params: {onSuccess: r => this.handleResult(r), request}})
      } else {
        request({}).then((r) => this.handleResult(r));
      }
    },

    handleResult(r) {
      if (r.ok) {
        this.$store.dispatch('balance');
        this.$router.push({name: "payout.success"});
      } else {
        this.$router.push({name: "payout.error", params: {code: r.error_code}});
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.withdraw-form {
  min-height: calc(100vh - 140px);
  position: relative;
}

.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 1s;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.buttons > button {
  width: unset;
  flex-grow: 1;
  flex-shrink: 1;
}

button.selected {
  background-color: #b14108;
}

</style>
