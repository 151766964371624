<template>
  <div class="promo-details-page">
    <transition
        mode="out-in"
        enter-class="enter-start-position"
        enter-to-class="enter-end-position"
        enter-active-class="while-enter"
        leave-class="enter-end-position"
        leave-to-class="leave-end-position"
        leave-active-class="while-enter"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Details"
}
</script>

<style scoped>
.promo-details-page {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #1b1c37;
  z-index: 1001;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>