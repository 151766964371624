export default function (makeRequest, setAuthToken) {

    this.init = phone => makeRequest('auth/register/init', {phone});
    this.confirm = (code, process_id) => makeRequest('auth/register/confirm', {code, process_id});
    this.complete = (password, process_id) => makeRequest('auth/register/complete', {password, process_id})
        .then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return {success: r.success}
    });

    this.registerUnconfirmed = ({phone, password, currency, data}) => makeRequest('auth/register/unconfirmed', {phone, password, currency, data}).then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return {success: r.success}
    })
}
