<template>
  <div class="tabs">
    <router-link :class="{tab: true, active: this.active === 'deposit'}" :to="{name: 'deposit'}">{{ $t('payIn.header') }}</router-link>
    <router-link :class="{tab: true, active: this.active === 'payout'}" :to="{name: 'payout'}">{{ $t('payOut.header') }}</router-link>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {active: {type: String}},
}
</script>

<style scoped>
.tabs {
  display: flex;
}
.tab {
  flex-grow: 1;
  text-align: center;
  flex-shrink: 1;
  flex-basis: 100px;

  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
  /*line-height: 29px;*/
}

.tab.active {
  border-bottom: 3px solid #f45a0e;
}
</style>
