<template>
  <div class="block" v-if="active">
    <div class="title">{{ $t('depositBanner.label') }}</div>
    <Hint :on-click="deposit"></Hint>
  </div>
</template>

<script>

import Hint from "../Deposit/Hint";
export default {
  name: "DepositBanner",
  components: {Hint},
  computed: {
    active() {
      return this.$store.state.accounts.length > 0 && this.$store.state.balanceDecimal < 200;
    },
  },

  methods: {
    deposit(sum) {
      this.$router.push({ name: 'deposit', params: { sum: sum } });
    },
  }

}
</script>

<style lang="scss" scoped>
.block {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

</style>
