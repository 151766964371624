export function formatAsPhoneNumber(title) {

    if(title.length === 10) {
        return [
            '+' + title.slice(0, 1),
            '(' + title.slice(1, 4) + ')',
            title.slice(4, 7),
            title.slice(7, 9),
            title.slice(9, 11),
        ].join(' ');
    }

    if(title.length === 12) {
        return [
            '+' + title.slice(0, 3),
            title.slice(3, 5),
            title.slice(5, 8),
            title.slice(8, 10),
            title.slice(10),
        ].join(' ');
    }

    return title
}