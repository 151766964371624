<template>
  <div class="site__links">
    <router-link :to="{ name: 'index' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/home.svg"/>
      {{$t('menu.main')}}
    </router-link>
    <router-link :to="{ name: 'promo' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/percent.svg"/>
      {{$t('menu.promo')}}
    </router-link>
    <router-link :to="{ name: 'profile' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/profile.svg"/>
      {{$t('menu.profile')}}
    </router-link>
    <router-link :to="{ name: 'history' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/history.svg"/>
      {{$t('menu.history')}}
    </router-link>
    <router-link :to="{ name: 'deposit' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/deposit.svg"/>
      {{$t('menu.deposit')}}
    </router-link>
    <router-link :to="{ name: 'payout' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/payout.svg"/>
      {{$t('menu.payout')}}
    </router-link>
    <router-link :to="{ name: 'logout' }" class="menu__link" @click.native="click">
      <img src="../../../assets/icons/menu/exit.svg"/>
      {{$t('menu.logout')}}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "PlayerMenu",
  props: {
    click: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
