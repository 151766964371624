<script>
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from "firebase/messaging";

const subscribe = ({firebaseConfig, vapidKey}) => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  return getToken(messaging, {vapidKey});
}

export default {
  name: "FirebaseWebPush",
  mounted() {
    subscribe(this.config)
        .then(token => this.$store.dispatch('sendPushToken', {token}))
        .catch(() => {});
  },
  computed: {
    config() {
      return {
        firebaseConfig: {
          apiKey: "AIzaSyBxFfasKJy-Fe5LIN2fnXszqjB-4gFOGhk",
          authDomain: "club-kz.firebaseapp.com",
          projectId: "club-kz",
          storageBucket: "club-kz.appspot.com",
          messagingSenderId: "874443311748",
          appId: "1:874443311748:web:16ebb86edbe5ec8a4690d1",
          measurementId: "G-DSMSW58HDN"
        },
        vapidKey: 'BE5yjBB7BVeILXLAvfboyfE5nD8-PvjwNc_doWF-y60DX5MU19p2u9P5Hjy1LPrXHaq5Q0mzfCgCYdEJmn78qVw'
      }
    },
  }
}
</script>

<template>
  <div v-if="false">
  </div>
</template>
