<template>
  <div class="close" @click="click">
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
          fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseButton",
  props: {
    customStyle: {type: Object, default: () => ({})},
    click: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>