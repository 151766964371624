<template>
  <section class="forget">
    <div class="forget__container">
      <div class="forget__main">
        <p class="forget__title">{{ $t('registration.complete.title') }}</p>
        <p class="sms__text">{{ $t('registration.complete.text') }}</p>
        <PasswordInput
            v-model="userPassword"
            :title="$t('registration.complete.password.label')"
            :placeholder="$t('registration.complete.password.placeholder')"
            :validate="$v.userPassword"
            :condition="formButtonLock"
            @input="formButtonLock = false"
        />
        <PasswordInput
            v-model="userRepeatPassword"
            :title="$t('registration.complete.confirmPassword.label')"
            :placeholder="$t('registration.complete.confirmPassword.placeholder')"
            :condition="formButtonLock"
            :validate="$v.userRepeatPassword"
            @input="formButtonLock = false"
        />
        <p
            :class="['error__label', { 'in-error': formButtonLock || $v.$error }]"
        >
          {{ errorMessage }}
        </p>
      </div>
      <div class="forget__events">
        <button
            class="btn__orange comeIn"
            :disabled="
            $v.userRepeatPassword.$error ||
            $v.userPassword.$error ||
            formButtonLock
          "
            @click="forget()"
        >
          {{ $t('registration.complete.button') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import PasswordInput from "../../Ui/PasswordInput";

export default {
  components: {
    PasswordInput,
  },
  data() {
    return {
      userPassword: "",
      userRepeatPassword: "",
      formButtonLock: false,
    };
  },
  computed: {
    process_id() {
      return this.$store.state.register.process_id
    },
    errorMessage() {

      if (!this.$v.userRepeatPassword.required) {
        return this.$i18n.t('registration.complete.error.required');
      }

      if (!this.$v.userPassword.minLength) {
        return this.$i18n.t('registration.complete.error.min');
      }

      if (!this.$v.userRepeatPassword.sameAsPassword) {
        return this.$i18n.t('registration.complete.error.match');
      }

      return '';
    },
  },
  validations: {
    userPassword: {
      required,
      minLength: minLength(6),
    },
    userRepeatPassword: {
      required,
      sameAsPassword: sameAs('userPassword'),
    },
  },
  methods: {
    forget() {
      this.$v.$touch()
      if(this.$v.$error) {
        return
      }

      this.$store.dispatch('register/complete', this.userPassword)
          .then(r => {
            if (r.success) {
              this.$router.push({name: 'registration.completed'});
            } else {
              this.formButtonLock = false;
            }
          });

    },
  },
};
</script>

<style lang="scss" scoped>
.error__label {
  margin-top: -20px;
  position: static;
}

.forget {
  background-color: #1b1c37;
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  .forget__container {
    height: 100%;
    position: relative;

    .forget__main {
      position: relative;
      margin-bottom: 20px;
      top: 0;
      transition: 0.2s;
      transition-delay: 0.2s;

      .forget__title {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
        text-align: center;
      }

      .link__orange.pass {
        max-width: max-content;
        display: flex;
        margin-left: auto;
        position: relative;
        top: -10px;
      }

      .sms__text {
        max-width: 222px;
        margin: 0 auto 40px auto;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);

        span {
          color: #ffffff;
        }
      }
    }

    .forget__error {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      transition-delay: 0s;

      p {
        margin-bottom: 15px;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        color: #ffffff;
      }

      &.in-error {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.2s;
      }
    }

    .forget__events {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 40px;
    }
  }

  &.active-main {
    right: -100%;
    left: 100%;
    opacity: 0;
  }

  &.active-next {
    right: -100%;
    left: 100%;
    opacity: 0;
  }
}
</style>