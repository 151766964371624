<template>
  <div class="wheel">
    <div class="heading">{{ $t('banners.wheel.title') }}</div>
    <button v-if="button" class="btn__orange" style="max-width: 120px">Начать</button>
  </div>
</template>

<script>
export default {
  props: {
    button: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.wheel {
  width: 100%;
  min-height: 120px;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/jackpot.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: contain;
  background-color: #03021b;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px;

  .heading {
    max-width: 120px;
    font-weight: 600;
    line-height: 140.7%;
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 20px;
  }

}
</style>
