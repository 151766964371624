<template>
  <div class="payout">
    <Tabs :active="'payout'"/>
    <router-view></router-view>
  </div>
</template>

<script>

import Tabs from "@/views/Wallet/Tabs";

export default {
  name: 'Payout',
  components: {Tabs}
};
</script>

<style lang="scss" scoped>
.payout {
  padding: 0 20px;
}
</style>
