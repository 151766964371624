<template>
  <div
    :class="[
      'input__default',
      { 'in-error': validate.$error || condition || customError },
      { 'in-success': !validate.$invalid && !condition && !customError },
    ]"
    :style="customStyle"
  >
    <label>{{ title }}</label>
    <div class="input__container">
      <the-mask
        v-model="userTelFormat"
        :mask="mask"
        type="text"
        :placeholder="placeholder"
        :masked="false"
        @input="onInput"
        inputmode="numeric"
        @focus.native="handleFocus"
        :readonly="readonly"
      />
    </div>
    <p :class="['error__label', { 'in-error': validate.$error}]" >
      {{ error }}
    </p>

    <p :class="['error__label', { 'in-error': !!customError && !validate.$error }]">
      {{ customError }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "Укажите номер целиком",
    },
    placeholder: {
      type: String,
      default: "",
    },
    condition: {
      type: Boolean,
      default: null,
    },
    validate: {
      type: Object,
      default: () => ({
        $error: false,
        $invalid: false,
      }),
    },
  },
  data() {
    return {
      userTelFormat: this.value,
    };
  },
  computed: {
    mask(){
      return this.$store.state.config.phoneMask || '+# (###) ###-##-##';
    }
  },
  methods: {
    onInput() {
      this.$emit("input", this.userTelFormat);
    },
    handleFocus() {
      if(this.userTelFormat === '') {
        this.userTelFormat = this.$store.state.config.phonePrefix
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.input__default {
  .input__container {
    position: relative;
    .check-password {
      display: flex;
      position: absolute;
      top: 20px;
      right: 12px;
    }
  }
}
</style>