import {LocalStorage} from 'ttl-localstorage';

export class PartnerData {
    constructor() {
        this.key = 'partner-data';
        this.ttl = 120;
    }

    populateFromUrl(urlString) {
        const url = new URL(urlString);

        const urlHas = names => {
            for (const name of names) {
                if (!url.searchParams.has(name)) {
                    return false;
                }
            }
            return true;
        }

        const params = {};
        url.searchParams.forEach((v, k) => params[k] = v);

        if (
            urlHas(['esub', 'pub_id']) ||
            urlHas(['subid', 'sources']) ||
            urlHas(['utm_source']) ||
            urlHas(['partner']) ||
            window.Android
        ) {
            if(window.Android) {
                params.android_app_id = window.Android.getAppId();
            }

            LocalStorage.put(this.key, params, this.ttl)
        }
    }

    flush(flusher) {
        LocalStorage.runGarbageCollector();
        flusher(this.get()).then(() => LocalStorage.removeKey(this.key))
    }

    get() {
        return LocalStorage.get(this.key, {});
    }

}
