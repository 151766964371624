<template>
  <section class="recovery">
    <div class="recovery__container">
      <div :class="['recovery__main', { 'in-error': recoveryFormError }]">
        <p class="recovery__title">{{ $t('recover.request.header') }}</p>
        <p class="recovery__text">
          {{ $t('recover.request.text') }}
        </p>
        <TelInput
            v-model="userRecoveryTel"
            :type="'text'"
            :placeholder="$t('recover.request.tel.placeholder')"
            :validate="$v.userRecoveryTel"
            @input="recoveryFormError = false"
        />
        <div :class="['recovery__error', { 'in-error': recoveryFormError }]">
          <p>{{ $t('recover.request.error.notFound') }}</p>
          <router-link
              :to="{ name: 'registration' }"
              class="btn__orange-border"
          >
            {{ $t('recover.request.error.regButton') }}
          </router-link>
        </div>
        <div class="recovery__events">
          <button
              class="btn__orange comeIn"
              :disabled="$v.userRecoveryTel.$error || recoveryFormError"
              @click="request()"
          >
            {{ $t('recover.request.button') }}
          </button>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import TelInput from "../../Ui/TelInput";
import {required, minLength} from "vuelidate/lib/validators";

export default {
  components: {TelInput},
  data() {
    return {
      userRecoveryTel: "",
      recoveryErrorMessage: "",
      recoveryFormError: false,
    };
  },
  validations: {
    userRecoveryTel: {
      required,
      minLength: minLength(11),
    },
  },
  methods: {
    request() {
      this.$v.$touch()
      if(this.$v.$error) {
        return
      }

      this.$store.dispatch('recover/init', this.userRecoveryTel)
          .then(r => {
            if (!r.success) {
              this.recoveryFormError = true;
            } else {
              this.$router.push({name: 'recover.confirm'});
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.recovery {
  background-color: #1b1c37;
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  &.active-main {
    right: -100%;
    left: 100%;
    opacity: 0;
  }

  &.active-next {
    right: -100%;
    left: 100%;
    opacity: 0;
  }
}

.recovery__container {
  height: 100%;
  position: relative;
}

.recovery__main {
  position: relative;
  margin-bottom: 20px;
  transition: 0.2s;
  transition-delay: 0.2s;

  .recovery__title {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
  }

  .recovery__text {
    max-width: 240px;
    margin: 0 auto 25px auto;
    font-weight: normal;
    font-size: 16px;
    line-height: 130.4%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  &.in-error {
    top: 0;
  }
}

.recovery__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  transition-delay: 0s;
  height: 0;
  overflow: hidden;
  p {
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 130.4%;
    color: #ffffff;
    text-align: center;
  }

  &.in-error {
    overflow: visible;
    height: initial;
    transition-delay: 0.2s;
    margin-bottom: 16px   ;
  }
}

.recovery__events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .comeIn {
    margin-bottom: 16px;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 130.4%;
    color: rgba(255, 255, 255, 0.5);
  }
}

</style>
