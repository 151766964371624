<template>
  <div v-if="googleClientId || mailruClientId">
    <p class="social-header">{{ $t('social.header') }}</p>
    <div class="social">
      <GoogleButton v-if="googleClientId" :client-id="googleClientId"/>
      <MailruButton v-if="mailruClientId" :client-id="mailruClientId"/>
    </div>
  </div>
</template>

<script>
import GoogleButton from "./Google/GoogleButton";
import MailruButton from "./Mailru/Button";

export default {
  name: "Social",
  components: {MailruButton, GoogleButton},
  computed: {
    mailruClientId() {
      return this.$store.state.config.mailru.client_id;
    },
    googleClientId() {
      return this.$store.state.config.google.client_id;
    },
  }
}
</script>

<style scoped>
.social-header {
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 16px;
  line-height: 130.4%;
  color: #ffffff;
  text-align: center;
}

.social {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  gap: 16px;
}
</style>