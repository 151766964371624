<template>
  <SmsForm :tel="tel" :sender="sendCode" :sms-form-error="error" :customStyle="{top:'110px'}"/>
</template>

<script>
import SmsForm from "../../components/Auth/SmsForm";

export default {
  components: {SmsForm},
  computed: {
    tel() {
      return this.$store.state.phoneSet.tel
    },
    process_id() {
      return this.$store.state.phoneSet.process_id
    }
  },
  data() {
    return {error: false};
  },
  methods: {
    sendCode(code) {
      this.$store.dispatch('phoneSet/confirm', code).then(r => {
        if (r.success) {
          this.$store.commit('identityConfirmed');
          this.$router.push({name: 'profile.confirm-phone.success'});
        } else {
          this.error = true
        }
      })
    }
  }
};
</script>