<template>
  <div class="auth-page">
    <div class="close" :style="[canBack ? {visibility: 'visible'} : {visibility: 'hidden'} ]">
      <img src="../assets/icons/auth/arrow.svg" @click="close()"/>
    </div>
    <transition name="slide">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
  computed: {
    canBack() {
      return this.$route.name.indexOf('completed') === -1;
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-page {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #1b1c37;
  z-index: 1001;
  overflow-y: scroll;
  overflow-x: hidden;

  .close {
    padding: 20px 20px 11px 20px;
  }
}

</style>