<template>
  <Popper trigger="clickToToggle" ref="popper"
          :visible-arrow="false"
          transition='fade'
          enter-active-class='fade-enter-active'
          leave-active-class='fade-leave-active'>
    <div class="popper person-info">
      <div v-for="item in accounts" :key="item.title + item.currency" class="row">
        <span class="titleBalanceType">{{ $t('account.title.' + item.title) }}</span>
        <span class="sum">{{ item.sum }} {{ symbol(item.currency) }}</span>
      </div>
    </div>
    <div slot="reference">
      <p class="titleBalance">{{ title }}</p>
      <p class="bankBalance">{{ balances.join(', ') }}</p>
    </div>
  </Popper>

  <!--  <div class="person-info"></div>-->
</template>

<script>
import Popper from 'vue-popperjs';
import {currencySymbol} from "../../store";
import {formatAsPhoneNumber} from '../../store/formatAsPhone';

export default {
  components: {Popper},
  name: "Balance",
  data() {
    return {
      showPopover: false,
    };
  },
  methods: {
    symbol(c) {
      return currencySymbol(c);
    }
  },
  computed: {
    title() {
      let title = this.$store.state.player_label;
      if (title.match(/^\d+$/)) {
        title = formatAsPhoneNumber(title);
      }
      return title;
    },
    balances() {
      const b = [];
      this.$store.state.balances.forEach(balance => {
        b.push(balance.string + ' ' + currencySymbol(balance.currency))
      })
      return b;
    },
    accounts() {
      const accs = [];
      this.$store.state.balances.forEach(balance => {
        balance.accounts.forEach(a => accs.push(a))
      })
      return accs;
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBalance {
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  font-weight: 500;
  font-size: 12px;
}

.bankBalance {
  color: #fff;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.popper {
  padding: 14px 12px;
  margin-top: 8px;
  margin-right: 15px;
  background: #2c2c4d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 34px rgba(209, 188, 188, 0.15);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .row {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    &:last-child {
      padding-bottom: 0px;
    }
  }

  .titleBalanceType {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  .sum {
    padding-left: 52px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #ffffff;
  }
}
</style>
