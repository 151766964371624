import History from "../../views/Wallet/History";
import payout from "./payout";
import deposit from "./deposit";
export default [
    ...payout,
    ...deposit,
    {
        path: '/history',
        component: History,
        name: 'history',
        meta: {requiresAuth: true}
    },
]