<template>
  <router-link v-if="needLink" :to="{ name: 'deposit' }" class="green-btn deposit router-link-exact-active router-link-active" />
  <div v-else class="green-btn back" @click="$router.push({name: 'index'})"/>
</template>

<script>
export default {
  name: "DepositButton",
  computed: {
    needLink() {
      return this.$route.name !== 'deposit'
      && this.$route.name !== 'payout'
    }
  }
}
</script>

<style scoped>
.green-btn {
  font-size: 14px;
  border-radius: 4px;
  background-color: #60BF75;
  display: block;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
}

.deposit {
  background-image: url("../../assets/icons/header/money.svg");
}
.back {
  background-image: url("../../assets/icons/auth/arrow.svg");
}
</style>
