<template>
  <div class="footer-buttons">
    <div class="footer-buttons-item" @click="setFilters('category', 'lotoclub')">
      <img class="footer-buttons-item-img footer-buttons-item-img-lotto" src="../assets/icons/footer/lotto.svg" alt="">
      <div class="footer-buttons-item-text footer-buttons-item-text-lotto">Лото</div>
    </div>
    <div class="footer-buttons-item" @click="setFilters('category', 'slots')">
      <img class="footer-buttons-item-img footer-buttons-item-img-slots" src="../assets/icons/footer/slots.svg" alt="">
      <div class="footer-buttons-item-text footer-buttons-item-text-slots">Слоты</div>
    </div>
    <div class="footer-buttons-item" @click="$router.push({name: 'deposit'})">
      <img class="footer-buttons-item-img footer-buttons-item-img-replenish"
           src="../assets/icons/footer/replenishUp.svg" alt="">
      <div class="footer-buttons-item-text footer-buttons-item-text-replenish">Пополнить</div>
    </div>
    <div class="footer-buttons-item" @click="setFilters('category', 'minigames')">
      <img class="footer-buttons-item-img footer-buttons-item-img-mini" src="../assets/icons/footer/miniGames.svg"
           alt="">
      <div class="footer-buttons-item-text footer-buttons-item-text-mini">Мини игры</div>
    </div>
    <div class="footer-buttons-item" @click="$router.push({name: 'promo.wheel'})">
      <img class="footer-buttons-item-img footer-buttons-item-img-koleso" src="../assets/icons/footer/koleso.svg"
           alt="">
      <div class="footer-buttons-item-text footer-buttons-item-text-koleso">Колесо удачи</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setFilters(key, value) {
      const filters = {
        category: 'all',
        provider: 'all',
        search: '',
        gamesLimit: 10
      };

      filters[key] = value;

      localStorage.setItem('filter', JSON.stringify(filters));

      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
.footer-buttons {
  display: flex;
  background: #32334B;
  width: 100%;
  height: 60px;

  &-item {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;

    &-img {
      &-lotto {
        margin-top: 7px;
      }

      &-slots {
        margin-top: 8px;
      }

      &-replenish {
        margin-top: -9px;
      }

      &-mini {
        margin-top: 8px;
      }

      &-koleso {
        margin-top: 8px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 10px;
      color: white;

      &-lotto {
        margin-top: 2px;
      }

      &-slots {
        margin-top: 2px;
      }

      &-replenish {
        margin-top: 2px;
      }

      &-mini {
        margin-top: 2px;
      }

      &-koleso {
        margin-top: 2px;
      }
    }
  }

}
</style>