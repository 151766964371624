<template>
  <Popper trigger="clickToOpen" :options="{placement: 'top', modifiers: { offset: { offset: '0,10px' } }}">
    <unicon name="copy" @click="toClipboard" style="position: absolute; top: 16px; right: 12px; background-color: #4a4a6c; padding-left: 6px"
            slot="reference"/>
    <div class="popper">
      {{$t('utils.copied')}}
    </div>
  </Popper>
</template>

<script>
import copy from 'copy-to-clipboard';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  name: "Copy",
  components: {Popper},
  props: {
    text: {
      type: String
    }
  },
  methods: {
    toClipboard() {
      copy(this.text)
    }
  }
}
</script>

<style scoped>

</style>
