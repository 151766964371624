<script>
export default {
  name: "Section"
}
</script>

<template>
  <div class="withdraw-form__container">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.withdraw-form__container {
  padding: 20px;
  margin-bottom: 12px;
  background: #2c2c4d;
  border-radius: 6px;

  .withdraw__input {
    .input__default {
      margin-bottom: 0px;

      &.in-error {
        margin-bottom: 12px;
      }
    }

    .title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
    }

    .currency {
      position: absolute;
      top: 19px;
      right: 11px;
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .withdraw__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      padding: 8px;
      margin-right: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      color: #ffffff;
      background: #4a4a6c;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>