export default {
    "faq": {
        "register": {
            "q": "पंजीकरण और लॉगिन",
            "a": "ऊपरी दाएं कोने में \"रजिस्टर\" बटन पर क्लिक करें, यदि आपके पास पहले से खाता है तो अपना फोन नंबर और पासवर्ड दर्ज करें। एक खाता खोलने के लिए, आपको पंजीकरण करने की आवश्यकता है और इसमें प्रस्तुत किए गए सामाजिक नेटवर्क में से किसी एक के माध्यम से अधिक समय या लॉग इन नहीं होगा। अब आप खेल सकते हैं, जीत सकते हैं और बोनस प्राप्त कर सकते हैं!"
        },
        "who-can": {
            "q": "कार्यालय कौन खोल सकता है?",
            "a": "खाता केवल 18 वर्ष से अधिक आयु के व्यक्तियों द्वारा खोला जा सकता है या जो उस देश के कानूनों के अनुसार कानूनी उम्र तक पहुंच चुके हैं जिसमें निर्दिष्ट व्यक्ति स्थित है। प्रत्येक खिलाड़ी के पास केवल एक कैबिनेट हो सकता है"
        },
        "find-game": {
            "q": "एक खेल कैसे खोजें?",
            "a": "मुख्य पृष्ठ पर \"खोज\" का उपयोग करते हुए, खेल का पूरा नाम या पहले कुछ अक्षर दर्ज करें। खेलों को \"प्रदाता\" द्वारा भी फ़िल्टर किया जा सकता है।"
        },
        "deposit": {
            "q": "कैसे फिर से भरना है?",
            "a": "\"टॉप अप\" पर क्लिक करें और टॉप अप करने के लिए राशि दर्ज करें। खुलने वाली विंडो में, कार्ड नंबर, समाप्ति तिथि और कार्ड के पीछे तीन अंकों का कोड दर्ज करें। उसके बाद, \"पे\" पर क्लिक करें। बैंक की गलती के कारण दुर्लभ मामलों के अपवाद के साथ, तुरंत पुनःपूर्ति होती है। किसी भी प्रश्न के लिए, कृपया चैट से संपर्क करें।"
        },
        "payout": {
            "q": "कैसे प्राप्त करें?",
            "a": "\"निकासी\" पर क्लिक करें और खुलने वाली विंडो में, वह राशि और कार्ड नंबर निर्दिष्ट करें जिससे आप भुगतान प्राप्त करना चाहते हैं। सत्यापन के बाद, भुगतान कार्ड में जमा किया जाएगा। किसी भी प्रश्न के लिए, कृपया चैट से संपर्क करें।"
        },
        "min-sum": {
            "q": "न्यूनतम जमा और भुगतान राशि क्या है?",
            "a": "न्यूनतम पुनःपूर्ति राशि 700 रुपये है। न्यूनतम भुगतान राशि 500 रुपये है"
        },
        "bonus": {
            "q": "बोनस और बोनस खाते क्या हैं?",
            "a": "बोनस क्लब की आंतरिक मुद्रा है, जिसे हमारी संस्था से उपहार के रूप में जमा किया जाता है और बोनस खाते में संग्रहीत किया जाता है। पदोन्नति में भाग लेकर बोनस प्राप्त किया जा सकता है। बोनस राशि निकालने के लिए, बोनस राशि को दांव पर लगाना होगा।"
        },
        "wager": {
            "q": "एक सक्रिय दांव क्या है?",
            "a": "दांव - शर्तों का एक सेट जो प्राप्त पुरस्कार को वापस लेने के लिए पूरा किया जाना चाहिए। \"वापस जीतें\" - खेल के दौरान एक निश्चित राशि के लिए स्पिन या दांव लगाएं, जीत या हार की परवाह किए बिना (रील के प्रत्येक स्पिन की गणना की जाती है, जिस पर वास्तविक धन लिखा जाता है)। प्रत्येक बोनस और प्रत्येक जमा का अपना दांव (दांव अनुपात) होता है। यह वह गुणांक है जिसके द्वारा दांव लगाने के लिए दांव की राशि प्राप्त करने के लिए राशि को गुणा किया जाता है। उदाहरण के लिए, दांव x2। 1,000 बोनस वापस लेने के लिए, आपको खेल के दौरान 2,000 दांव लगाने होंगे । आवश्यक बाजी राशि का काउंटर \"निकासी\" मेनू में स्थित है।"
        },
        "password": {
            "q": "पासवर्ड भूल गए?",
            "a": "अपना पासवर्ड पुनर्प्राप्त करने के लिए, \"अपना पासवर्ड भूल गए\" का उपयोग करें। अपना फोन नंबर दर्ज करें, प्राप्त एसएमएस कोड दर्ज करें और एक नया पासवर्ड बनाएं। किसी भी प्रश्न के लिए, आप चैट पर लिख सकते हैं।"
        },
        "header": "सामान्य प्रश्न"
    },
    "promo": {
        "cashback": {
            "title": "क्लब कैशबैक",
            "body": "<p>कैशबैक &mdash; खोए हुए धन के एक निश्चित प्रतिशत की वापसी।</p><p>कैशबैक क्लब के नियमित खिलाड़ियों के लिए उपलब्ध है और साप्ताहिक नुकसान के 10% के बराबर है राशि की गणना करते समय, वास्तविक शेष राशि से केवल खोए हुए धन को ही ध्यान में रखा जाता है। बोनस बैलेंस गणना में शामिल नहीं है। कैशबैक हर शुक्रवार को 18:00 बजे अपने आप क्रेडिट हो जाता है। </p>"
        },
        "deposit": {
            "title": "पहली जमा राशि पर 20% बोनस",
            "body": "<p>इस ऑफ़र के हिस्से के रूप में, उपयोगकर्ता को एक बोनस मिलता है &mdash; जमा राशि का 20%। बोनस प्राप्त करने के लिए आवश्यक न्यूनतम जमा राशि 700 रुपये है।</p><p>यदि कोई बोनस लागू किया जाता है, तो दांव की राशि जमा की गई राशि और बोनस के कुल मूल्य पर सेट की जाएगी।</p><p>अर्थात, यदि आप 10,000 रुपये जमा करते हैं, तो आपको 2,000 बोनस प्राप्त होंगे।</p>"
        },
        "wheel": {
            "title": "भाग्य का पहिया - अपने भाग्य को स्पिन करें",
            "body": "<p>एक रोमांचक मिनी-गेम जहां आप कई तरह के पुरस्कार जीत सकते हैं!</p><p>भाग्य का पहिया &mdash; यह एक मुफ्त दैनिक मिनी-गेम है जहां आप बोनस अंक जीत सकते हैं, अपनी जमा राशि में वृद्धि या क्लब से अच्छे पुरस्कार जीत सकते हैं। फॉर्च्यून का पहिया क्षेत्रों में विभाजित है, और उनमें से लगभग प्रत्येक का अपना पुरस्कार है। भाग्य का पहिया शुरू करने के लिए, पहिया के केंद्र में 'प्रारंभ' बटन पर क्लिक करें। खेल का परिणाम एक यादृच्छिक संख्या जनरेटर का उपयोग करके निर्धारित किया जाता है। कपड़ों के पुरस्कारों के वितरण के लिए भुगतान विजेता की कीमत पर किया जाता है</p>"
        }
    },
    "header": {
        "login": "प्रवेश द्वार",
        "balance": "संतुलन"
    },
    "banners": {
        "firstDeposit": {
            "title": "नए खिलाड़ियों के लिए बोनस"
        },
        "wheel": {
            "title": "भाग्य का पहिया"
        },
        "cashback": {
            "title": "हम खाते में पैसे वापस कर देंगे"
        },
        bonusBanner: {
            title: "बोनस<br>सक्रिय",
            action: "+20% प्राप्त करें"
        }
    },
    "index": {
        "getBonusButton": "एक बोनस प्राप्त करें",
        "winnersHeader": "आज के नायक",
        "showMore": "और दिखाओ",
        "gamesHeaderDefault": "खेल",
        "providers": "प्रदाताओं",
        "searchPlaceholder": "स्लॉट खोज",
        "favorite": 'पसंदीदा',
        "lastGames": 'नवीनतम खेल',
        "lotoClub": "लोट्टो क्लब",
        "slots": "स्लॉट्स",
        "roulette": "रूले",
        "minigames": "छोटे खेल",
        "allGames": "सभी खेल"
    },
    "menu": {
        "main": "घर",
        "promo": "छूट",
        "profile": "मेरी प्रोफाइल",
        "history": "कहानी",
        "deposit": "फिर से भरना",
        "payout": "वापस लेना",
        "logout": "बाहर जाओ",
        "login": "प्रवेश द्वार",
        "entrance": "लॉगिन /पंजीकरण",
        "licence": "लाइसेंस",
        "faq": "सामान्य प्रश्न"
    },
    "login": {
        "header": "प्रवेश",
        "phoneInput": {
            "label": "फ़ोन नंबर",
            "placeholder": "टेलीफ़ोन",
            "required": "पूरा नंबर दर्ज करें"
        },
        "passwordInput": {
            "label": "आपका पासवर्ड",
            "placeholder": "पासवर्ड",
            "required": "एक पासवर्ड दर्ज करें"
        },
        "forgotPasswordLink": "पासवर्ड भूल गए हैं?",
        "invalidCredentials": "दर्ज किया गया पासवर्ड गलत है",
        "restorePasswordButton": "पासवर्ड पुनर्स्थापित करें",
        "loginButton": "प्रवेश द्वार",
        "registerLinkHeading": "कोई प्रोफ़ाइल नहीं है?",
        "registerLink": "पंजीकरण करवाना"
    },
    "account": {
        "title": {
            "real": "बुनियादी",
            "main": "बुनियादी",
            "bonus": "बक्शीश"
        }
    },
    "profile": {
        "header": "प्रोफ़ाइल",
        "passwordForm": {
            "header": "पासवर्ड परिवर्तन",
            "currentPassword": {
                "label": "पुराना पासवर्ड डालें",
                "placeholder": "पुराना पासवर्ड",
                "required": "वर्तमान पासवर्ड दर्ज करें",
                "invalid": "गलत वर्तमान पासवर्ड"
            },
            "newPassword": {
                "label": "नया पारण शब्द भरे",
                "placeholder": "नया पासवर्ड",
                "required": "नया पारण शब्द भरे",
                "min": "कम से कम 8 अक्षर दर्ज करें"
            },
            "confirmPassword": {
                "label": "पासवर्ड दोहराएं",
                "placeholder": "पासवर्ड दोहराएं",
                "required": "नया पासवर्ड दोहराएँ",
                "min": "कम से कम 8 अक्षर दर्ज करें",
                "match": "दर्ज किया गया पासवर्ड मेल नहीं खाता"
            },
            "button": "परिवर्तनों को सुरक्षित करें",
            "success": {
                "title": "पासवर्ड सफलतापूर्वक बदला गया",
                "text": "जारी रखने के लिए बटन पर क्लिक करें",
                "link": "घर"
            }
        }
    },
    "history": {
        "header": "कहानी",
        "payOut": "धन की निकासी",
        "payOutReturn": "निकासी रद्द करना",
        "payIn": "फिर से भरना",
        "status": {
            "complete": "सफलतापूर्वक",
            "wait": "प्रतीक्षा कर रहा है",
            "cancel": "अस्वीकृत"
        },
        "showMoreLink": "और दिखाओ",
        "noMoreResults": "सभी ऑपरेशन दिखा रहा है"
    },
    "payIn": {
        "header": "फिर से भरना",
        "sum": {
            "label": "पुनःपूर्ति राशि दर्ज करें"
        },
        "button": "फिर से भरना",
        "error": {
            "title": "एक गलती हुई है!",
            "text": "बाद में प्रयास करें",
            "link": "घर"
        },
        "success": {
            "title": "फिर से भर दिया!",
            "text": "आपका बैलेंस सफलतापूर्वक टॉप अप कर दिया गया है",
            "link": "घर"
        }
    },
    "payOut": {
        "header": "वापस लेना",
        "sum": {
            "label": "निकासी राशि दर्ज करें",
            "positive": "एक सकारात्मक राशि दर्ज करें",
            "required": "राशि निर्दिष्ट करें",
            "max": "राशि बहुत बड़ी है"
        },
        "cardNumber": {
            "label": "कार्ड नंबर दर्ज करें",
            "full": "कार्ड नंबर पूरा दर्ज करें",
            "required": "कार्ड नंबर दर्ज करें"
        },
        "button": "वापस लेना",
        "goBack": "पीछे",
        "wagerNotice": {
            "header": "सक्रिय दांव",
            "notice": "निकासी को अनलॉक करने के लिए कृपया अपने मुख्य खाते से {sum} पर बेट लगाएं।"
        },
        "identificationNotice": {
            "header": "खाते की पहचान नहीं हुई",
            "notice": "कृपया अपना फ़ोन या ईमेल सत्यापित करें।"
        },
        "error": {
            "title": "एक गलती हुई है",
            "link": "पीछे",
            "code": {
                "internal": "आंतरिक त्रुटि",
                "no_suitable_account": "कोई मिलान खाता नहीं",
                "funds": "अपर्याप्त कोष",
                "invalid_card": "गलत कार्ड नंबर",
                "wager": "आपका दांव दांव पर नहीं लगाया गया है"
            },
            "unknownError": "अज्ञात त्रुटि: {code}"
        },
        "success": {
            "title": "अनुरोध भेज दिया गया है",
            "text": "पेआउट अनुरोध सफलतापूर्वक भेजा गया",
            "link": "घर"
        }
    },
    "licence": {
        "header": "लाइसेंस"
    },
    "notFound": {
        "title": "पृष्ठ नहीं मिला",
        "link": "घर"
    },
    "logout": {
        "title": "आप अपने खाते से लॉग आउट हो गए हैं",
        "text": "जारी रखने के लिए बटन पर क्लिक करें",
        "link": "घर",
        "success": {
            "header": "बाहर आया",
            "text": "क्लिक",
            "button": "घर"
        }
    },
    "smsForm": {
        "title": "एसएमएस से कोड दर्ज करें",
        "sent": "हमने नंबर पर एसएमएस भेजा",
        "notDeliveredLink": "कोड प्राप्त नहीं हुआ?",
        "invalidCode": "सही कोड नहीं!"
    },
    "registration": {
        "init": {
            "title": "पंजीकरण",
            "text": "वह फ़ोन नंबर दर्ज करें जिस पर हम एक पुष्टिकरण कोड के साथ एसएमएस भेजेंगे",
            "tel": {
                "placeholder": "टेलीफ़ोन",
                "required": "पूरा नंबर दर्ज करें"
            },
            "error": {
                "used": "यह फ़ोन नंबर पहले से उपयोग में है!",
                "recoverButton": "पासवर्ड पुनर्स्थापित करें"
            },
            "button": "आगे",
            "hasAccount": "क्या आपके पास पहले से एक खाता मौजूद है?",
            "loginLink": "प्रवेश द्वार"
        },
        "complete": {
            "title": "एक पासवर्ड बनाएं",
            "text": "पासवर्ड में कम से कम 8 अक्षर होने चाहिए!",
            "password": {
                "label": "एक पासवर्ड बनाएं",
                "placeholder": "एक पासवर्ड बनाएं"
            },
            "confirmPassword": {
                "label": "पासवर्ड दोहराएं",
                "placeholder": "पासवर्ड"
            },
            "error": {
                "required": "कृपया पासवर्ड दर्ज करें",
                "min": "पासवर्ड बहुत छोटा है, कृपया कम से कम 8 अक्षर दर्ज करें",
                "match": "पासवर्ड मेल नहीं खाते"
            },
            "button": "आगे"
        },
        "completed": {
            "title": "पंजीकरण पूर्ण",
            "text": "आपने सफलतापूर्वक पंजीकरण कर लिया है, जारी रखने के लिए बटन पर क्लिक करें",
            "link": "घर"
        }
    },
    "recover": {
        "request": {
            "header": "पासवर्ड की दोबारा प्राप्ति",
            "text": "वह फ़ोन नंबर दर्ज करें जिस पर हम एक पुष्टिकरण कोड के साथ एसएमएस भेजेंगे",
            "tel": {
                "placeholder": "टेलीफ़ोन"
            },
            "error": {
                "notFound": "नंबर नहीं मिला",
                "regButton": "पंजीकरण करवाना"
            },
            "button": "आगे"
        },
        "reset": {
            "header": "एक नया पासवर्ड बनाएँ",
            "password": {
                "label": "एक पासवर्ड बनाएं",
                "placeholder": "एक पासवर्ड बनाएं"
            },
            "confirmPassword": {
                "label": "पासवर्ड दोहराएं",
                "placeholder": "पासवर्ड"
            },
            "error": {
                "required": "कृपया पासवर्ड दर्ज करें",
                "min": "पासवर्ड बहुत छोटा है, कृपया कम से कम 8 अक्षर दर्ज करें",
                "match": "पासवर्ड मेल नहीं खाते"
            },
            "button": "आगे"
        },
        "completed": {
            "title": "पासवर्ड सफलतापूर्वक बदला गया",
            "text": "जारी रखने के लिए बटन पर क्लिक करें",
            "link": "घर"
        }
    }
}
