<template>
  <div style="margin-bottom: 1rem;">
    <div>{{ address.currency }} {{ address.chain }}</div>
    <div class="input__default" style="margin-bottom: .4rem">
      <input type="text" :value="address.address" readonly/>
      <Copy :text="address.address"/>
    </div>
    <div>
      Пополнить до <LocalTime :unix_time="address.expiresAt"/>, минимальная сумма: {{ address.min_sum }} {{ address.currency }}
      <div v-if="address.rate !== null" style="margin-top: 0.5em">Курс: {{address.rate.toFixed(2)}}</div>
    </div>
  </div>
</template>

<script>
import Copy from "./Copy";
import LocalTime from "../Ui/LocalTime";

export default {
  name: "Crypto",
  props: ['address'],
  components: {LocalTime, Copy},
}
</script>

<style scoped>
input{
  /*width: calc(100% - 48px);*/
}
</style>