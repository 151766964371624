<template>
  <section class="promo">
    <div class="promo__container">
      <router-link v-if="has('firstDeposit') && false" :to="{name: 'promo.first-deposit'}" class="stock-item">
        <Bonus />
      </router-link>
      <router-link v-if="has('koleso')" :to="{name: 'promo.wheel'}" class="stock-item">
        <WheelOfFortune/>
      </router-link>
      <router-link v-if="has('cb')" :to="{name: 'promo.cash-back'}" class="stock-item">
        <Cashback/>
      </router-link>
    </div>
  </section>
</template>

<script>
import Bonus from "../../components/Promo/FirstDeposit";
import Cashback from "../../components/Promo/Cashback";
import WheelOfFortune from "../../components/Promo/Wheel";
export default {
  components: {WheelOfFortune, Cashback, Bonus},
  methods: {
    has(key) {
      return this.$store.state.config.banners.includes(key)
    }
  }
};
</script>

<style lang="scss" scoped>
.promo {
  .promo__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    .stock-item {
      width: 100%;
      min-height: 140px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      position: relative;
      background: #2c2c4d;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
</style>
