<template>
  <Details :hideButton="status === 'absent'">
    <template v-slot:title>{{$t('promo.ggg.title')}}</template>
    <template v-slot:image><img class="img" src="../../../assets/images/banners/GGG/head.png"/></template>
    <template v-slot:body>
      <button v-if="status === 'absent'" class="btn__orange" @click="activate">
        {{ $t('promo.ggg.click_to_activate') }}
      </button>

      <button v-if="status === 'activated'" class="btn__orange" @click="deposit">
        {{ $t('promo.ggg.activated') }}
      </button>

      <button v-if="status === 'complete'" class="btn__orange" @click="deposit">
        {{ $t('promo.ggg.complete') }}
      </button>


      <br><br>&nbsp;
      <div v-html="$t('promo.ggg.body')"></div>
    </template>
  </Details>
</template>

<script>
import Details from "../../../components/Promo/Details";

export default {
  name: "Ggg",
  components: {Details},
  computed: {
    status() {
      const bonuses = this.$store.state.bonus.filter(i => i.id === 'ggg');
      if(bonuses.length < 1) {
        return 'completed';
      }
      return bonuses[0].status;
    }
  },
  methods: {
    activate() {
      this.$store.dispatch('activateBonus', 'ggg').then(() => {
        this.$router.push('/deposit/form')
      })
    },

    deposit() {
      this.$router.push('/deposit/form')
    }
  }
}
</script>

<style scoped>
   .img {
     max-width: 100%;
   }
</style>
