export default function (makeRequest, setAuthToken) {

    this.init = phone => makeRequest('auth/recover/init', {phone});
    this.confirm = (code, process_id) => makeRequest('auth/recover/confirm', {code, process_id});
    this.complete = (password, process_id) => makeRequest('auth/recover/complete', {password, process_id})
        .then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return {success: r.success}
    });

}