<template>
  <SuccessMessage
      :title="$t('payIn.success.title')"
      :message="$t('payIn.success.text')"
      :button-text="$t('payIn.success.link')"
      :button-callback="goHome"
      :auto-redirect="true"/>
</template>

<script>
import SuccessMessage from "../Ui/Success";

export default {
  components: {SuccessMessage},
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
};
</script>
