<template>
  <Popper trigger="clickToToggle" ref="popper"
          :visible-arrow="false"
          transition='fade-popper'
          enter-active-class="fade-popper-enter-active"
          leave-active-class="fade-popper-leave-active"
  >
    <div class="popper" :style="customPopperStyle">
      <slot></slot>
    </div>
    <div slot="reference">
      <unicon name="info-circle"/>
    </div>
  </Popper>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: "Help",
  components: {Popper},
  props: {
    customPopperStyle: {
      type: Object,
      default: () => {
      },
    }
  }
}
</script>

<style>
.fade-popper-enter-active, .fade-popper-leave-active {
  transition: opacity .5s;
}

.fade-popper-enter, .fade-popper-leave-to {
  transition: opacity .5s;
  opacity: 0;
}

</style>

<style scoped>

.popper {
  padding: 14px 12px;
  margin-top: 8px;
  margin-right: 15px;
  background: #2c2c4d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0 0 34px rgba(209, 188, 188, 0.15);
  border-radius: 6px;

  color: #fff;

  width: 300px;

  text-align: left;
}

.popper > p {
  padding-top: 8px;
  padding-bottom: 15px;
}
</style>
