<template>
  <div class="failed-form">
    <div class="failed-form__container">
      <div class="failed__message">
        <img src="../../assets/icons/alerts/warning.svg"/>
        <p class="failed__title">{{ title }}</p>
        <p class="failed__text">{{ message }}</p>
        <div class="progress" v-if="autoRedirect">
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <div class="accept__button">
      <button class="btn__orange" @click="buttonCallback()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    autoRedirect: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonCallback: {
      type: Function,
      required: true
    },
  },
  mounted() {
    if(this.autoRedirect) {
      setTimeout(() => this.buttonCallback(), 4000);
    }
  },
}
</script>

<style lang="scss" scoped>

.progress {
  width: 100%;
  .bar {
    height: 4px;
    width: 100%;
    background-color: #f45a0e;
    border-radius: 1px;
    animation: .7s ease-in-out 0s infinite alternate both running pulse,
    4s linear 0s 1 normal forwards running shrink;
  }
}

@keyframes shrink {
  from {
    width: 100%;
  }

  to {
    width: 1%;
  }
}

.failed-form {
  height: calc(100vh - 80px);
  min-height: 450px;
  /* position: relative; */

  .failed-form__container {
    padding: 20px;

    .failed__message {
      position: relative;
      top: 100px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 8px;
      }

      .failed__title {
        width: 191px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
      }

      .failed__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .accept__button {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 32px;
  }
}
</style>