<template>
  <div class="scroll-up" id="scrollToTopBtn" @click="scrollToTopAnimated">
    <img src="../assets/images/up.png" alt="scroll up">
  </div>
</template>

<script>
export default {
  mounted() {
    const scrollToTopBtn = document.getElementById('scrollToTopBtn');

    document.addEventListener('scroll', function () {
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      let opacity = scrollTop / windowHeight;

      if (opacity < 0) {
        opacity = 0
      } else if (opacity > 1) {
        opacity = 1
      }

      scrollToTopBtn.style.opacity = opacity.toFixed(2);
    });
  },
  methods: {
    scrollToTopAnimated() {
      window.scrollTo(0, 0);
    }
  }
}

</script>


<style scoped lang="scss">
.scroll-up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease;
  opacity: 0;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>