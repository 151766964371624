<template>
  <div class="failed__message">
    <img src="../../../assets/icons/alerts/warning.svg"/>
    <p class="failed__title">{{ $t('payOut.wagerNotice.header') }}</p>
    <p class="failed__text">{{ $t('payOut.wagerNotice.notice', {sum: sum + ' ' + currency}) }}</p>
  </div>
</template>

<script>
export default {
  name: "WagerNotice",
  props: ['sum', 'currency']
}
</script>

<style lang="scss" scoped>
.failed__message {
  position: relative;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 8px;
  }

  .failed__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  }

  .failed__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
  }

}
</style>