export default {
    "header": {
        "login": "кіру",
        "balance": "баланс",
        "sign_in": "Тіркелу"
    },
    appBanner: {
        text: 'Бонус және сыйлықтар қолданбада.',
        link: 'Жүктеу.'
    },
    "banners": {
        "firstDeposit": {
            "title": "Жаңа ойыншылар үшін бонус"
        },
        "wheel": {
            "title": "сәттілік дөңгелегі"
        },
        "cashback": {
            "title": "Есепке қайтарамыз"
        },
        "bonusBanner": {
            title: "БОНУС<br>БЕЛСЕНДІ",
            action: "+20% АЛУ"
        }
    },
    "index": {
        "getBonusButton": "бонус алу",
        "winnersHeader": "Күннің қаһармандары",
        "showMore": "тағы көрсету",
        "gamesHeaderDefault": "Ойындар",
        "favorite": 'Таңдаулылар',
        "lastGames": 'Соңғы ойындар',
        "providers": "Провайдерлер",
        "searchPlaceholder": "Слот іздеу",
        "slots": "Слоттар",
        "roulette": "Рулетка",
        "minigames": "Шағын ойындар",
        "sport": "Спорт",
        "allGames": "Барлық ойындар"
    },
    "menu": {
        "main": "Негізгі",
        "promo": "акциялар",
        "profile": "Менің профилім",
        "history": "Тарих",
        "deposit": "Толықтыру",
        "payout": "шығару",
        "logout": "шығу",
        "login": "кіру",
        "entrance": "Кіру / Тіркелу",
        "licence": "Құқықтық ақпарат",
        faq: 'FAQ'
    },
    "login": {
        "header": "кіру",
        "phoneInput": {
            "label": "Телефон нөмірі",
            "placeholder": "Телефон",
            "required": "Толық нөмірді енгізіңіз"
        },
        "passwordInput": {
            "label": "құпия сөзіңіз",
            "placeholder": "құпия сөз",
            "required": "Құпия сөзді енгізіңіз"
        },
        "forgotPasswordLink": "Құпия сөзіңізді ұмыттыңыз ба?",
        "invalidCredentials": "Енгізілген құпия сөз дұрыс емес",
        "restorePasswordButton": "Құпия сөзді қалпына келтіру",
        "loginButton": "кіру",
        "registerLinkHeading": "Профиль жоқ па?",
        "registerLink": "Тіркелу"
    },
    "account": {
        "title": {
            "real": "Негізгі",
            "main": "Негізгі",
            "bonus": "Бонус"
        }
    },
    "profile": {
        "header": "Профиль",
        "passwordForm": {
            "header": "Құпия сөзің өзгерту",
            "currentPassword": {
                "label": "Ескі құпия сөзді енгізіңіз",
                "placeholder": "Ескі құпия сөз",
                "required": "ағымдағы құпия сөзді енгізіңіз",
                "invalid": "ағымдағы құпия сөз дұрыс емес"
            },
            "newPassword": {
                "label": "Жаңа құпия сөзді енгізіңіз",
                "placeholder": "Жаңа құпия сөз",
                "required": "Жаңа құпия сөзді енгізіңіз",
                "min": "кемінде 8 таңба енгізіңіз"
            },
            "confirmPassword": {
                "label": "Құпия сөзді қайталаңыз",
                "placeholder": "Құпия сөзді қайталаңыз",
                "required": "Жана құпия сөзді қайталаңыз",
                "min": "кемінде 8 таңба енгізіңіз",
                "match": "енгізілген құпия сөздер сәйкес келмейді"
            },
            "button": "Өзгерістерді сақтау",
            "success": {
                "title": "Құпия сөз сәтті өзгертілді",
                "text": "жалғастыру үшін түймені басыңыз",
                "link": "Негізгіге"
            }
        }
    },
    "history": {
        "header": "тарих",
        "payOut": "Қаражатты шығару",
        "payOutReturn": "Қаражатты шығарудан бас тарту",
        "payIn": "Толықтыру",
        "status": {
            "complete": "Сәтті",
            "wait": "Күтуде",
            "cancel": "Қабылданбады"
        },
        "showMoreLink": "тағы да көрсету",
        "noMoreResults": "Барлық операцияларды көрсетілген"
    },
    "payIn": {
        "header": "Толықтыру",
        "sum": {
            "label": "Толықтыру сомасын енгізіңіз"
        },
        "button": "Толықтыру",
        "error": {
            "title": "Қате шықты!",
            "text": "кейінірек көріңіз",
            "link": "Негізгіге",
            reasons: 'Туындау мүмкін себептер:',
            funds: 'Төлем картасында ақша',
            online_blocked: 'Төлем картасында онлайн төлем жабық',
            online_limit: 'Төлем картасында онлайн төлемдерге лимит біткен',
        },
        "success": {
            "title": "Толықтырылды!",
            "text": "Балансыңыз сәтті толтырылды",
            "link": "Негізгіге"
        },
        kaspi: {
            header: "Егер төлем картаңыз болмаса немесе төлем өтпесе, Kaspi ақша аудармасын қолданыңыз",
            footer: "Төлемнен кейін чатқа жазыңыз. Ақшанын түсу уақыты 5 минуттан 30 минутқа дей."
        }
    },
    "payOut": {
        "header": "Шығару",
        "sum": {
            "label": "Шығару сомасын енгізіңіз",
            "positive": "соманы енгізіңіз",
            "required": "оң соманы енгізіңіз",
            "max": "сома тым үлкен"
        },
        "cardNumber": {
            "label": "Карта нөмірін енгізіңіз",
            "full": "карта нөмірін толық енгізіңіз",
            "required": "карта нөмірін енгізіңіз"
        },
        "button": "Шығару",
        "goBack": "Артқа",
        "wagerNotice": {
            "header": "Белсенді вейджер",
            "notice": "Өтінемін, шығыс құлпын ашу үшін, негізгі шоттағы {sum} сомасына ставкаларды қойыңыз"
        },
        "identificationNotice": {
            "header": "Аккаунт анықталмаған",
            "notice": "Өтінемін, телефоныңызды немесе email-ды растаңыз."
        },
        "error": {
            "title": "Қате кетті",
            "link": "Артқа",
            "code": {
                "internal": "ішкі қате",
                "no_suitable_account": "қолайлы аккаунт жоқ",
                "funds": "қаражат жеткіліксіз",
                "invalid_card": "жарамсыз карта нөмірі",
                "wager": "сізде вейджер ойнай алмаған"
            },
            "unknownError": "белгісіз қате: {code}"
        },
        "success": {
            "title": "Сұрау жіберілді",
            "text": "Төлем сұранысы сәтті жіберілді",
            "link": "Басты бетке"
        }
    },
    "licence": {
        "header": "Лицензия",
        "agreement": 'Ашық ұсыныс туралы келісім',
        "policy": 'Құпиялылық саясаты',
        "info": 'Біз қандай ақпаратты жинаймыз және оны қалай қолданамыз',
        "player": 'Ойыншы туралы ақпаратты жаңарту'
    },
    "faq": {
        "header": "",
        "register": {
            "q": "Тіркелу және кіру",
            "a": "Жоғарғы оң жақ бұрыштағы “Тіркеу” батырмасын басыңыз, егер сізде кабинетіңіз бар болса, телефон нөмірі мен пароліңізді енгізіңіз. Кабинетті ашу үшін сіз тіркелуіңіз керек және де бұл көп уақыт алмайды немесе ұсынылған әлеуметтік желілердің бірі арқылы кіріңіз. Енді Сіз ойнап, ұтып және бонустар ала аласыз!"
        },
        "who-can": {
            "q": "Кабинетті кім аша алады?",
            "a": "Кабинетті 18 жастан асқан тұлғалар немесе аталған тұлға орналасқан елдің заңнамасына сәйкес рұқсат етілген жасқа толған тұлғалар ғана аша алады. Әр ойыншының тек бір кабинеті болуы мүмкін"
        },
        "find-game": {
            "q": "Ойынды қалай табуға болады?",
            "a": "Негізгі бетте «Іздеу» көмегімен ойынның атын толығымен немесе бірнеше алғашқы әріптерді енгізіңіз. Сонымен қатар ойындар «Провайдер» бойынша сұрыптауға болады."
        },
        "deposit": {
            "q": "Қалай толықтыруға болады?",
            "a": "«Толықтыру» батырмасын басып, толықтыру сомасын көрсетіңіз. Ашылған терезеде картаның нөмірін, жарамдылық мерзімін және картаның артқы жағындағы үш таңбалы кодты енгізіңіз. Осыдан кейін «Төлеу» батырмасын басыңыз. Банктің кінәсінен сирек жағдайларды қоспағанда, толтыру бірден жүреді. Кез келген сұрақтар бойынша чатқа хабарласыңыз."
        },
        "payout": {
            "q": "Қалай шығарып алуға болады?",
            "a": "«Шығарып алу» батырмасын басыңыз және ашылған терезеде соманы және төлемді алғыңыз келетін картаның нөмірін көрсетіңіз. Тексеруден кейін төлем картаға түседі. Кез келген сұрақтар бойынша чатқа хабарласыңыз."
        },
        "min-sum": {
            "q": "Толықтыру мен төлемнің ең аз мөлшері қандай?",
            "a": "Толықтырудың ең аз сомасы 2000 KZT. Төлемнің ең аз сомасы 1000 KZT."
        },
        "bonus": {
            "q": "Бонустар мен Бонустық шот дегеніміз не?",
            "a": "Бонустар — бұл біздің мекемемізден сыйлық ретінде есептелетін және Бонустық шотта сақталатын клубтың ішкі валютасы. Бонустарды акцияларға қатысу арқылы алуға болады. Бонустық қаражатты шығарып алу үшін бонус сомасын қайтарып ұтып алу керек."
        },
        "wager": {
            "q": "Белсенді вейджер дегеніміз не?",
            "a": "Вейджер — бұл алынған жүлдені шығарып алу үшін сақталуы керек шарттар жиынтығы. «Қайтарып ұтып алу» — ұтысқа немесе ұтылысқа қарамастан, ойын барысында белгілі бір сомаға айналдыру немесе ұтыс тігу жасау (нақты қаражат есептен шығарылатын барабандардың әрбір айналуы есептеледі). Әрбір бонус пен әрбір шотты толықтырудың өз вейджері бар (қайтарып ұтып алудың коэффициенті). Бұл қайтарып ұтып алуға арналған ұтыс тігудің сомасын алу үшін сома көбейтілетін коэффициент. Мысалы, вейджер x2. 1 000 мөлшердегі бонусты алу үшін ойын барысында 2 000-ға ұтыс тігулер жасау керек. Қажетті қайтарып ұтып алу сомасының есептегіші «Шығарып алу» мәзірінде орналасқан."
        },
        "password": {
            "q": "Пароліңізді ұмыттып қалдыңыз ба?",
            "a": "Пароліңізді қалпына келтіру үшін «парольді ұмыттым» батырмасын пайдаланыңыз. Телефон нөмірін енгізіңіз, алынған SMS кодты енгізіңіз және жаңа пароль ойлап табыңыз. Кез келген сұрақтар бойынша Сіз чатқа жаза аласыз."
        }
    },
    "notFound": {
        "title": "Бет табылмады",
        "link": "Басты бетке"
    },
    "logout": {
        "title": "Сіз өз аккаунтыңыздан шықтыңыз",
        "text": "жалғастыру үшін түймесін басыңыз",
        "link": "Басты бетке",
        "success": {
            "header": "Сіз шығып кеттіңіз",
            "text": "басыңыз",
            "button": "Басты бетке"
        }
    },
    "smsForm": {
        "title": "SMS-тен кодты енгізіңіз",
        "sent": "Біз нөмірге СМС жібердік",
        "notDeliveredLink": "Код келмеді ме?",
        "invalidCode": "Қате код!"
    },
    "registration": {
        unconfirmed: {
            text: 'Телефон нөмірін енгізіңіз, ол парольді қалпына келтіруі керек',
            button: 'Әрі қарай',
            password: {
                label: 'Құпия сөз ойлап табыңыз',
                placeholder: 'Құпия сөз ойлап табыңыз',
                error: 'Ең аз дегенде 6 таңбаны енгізіңіз',
            },
            loginHint: {
                label: 'немесе',
                link: 'кіру'
            },
            agreement: {
                error: 'Сіз шарттармен келісуіңіз керек',
                label: 'Мен офертамен таныстым және шарттарымен келісемін'
            }
        },
        "init": {
            "title": "Тіркелу",
            "text": "Растау коды бар SMS жіберетін телефон нөмірін енгізіңіз",
            "tel": {
                "placeholder": "телефон",
                "required": "Нөмірді толығымен көрсетіңіз"
            },
            "error": {
                "used": "Бұл телефон нөмірі қазірдің өзінде қолданылады!",
                "recoverButton": "Құпиясөзді қалпына келтіру"
            },
            "button": "Әрі қарай",
            "hasAccount": "Қазірдің өзінде акаунт бар ма?",
            "loginLink": "Кіру"
        },
        "complete": {
            "title": "Құпия сөз ойлап табыңыз",
            "text": "Парольде кемінде 8 таңба болуы керек!",
            "password": {
                "label": "Құпия сөз ойлап табыңыз",
                "placeholder": "Құпия сөз ойлап табыңыз"
            },
            "confirmPassword": {
                "label": "Құпия сөзді қайталаңыз",
                "placeholder": "Құпия сөз"
            },
            "error": {
                "required": "Өтінемін, құпия сөзді көрсетіңіз",
                "min": "Құпия сөз тым қысқа, кем дегенде 8 таңбаны енгізіңіз",
                "match": "Құпия сөздер сәйкес келмейді"
            },
            "button": "Әрі қарай"
        },
        "completed": {
            "title": "Тіркеу аяқталды",
            "text": "сіз сәтті тіркелдіңіз, жалғастыру үшін түймесін басыңыз",
            "link": "Басты бетке"
        }
    },
    "recover": {
        "request": {
            "header": "Құпия сөзді қалпына келтіру",
            "text": "Растау коды бар SMS жіберетін телефон нөмірін енгізіңіз",
            "tel": {
                "placeholder": "телефон"
            },
            "error": {
                "notFound": "Нөмір табылмады",
                "regButton": "Тіркелу"
            },
            "button": "Әрі қарай"
        },
        "reset": {
            "header": "Жаңа құпия сөз ойлап табыңыз",
            "password": {
                "label": "Құпия сөз ойлап табыңыз",
                "placeholder": "Құпия сөз ойлап табыңыз"
            },
            "confirmPassword": {
                "label": "Құпия сөзді қайталаңыз",
                "placeholder": "Құпия сөз"
            },
            "error": {
                "required": "Өтінемін, құпия сөзді көрсетіңіз",
                "min": "Құпия сөз тым қысқа, кем дегенде 8 таңбаны енгізіңіз",
                "match": "Құпия сөздер сәйкес келмейді"
            },
            "button": "Әрі қарай"
        },
        "completed": {
            "title": "Құпия сөз  сәтті өзгертілді",
            "text": "жалғастыру үшін түймесін басыңыз",
            "link": "Басты бетке"
        }
    },
    "promo": {
        "cashback": {
            "title": "Клубтық кэшбек",
            "body": "<p> Кэшбэк &mdash; ұтылған қаражаттың белгілі бір пайызын қайтару.</p><p>Кэшбэк клубтың тұрақты ойыншыларына қол жетімді және бір аптадағы ұтылыстың 10% құрайды. Соманы есептеу кезінде тек нақты баланстан ұтылған меншікті қаражат ескеріледі. Бонустық теңгерім есептеуге қатыспайды. Кэшбекпен ақшаны қайтару әр жұмада сағат 18-де автоматты түрде жүреді.</p>"
        },
        "deposit": {
            "title": "Бірінші депозитке 20% БОНУС",
            "body": "<p>Осы ұсыныс шеңберінде пайдаланушы &mdash бонусын алады; ол енгізілген депозит сомасының 20% құрайды. Бонусты алу үшін қажетті депозиттің ең аз сомасы – 700 KZT.</p> <p>Бонус қолданылған жағдайда, вейджер сомасы енгізілген қаражат пен бонустың жиынтық мәніне белгіленеді.</p> <p>Яғни, 10 000 KZT енгізген кезде сіз 2 000 бонус аласыз.</p>"
        },
        "wheel": {
            "title": "Фортуна дөңгелегі — Сізге бақ қонсын",
            "body": "<p>Көптеген түрлі сыйлықтар ұтып алуға мүмкіндік бар қызықты мини-ойын!</p><p>Фортуна дөңгелегі &mdash; бұл тегін күнделікті мини-ойын, онда бонустық ұпайларды, депозитті толықтыруға қосымшаны немесе Клубтың керемет сыйлықтарын ұтып алуға болады, Фортуна дөңгелегі секторларға бөлінген және олардың әрқайсысында дерлік өз сыйлығы бар. Фортуна дөңгелегін іске қосу үшін дөңгелектің ортасындағы 'Старт' батырмасын басыңыз. Ойын нәтижесі кездейсоқ сандар генераторының көмегімен анықталады. Заттай жүлделерді жеткізу төлемі жеңімпаздың есебінен жүзеге асырылады</p>"
        }
    }
}
