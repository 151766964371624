<template>
  <div class="failed-form">
    <div class="failed-form__container">
      <div class="failed__message">
        <img src="../assets/icons/alerts/warning.svg"/>
        <p class="failed__title">{{ $t('notFound.title') }}</p>
      </div>
    </div>
    <div class="accept__button">
      <button class="btn__orange" @click="$router.push('/')">
        {{ $t('notFound.link') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style lang="scss" scoped>
.failed-form {
  height: calc(100vh - 80px);
  min-height: 450px;
  /* position: relative; */
  .failed-form__container {
    padding: 20px;
    .failed__message {
      position: relative;
      top: 100px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 8px;
      }
      .failed__title {
        width: 191px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
      }
      .failed__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .accept__button {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 32px;
  }
}
</style>