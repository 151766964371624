<template>
  <span>
    <span v-if="days">{{days}} {{days > 1 ? 'DAYS ' : 'DAY '}}</span>
    <span v-if="hours">{{hours}}</span>
    <span v-if="hours" class="blink">:</span>
    <span v-if="minutes">{{minutes}}</span>
  </span>
</template>

<script>
export default {
  name: "Timer",
  props: {
    secondsLeft: {
      type: Number
    },
  },
  computed: {
    days() {
      return this.timeLeft.days
    },
    hours() {
      return this.timeLeft.hours
    },
    minutes() {
      return this.timeLeft.minutes
    },
    timeLeft() {
      const d =this.secondsLeft;
      const day = 24 * 60 * 60;
      const hour = 60 * 60;
      const minute = 60;
      if (d < 0) {
        return {days: 0, hours: 0, minutes: 0};
      }
      const days = Math.floor(d / day);
      let rest = d - (days * day);
      const hours = Math.floor(rest / hour);
      rest = rest - (hours * hour);
      const minutes = Math.floor(rest / minute);
      return {days, hours: hours.toString().padStart(2, '0'), minutes: minutes.toString().padStart(2, '0')}
    }
  },

}
</script>

<style scoped>

.blink {
  animation-name: blink-a;
  animation-duration: .5s;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@keyframes blink-a{
  0%{opacity: 0;}
  100%{opacity: 1;}
}
</style>
