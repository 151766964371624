<template>
  <div class="banner" v-if="isApp && url && needToUpdate && testUser && !blackListedPage">
    <div class="text">
      {{ $t('updateBanner.text') }}
      <a class="btn btn__orange" style="display: inline-block; width: auto; padding: 10px; margin-top: 5px;"
         :href="url">{{ $t('updateBanner.link') }}</a>
    </div>
  </div>
</template>

<script>
import semver from 'semver';

const getVersion = () => {
  const Android = window.Android
  return (Android && Android.getVersion && Android.getVersion()) || '0.0.0';
}

export default {
  name: "UpdateBanner",
  data() {
    return {currentVersion: '0.0.0'}
  },
  mounted() {
    this.currentVersion = getVersion();
  },
  computed: {
    needToUpdate() {
      return semver.lt(this.currentVersion, this.minVersion, true)
    },
    testUser() {
      return [4, 4193].includes(this.$store.state.player_id);
    },
    minVersion() {
      return this.$store.state.config.minVersion || '1.0.10';
    },
    url() {
      return this.$store.state.config.appUrl;
    },
    isApp() {
      return this.$store.state.isAndroidApp;
    },
    blackListedPage() {
      return ['deposit', 'deposit.phone', 'deposit.phone.complete'].includes(this.$route.name)
    }
  }
}
</script>

<style scoped>
.banner {
  border-radius: 8px;
  margin: 20px;
  display: flex;
  padding: 20px;
  background-color: #86e15b;
  color: #fff;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  flex-grow: 0;
}

.text {
  flex-grow: 1;
}
</style>
