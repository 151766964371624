<template>
  <SuccessMessage
      :title="$t('registration.completed.title')"
      :message="$t('registration.completed.text')"
      :button-text="$t('registration.completed.link')"
      :button-callback="goHome"
      :auto-redirect="true"/>
</template>

<script>
import SuccessMessage from "../../Ui/Success";

export default {
  components: {SuccessMessage},
  props: {},
  data() {
    return {error: false};
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
};
</script>