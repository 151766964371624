<template>
  <div class="fortuna3x">
    <div class="heading"></div>
    <button class="button"></button>
  </div>
</template>

<script>
export default {
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.fortuna3x {
  width: 100%;
  height: 158px;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/fortuna3x/bg.png);
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}

.button {
  background-image: url(../../assets/images/banners/fortuna3x/button.png);
  background-size: cover;
  width: 205px;
  height: 69px;
  display: block;
  right: 5px;
  bottom: 5px;
  position: absolute;
  cursor: pointer;
}

.heading {
  background-image: url(../../assets/images/banners/fortuna3x/text.png);
  background-size: cover;
  width: 222px;
  height: 64px;
  display: block;
  left: 5px;
  top: 5px;
  position: absolute;
  cursor: pointer;
}
</style>
