<template>
  <div class="history-page">
    <br/>
    <CashBack/>
    <br/>
    <br/>
    <p class="history__title">{{ $t('history.header') }}</p>
    <br/>
    <Finance :rows="history"/>
    <div v-if="hasMore" @click="loadMore" class="more_link">{{ $t('history.showMoreLink') }}</div>
    <div v-else class="more_link end">{{ $t('history.noMoreResults') }}</div>
  </div>
</template>

<script>
import Finance from "../../components/History/Finance";
import CashBack from "../../components/History/CashBack/CashBack";

export default {
  components: {CashBack, Finance},
  computed: {
    cashback() {
      return this.$store.state.cashBack
    },
    history() {
      return this.$store.state.paymentHistory.items;
    },
    hasMore() {
      return this.$store.state.paymentHistory.nextPage !== null;
    },
  },
  methods: {
    loadMore() {
      this.$store.dispatch('paymentHistoryMore', this.$store.state.paymentHistory.nextPage)
    }
  },
  beforeMount() {
    this.$store.dispatch('paymentHistory')
  }
};
</script>

<style lang="scss" scoped>
.history-page {
  padding: 0 20px 20px 20px;

  .more_link {
    line-height: 17px;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-decoration-line: underline;
    color: #E5550E;
    text-align: center;
    padding-top: 16px;
  }

  .more_link.end {
    color: #959595;
    text-decoration-line: none;
  }

  .history__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
  }

  .history__links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 21px 0 28px 0;

    a {
      margin-right: 20px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

