import Vue from 'vue'
import VueGtag from 'vue-gtag';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import './assets/style/swiper.css'

import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import VueI18n from 'vue-i18n'
import messages from "./localisation/all";

import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';
import {uniCopy, uniDialpadAlt, uniCreditCard, uniInfoCircle, uniCheck} from 'vue-unicons/dist/icons';

Unicon.add([uniCopy, uniDialpadAlt, uniCreditCard, uniInfoCircle, uniCheck])


import './assets/style/global.scss'
import router from './router/router'
import store from './store'
import routerBeforeEachHook from "./router/routerBeforeEachHook";
import App from './App.vue'
import VueYandexMetrika from 'vue-yandex-metrika'
import {gamesCache} from "./store";

routerBeforeEachHook(router, store);

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueI18n);
Vue.use(Unicon, {
    fill: '#fff',
    height: 24,
    width: 24
});
Vue.use(VueGtag, {
    config: {id: 'G-VTQVLBKTH4'} // Ваш код отслеживания Google Analytics
});

Vue.config.productionTip = false

closeChat();

store.commit('setGames', gamesCache.get());
store.dispatch('index').then(r => {

    const i18n = new VueI18n({
        locale: selectLanguage(r.config.defaultLanguage),
        fallbackLocale: 'ru',
        messages
    })

    handleMetrika(r.config.metrikaId);

    new Vue({
        VueAwesomeSwiper,
        router,
        store,
        render: h => h(App),
        i18n
    }).$mount('#app')
});


function handleMetrika(id) {
    if (!id) {
        return
    }

    // eslint-disable-next-line no-prototype-builtins
    const params = window.hasOwnProperty('Android') ? {
        android_app: true,
        app_id: window.Android.getAppId()
    } : {};

    Vue.use(VueYandexMetrika, {
        id,
        router: router,
        env: process.env.NODE_ENV,
        options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            params
        }
    })
}

function closeChat() {
    const key = 'thChatData';
    const data = JSON.parse(window.localStorage.getItem(key));
    if (data && data.opened) {
        window.localStorage.setItem(key, JSON.stringify({...data, opened: false}))
    }
}

function selectLanguage(serverDefault) {
    const browserLang = window.navigator.language.split('-')[0];
    return localStorage.getItem('$i18n.locale') || serverDefault || browserLang || 'ru';
}
