<template>
  <AuthenticatedConfirmation
      :submit="submit"
      :on-success="onSuccess"
      :cancel="cancel"
      back-route="/payout/form"
  />
</template>

<script>
import AuthenticatedConfirmation from "../../Ui/AuthenticatedConfirmation";

export default {
  name: "ConfirmOut",
  components: {AuthenticatedConfirmation},
  methods: {
    cancel() {
      this.$router.push({name: 'payout'})
    },
    submit(confirmation) {
      return this.$route.params.request(confirmation)
    },
    onSuccess(r) {
      this.$route.params.onSuccess(r)
    }
  }
}
</script>

<style scoped>

</style>