<template>
  <section class="login">
    <div class="login__container">
      <div class="login__main">
        <p class="login__title">{{ $t('social.mailru') }}</p>
        <p class="login__title">{{ result }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MailruRedirectHandler",
  data() {
    return {
      result: this.$i18n.t('social.wait')
    }
  },
  mounted() {

    const code = this.$route.query.code;
    if (code) {
      const redirect = new URL(this.$router.resolve({name: "login.mailru"}).href, location.origin).toString()
      this.$store.dispatch('loginMailru', {code, redirect}).then(r => {
        if(r) {
          this.$router.push('/')
        } else {
          this.result = this.$i18n.t('social.login_error');
        }
      })
    } else {
      this.result = this.$i18n.t('social.login_cancelled');
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  .login__container {
    height: 100%;
    min-height: 530px;
    position: relative;

    .login__main {
      position: relative;
      margin-bottom: 20px;
      top: 20px;
      transition: 0.2s;
      transition-delay: 0.2s;

      .login__title {
        display: flex;
        justify-content: center;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
      }
    }
  }
}
</style>
