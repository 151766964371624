<template>
  <SuccessMessage :title="$t('logout.title')"
                  :message="$t('logout.text')"
                  :button-text="$t('logout.link')" :button-callback="goHome"/>
</template>


<script>
import SuccessMessage from "../Ui/Success";

export default {
  name: "Logout",
  components: {SuccessMessage},
  beforeMount() {
    this.$store.dispatch('logout')
    localStorage.removeItem("BonusBanner.closed")
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>