import FirstDeposit from "../views/Promo/Details/FirstDeposit";
import Wheel from "../views/Promo/Details/Wheel";
import CashBack from "../views/Promo/Details/CashBack";
import Promo from "../views/Promo/Promo";
import Details from "../views/Promo/Details/DetailsPage";
import Ggg from "@/views/Promo/Details/Ggg";
import PromoGame from "@/views/Promo/PromoGame.vue";

export default [
    {
        path: '/promo',
        name: 'promo',
        component: Promo,
    },
    {
        path: '/promo',
        component: Details,
        children: [
            {
                name: 'promo.first-deposit',
                path: 'first-deposit',
                component: FirstDeposit,
            },
            {
                name: 'promo.wheel',
                path: 'wheel',
                component: Wheel,
            },
            {
                name: 'promo.cash-back',
                path: 'cash-back',
                component: CashBack,
            },
            {
                name: 'promo.ggg',
                path: 'GGG',
                component: Ggg,
            },
            {
                path: 'play/:id',
                name: 'promo.play',
                component: PromoGame,
                meta: {requiresAuth: true},
                props: ({params}) => ({...params, demo: false})
            },
        ]
    },

];
