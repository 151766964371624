<template>
  <div class="deposit-form">
    <div class="deposit-form__container input__default">
      <p class="title">{{ $t('payIn.kaspi.header') }}</p>
      <div style="position: relative">
        <unicon name="dialpad-alt" style="position: absolute; top: 17px; left: 12px;"/>
        <input :value="kaspi.phone" readonly style="padding-left: 42px;"/>
        <Copy :text="kaspiPhoneToCopy"/>
      </div>
      <div style="position: relative">
        <unicon name="credit-card" style="position: absolute; top: 17px; left: 12px;"/>
        <input :value="kaspi.card" style="padding-left: 42px;" readonly/>
        <Copy :text="kaspiCardToCopy"/>
      </div>
      <br>
      <p class="title">{{ $t('payIn.kaspi.footer') }}</p>
    </div>
  </div>
</template>

<script>
import Copy from "./Copy";

export default {
  name: "DepositKaspi",
  components: {Copy},
  computed: {
    kaspiPhoneToCopy() {
      const phone = this.$store.state.config.deposit.kaspi.phone;
      if (phone.substring(0, 2) === '+7') {
        return phone.substring(2);
      }
      return phone;
    },
    kaspiCardToCopy() {
      return this.$store.state.config.deposit.kaspi.card.replace(/\s/g, '');
    },
    kaspi() {
      return this.$store.state.config.deposit.kaspi;
    },
    currency() {
      return this.$store.state.currency;
    }
  },
};
</script>

<style lang="scss" scoped>

.deposit-form {
  color: #ffffff;
}

.deposit-form__container {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
  position: relative;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

</style>
