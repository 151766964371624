<template>
  <div class="banner" v-if="hasActivePromo">
    <div v-for="(promoCode, i) in activePromo" :key="i" class="text">
      {{ $t('promoCode.bannerText', {percent: promoCode.percent, until: formatDate(promoCode.activeUntil)}) }}
    </div>
  </div>
</template>

<script>

import dayjs from "dayjs";

export default {
  name: "PromoBanner",
  methods: {
    formatDate(ts) {
      return dayjs.unix(ts).format('HH:mm')
    }
  },
  computed: {
    activePromo() {
      return this.$store.state.activePromoCode.filter(code => code.percent > 0);
    },

    hasActivePromo() {
      return this.activePromo.length > 0;
    }

  }
}
</script>

<style scoped>
.banner {
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  background-color: #86e15b;
  color: #fff;
  justify-content: space-between;
  align-items: center;
}

.text {
  flex-grow: 1;
}
</style>
