<template>
  <div class="deposit-form">
    <div class="deposit-form__title">{{ $t('payIn.phone.header') }}</div>
    <div class="deposit-form__container">
      <p class="title">{{ $t('payIn.phone.codeLabel') }}</p>
      <div :class="['input__default', { 'in-error': invalidCode }]"
           style="margin-bottom: 16px">
        <input v-model="code" @input="invalidCode = false"/>
        <p :class="['error__label', { 'in-error': invalidCode }]">
          {{ $t('payIn.phone.invalidCode') }}
        </p>
      </div>
    </div>
    <div class="warning-block">{{ $t('payIn.phone.beelineNote') }}</div>
    <br>
    <div class="buttons">
      <button class="btn__orange" @click="completeDeposit">{{ $t('payIn.phone.pay') }}</button>
    </div>
  </div>
</template>

<script>
import {required, minLength} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      ps: "",
      id: "",
      code: "",
      invalidCode: false
    };
  },
  validations() {
    return {
      phone: {
        required,
        minLength: minLength(3),
      }
    };
  },
  computed: {},
  methods: {
    async completeDeposit() {
      this.$store
          .dispatch("depositPhoneComplete", {ps: this.ps, id: this.id, code: this.code})
          .then(async (r) => {
            if (!r.ok) {
              this.$router.push({name: 'deposit.error'})
              return;
            }
            if (r.invalid_code) {
              this.invalidCode = true;
              return;
            }

            await this.$router.push({name: 'deposit.pending'})
          });
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.ps = this.$route.params.ps;
  }
};
</script>

<style lang="scss" scoped>

.deposit-form {
  color: #ffffff;
}

.deposit-form__title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.deposit-form__container {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.warning-block {
  margin-top: 16px;
  padding: 15px 15px;
  background-color: #2c2c4d;
  border-radius: 6px;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
</style>
