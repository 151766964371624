<template>
  <div class="deposit-form">
    <div class="deposit-form__title">{{ $t('payIn.phone.header') }}</div>
    <div class="deposit-form__container">
      <div class="deposit__input">
        <p class="title">{{ $t('payIn.sum.label') }}</p>
        <div :class="['input__default', { 'in-error': $v.sum.$error }]">
          <input
              v-model="sum"
              inputmode="numeric"
          />
          <p class="currency">{{ currency }}</p>
          <p :class="['error__label', { 'in-error': $v.sum.$error }]">{{ error }}</p>
        </div>
      </div>
      <Hint :on-click="setSum"/>
    </div>
    <br>
    <div class="deposit-form__container">
      <TelInput
          v-model="phone"
          :title="$t('payIn.phone.phoneLabel')"
          :validate="$v.phone"
          :custom-style="{marginBottom: '16px'}"
      />
    </div>
    <div class="warning-block">{{ $t('payIn.phone.beelineNote') }}</div>
    <div class="buttons">
      <button class="btn__orange" @click="initDeposit">{{ $t('payIn.phone.getCode') }}</button>
    </div>

  </div>
</template>

<script>
import {required, minLength, integer, minValue, maxValue} from "vuelidate/lib/validators";
import TelInput from "../Ui/TelInput";
import Hint from "@/components/Deposit/Hint";

export default {
  components: {TelInput, Hint},
  data() {
    return {
      hasPageshowListener: false,
      phone: "",
      showCodeBlock: false,
      sum: "",
      ps: "",
      code: ""
    };
  },
  validations() {
    return {
      sum: {
        required,
        integer,
        minValue: minValue(this.config.min),
        maxValue: maxValue(this.config.max),
      },
      phone: {
        required,
        minLength: minLength(11),
      }
    };
  },
  computed: {
    config() {
      return this.$store.state.config.deposit.hint[this.$store.state.currencyCode] || {min: 1, max: 500000};
    },
    currency() {
      return this.$store.state.currency;
    },
    error() {
      const formatter = new Intl.NumberFormat(undefined, {maximumFractionDigits: 0});
      const min = formatter.format(this.config.min);
      const max = formatter.format(this.config.max);
      return `Укажите сумму от ${min} до ${max}`
    },
  },
  methods: {
    setSum(value) {
      this.sum = value;
    },
    initDeposit() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.$store.dispatch("depositPhone", this.depositData()).then((r) => {
        if (r.ok) {
          this.$router.push({name: 'deposit.phone.complete', params: {id: r.id, ps: this.ps}})
        } else {
          this.$router.push({name: 'deposit.error'})
        }
      });
    },
    depositData() {
      const successRoute = this.$router.resolve({name: "deposit.success"});
      const failedRoute = this.$router.resolve({name: "deposit.error"})
      return {
        ps: this.ps,
        sum: this.sum,
        success_url: new URL(successRoute.href, location.origin).toString(),
        failed_url: new URL(failedRoute.href, location.origin).toString(),
        phone: this.phone
      }
    }
  },
  created() {
    this.sum = this.$route.params.sum;
    this.ps = this.$route.params.ps
    if (/^\d{11}$/.test(this.$store.state.player_label)) {
      this.phone = this.$store.state.player_label;
    }
  }
};
</script>

<style lang="scss" scoped>
.code-block {
  margin-top: 16px;
  margin-bottom: 10px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms;
}

.code-block.display {
  visibility: visible;
  height: 100%;
  opacity: 1;
}

.deposit-form {
  color: #ffffff;
}

.deposit-form__title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.deposit-form__container {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.warning-block {
  margin-top: 16px;
  padding: 15px 15px;
  background-color: #2c2c4d;
  border-radius: 6px;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.currency {
  position: absolute;
  top: 19px;
  right: 11px;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
}

.buttons {
  transition: all 1s;
  margin-top: 20px;
  display: flex;
  gap: 8px;
}

.buttons > button {
  flex-grow: 1;
}
</style>
