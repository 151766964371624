const metaFromJson = require('../json/meta.json');

const getSeoMeta = () => {

    const crypto = require('crypto');
    let pathname = window.location.pathname;

    if (pathname.endsWith('/')) {
        pathname = pathname.slice(0, -1);
    }

    const hash = crypto.createHash('md5').update(window.location.hostname).digest("hex") + pathname;

    return {
        title: metaFromJson[hash] ? metaFromJson[hash].title : 'LOTO CLUB',
        description: metaFromJson[hash] ? metaFromJson[hash].description : ''
    }
}

export default (router, store) => router.beforeEach((to, from, next) => {
    const seoMeta = getSeoMeta();

    // Добавляем title для SEO
    if (seoMeta.title) {
        document.title = seoMeta.title
    }

    // Добавляем description для SEO
    if (seoMeta.description) {
        const metaDescription = document.querySelector('meta[name="description"]')

        if (metaDescription) {
            metaDescription.setAttribute('content', seoMeta.description)
        } else {
            const meta = document.createElement('meta')
            meta.setAttribute('name', 'description')
            meta.setAttribute('content', seoMeta.description)
            document.head.appendChild(meta)
        }
    }

    // Добавляем canonical для SEO
    const linkCanonical = document.querySelector('link[rel="canonical"]')

    if (linkCanonical) {
        linkCanonical.setAttribute('href', location.href)
    } else {
        const link = document.createElement('link')
        link.setAttribute('rel', 'canonical')
        link.setAttribute('href', location.href)
        document.head.appendChild(link)
    }

    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        store.state.auth === false
    ) {
        if (to.query.login === 'reg') {
            next({name: 'registration', params: {return: to.fullPath}});
        } else {
            next({name: 'login', params: {return: to.fullPath}});
        }
        return
    }

    next()
})
