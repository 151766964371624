<template>
  <div class="telega">
    <div class="heading"></div>
    <a class="button" :href="tgUrl"></a>
  </div>
</template>

<script>
export default {
  computed: {
    tgUrl() {
      return "https://t.me/loto37club";
    }
  }
};
</script>

<style lang="scss" scoped>
.telega {
  width: 100%;
  height: 158px;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/telegram/bg.png);
  background-position: center;
  background-size: cover;
  position: relative;
}

.button {
  background-image: url(../../assets/images/banners/telegram/button.png);
  background-size: cover;
  width: 129px;
  height: 34px;
  display: block;
  left: 7px;
  bottom: 15px;
  position: absolute;
}

.heading {
  background-image: url(../../assets/images/banners/telegram/text.png);
  background-size: cover;
  width: 164px;
  height: 30px;
  display: block;
  left: 10px;
  bottom: 75px;
  position: absolute;
}
</style>
