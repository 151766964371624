export default function TokenStorage() {
    const key = 'token';
    const isAndroid = 'Android' in window;
    this.get = () => isAndroid ?
        window.Android.getToken()
        : localStorage.getItem(key)

    this.set = token => {
        if (isAndroid) {
            window.Android.setToken(token)
        } else {
            localStorage.setItem(key, token)
        }
    }

    this.reset = () => {
        this.set('')
        if (getTokenFromURL()) {
            window.location.href = '/';
        }
    }

    const getTokenFromURL = () => {
        const url = new URL(window.location.href);
        return url.searchParams.get('login_token');
    }

    if(!isAndroid && getTokenFromURL()) {
        this.set(getTokenFromURL());
    }
}
