<template>
    <ExternalPage v-if="game_url" :url="game_url" :allow-full-screen="allowFullScreen"/>
</template>

<script>

import ExternalPage from "./ExternalPage.vue";

export default {
  name: "Game",
  components: {ExternalPage},
  props: {
    id: {type: String},
    demo: {type: Boolean},
    iframe: {type: Boolean, default: false},
    allowFullScreen: {type: Boolean, default: true}
  },
  data() {
    return {
      game_url: null
    }
  },
  mounted() {
    const returnUrl = new URL(this.$router.resolve({name: "index"}).href, location.origin).toString();
    const refillUrl = new URL(this.$router.resolve({name: "deposit"}).href, location.origin).toString();
    const language = this.$i18n.locale;
    const payload = {id: this.$route.params.id, returnUrl, refillUrl, language};
    const action = this.demo ? 'startDemo' : 'startGame';
    this.$store
        .dispatch(action, payload)
        .then(r => {
          if (!r.success) {
            return
          }

          if(r.iframe  || this.iframe) {
            this.game_url = r.url;
            return;
          }

          this.$store.commit('loading')
          if (!this.hasPageshowListener) {
            window.addEventListener('pageshow', event => {
              if (event.persisted) {
                this.$store.commit('loading_complete');
              }
            });
            this.hasPageshowListener = true;
          }
          location.replace(r.url);
        })
  }
}
</script>

<style scoped>
.game-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 20;
}

.game-frame {
  top: 40px;
  width: 100%;
  height: calc(100% - 80px);
  border: 0;
}
</style>
