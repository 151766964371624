export default (api, partnerData) => ({
    namespaced: true,
    state: {
        process_id: '',
        tel: '',
        confirmed: false
    },
    mutations: {
        init(state, {id, tel}){
            state.process_id = id
            state.tel = tel
        },
        reset(state) {
            state.process_id = ''
            state.tel = ''
            state.confirmed = false
        },
        confirmed(state, r) {
            state.confirmed = r
        }
    },
    actions: {
        unconfirmed({commit}, {phone, password, currency}) {
            const data = partnerData.get();
            return api.registerUnconfirmed({phone, password, currency, data})
                .then(r => {
                    if(r.success) {
                        commit('login', null, { root: true })
                        commit('reset')
                    }
                    return r;
                })
        },
        init({commit}, tel) {
            return api.init(tel)
                .then(r => {
                    if(r.success) {
                        commit('init', {id: r.process_id, tel})
                    }
                    return r;
                })
        },
        confirm({commit, state}, code) {
            return api.confirm(code, state.process_id)
                .then(r => {
                    commit('confirmed', r.success)
                    return r;
                })
        },
        complete({commit, state}, password) {
            return api.complete(password, state.process_id)
                .then(r => {
                    if(r.success) {
                        commit('login', null, { root: true })
                        commit('reset')
                    }
                    return r;
                })
        }
    }

})
